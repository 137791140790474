import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import * as authActions from "../actions/auth.actions";
import { AuthService } from "../../services";
import { RoutingService } from "src/app/clearpath-module/services";
import * as rg4js from "raygun4js";
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { Router } from "@angular/router";

@Injectable()
export class AuthEffects {

  // LOGOUT

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.logout),
      withLatestFrom(this.authService.selectAuthState()),
      map(([action, {token}]) => {
        console.log("Logging out of Auth0");
        return this.auth0.logout({
          logoutParams: {
            returnTo: window.location.origin
          }
        });
      }),
      map(() => {
        this.authService.clearUser();
        localStorage.clear();
        this.authService.clearTokenTimeout();
        this.authService.token = "";
        this.router.navigate(["/"]);
        return authActions.logoutSuccess();
      })
    )
  );

  // VERIFY TOKEN

  verifyToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.verifyToken),
      withLatestFrom(this.authService.selectAuthState()),
      switchMap(([action, {token}]) => {
        return this.authService.verifyTokenHttp(token)
          .pipe(map(({token}) => {
              // console.log("result:", token);
            }),
            catchError(error => {
              return of(authActions.logout());
            }));
      })
    ), {dispatch: false}
  );

  // REFRESH

  refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.refreshToken),
      withLatestFrom(this.authService.selectAuthState()),
      switchMap(([action, {token}]) => {
        return this.authService.refreshTokenHttp(token)
          .pipe(
            map(({token: newToken}) => {
              this.authService.token = newToken;
              this.authService.setTokenTimeout(newToken);
              return authActions.refreshTokenSuccess({token: newToken});
            }),
            catchError(error => {
              this.authService.logout();
              this.authService.clearTokenTimeout();
              return of(authActions.refreshTokenFailure({error}));
            })
          );
      })
    )
  );

  // LOGIN

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.login),
      switchMap(({email, password}) => {
        return this.authService.loginHttp({email, password})
          .pipe(
            map(({token, user}) => {
              return authActions.loginSuccess({token, user});
            }),
            catchError(error => {
              return of(authActions.loginFailure({error}));
            })
          );
      })
    )
  );

  loginSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(authActions.loginSuccess),
        tap(({token, user}) => {
          localStorage.clear();
          this.authService.token = token;
          this.authService.setTokenTimeout(token);
          this.routingService.routeToInitialView();
          rg4js("setUser", {
            identifier: user.email,
            isAnonymous: false,
            email: user.email,
            firstName: user.firstName,
            fullName: user.firstName + " " + user.lastName
          });
        })
      ),
    {dispatch: false}
  );

  loginWithToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.loginWithToken),
      switchMap(({token}) => {
        return this.authService.loginWithTokenHttp(token)
          .pipe(
            map(({token, user}) => {
              return authActions.loginSuccess({token, user});
            }),
            catchError(error => {
              return of(authActions.loginFailure({error}));
            })
          );
      })
    )
  );

  loginWithTokenSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(authActions.loginWithTokenSuccess),
        tap(({token, user}) => {
          const user2 = (user as any).user;
          // console.log("loginWithTokenSuccess", token, user2);
          localStorage.clear();
          this.authService.token = token;
          this.authService.setTokenTimeout(token);
          this.routingService.routeToInitialView();
          rg4js("setUser", {
            identifier: user2.email,
            isAnonymous: false,
            email: user2.email,
            firstName: user2.firstName,
            fullName: user2.firstName + " " + user2.lastName
          });
        })
      ),
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private auth0: Auth0Service,
    private routingService: RoutingService,
    private router: Router
  ) { }
}
