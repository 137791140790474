<form [formGroup]="vehiclePriceForm">

  <section class="vehicle-price-form-column-layout">

    <app-simple-input
      [parentForm]="financeOptionsForm"
      [name]="'MSRP'"
      [formCtrlName]="'msr'"
      [blur]="autoSubmitFinanceOptionsForm('msr')"
      [type]="'number'"
      [pipe]="'currency'">
    </app-simple-input>

    <app-simple-input
      [parentForm]="financeOptionsForm"
      [name]="'BT Discount'"
      [formCtrlName]="'discount'"
      [pipe]="'currency'"
      [blur]="autoSubmitFinanceOptionsForm('discount')"
      [type]="'number'"
      [readOnly]="true">
    </app-simple-input>

    <app-simple-input
      [parentForm]="financeOptionsForm"
      [name]="'Retail Price'"
      [formCtrlName]="'retail'"
      [pipe]="'currency'"
      [blur]="autoSubmitFinanceOptionsForm('retail')"
      [type]="'number'">
    </app-simple-input>

    <hr class="standard-section-breaker">

    <app-smw-rebates-form
      [dealIncentives]="dealIncentives"
      [vehicleIncentives]="vehicleIncentives"
      [dealType]="selectedDealType"
      (updateIncentives)="onUpdateIncentives($event)">
    </app-smw-rebates-form>

    <div *ngFor="let incentive of incentives" class="ml-4">
      <ng-container *ngIf="['Lease Subvention', 'APR Subvention'].includes(incentive.type)">
        <app-simple-input
          *ngIf="incentive.value > 0"
          [name]="incentive.type"
          [fieldValue]="incentive.value"
          type="number"
          pipe="currency">
        </app-simple-input>
      </ng-container>
    </div>

    <div class="app-bg-lt-gray px-1 my-1 py-1 d-flex justify-content-between">
      <span>Rebates Total</span>
      <span>{{ selectedCashIncentivesTotal | currency }}</span>
    </div>

    <hr class="standard-section-breaker">


    <div class="app-bg-lt-gray px-1 my-1 py-1 d-flex justify-content-between font-weight-bold">
      <span>Vehicle Subtotal</span>
      <span>{{ baseVehiclePrice | currency }}</span>
    </div>

  </section>

  <hr class="standard-section-breaker">

  <div *ngIf="dealerAccessories && dealerAccessories.length>0" class="accessories-container">
    <label class="desking-column-sub-label underlined">Dealer Accessories</label>
    <div *ngFor="let dealerAcc of dealerAccessories" class="accessories-row">
      <input
        type="checkbox"
        class="form-check"
        [checked]="isDealerAccessorySelected(dealerAcc)"
        (click)="updateDealerAccessorySelected(dealerAcc,dealerAccessories)"/>
      <div class="accessory-name-cell">{{ dealerAcc.name }} </div>
      <div>{{ dealerAcc.price | currency: "USD":"symbol":"1.0-0" }}</div>
    </div>
  </div>

  <app-accessories-itemized
    [autoSubmitAccessories]="autoSubmitAccessories"
    [accessories]="accessoriesList"
    [accessoriesTotal]="accessoriesTotal"
    [flagChange]="flagChange"
    [parentForm]="vehiclePriceForm">
  </app-accessories-itemized>

  <div class="app-bg-lt-gray px-1 my-1 py-1 d-flex justify-content-between">
    <span>Accessories Total</span>
    <span>{{ accessoriesTotal + dealerAccessoriesTotal | currency }}</span>
  </div>

  <hr class="standard-section-breaker">

  <app-simple-input
    [parentForm]="financeOptionsForm"
    [blur]="autoSubmitFinanceOptionsForm('docFees')"
    [formCtrlName]="'docFees'"
    [pipe]="'currency'"
    [name]="'Document Fee'"
    [type]="'number'">
  </app-simple-input>

  <app-simple-input
    [parentForm]="financeOptionsForm"
    [blur]="autoSubmitFinanceOptionsForm('eFilingFee')"
    [formCtrlName]="'eFilingFee'"
    [pipe]="'currency'"
    [name]="'Electronic Filing Fee'"
    [listenerEnabled]="true"
    [type]="'number'">
  </app-simple-input>

  <div class="form-check-row">
    <input class="form-check-input check" type="checkbox" [checked]="financeOptions.plateTransfer" (click)="onTogglePlateTransfer()"/>
    <label>Plate Transfer ({{PLATE_TRANSFER_FEE | currency: "USD":"symbol":"1.2-2"}})</label>
  </div>

  <app-simple-input
    [parentForm]="financeOptionsForm"
    [blur]="autoSubmitFinanceOptionsForm('regFee')"
    [formCtrlName]="'regFee'"
    [pipe]="'currency'"
    [name]="'Registration Fee'"
    [listenerEnabled]="true"
    [flagChange]="flagChange"
    [type]="'number'">
  </app-simple-input>

  <app-simple-input
    [parentForm]="financeOptionsForm"
    [blur]="autoSubmitFinanceOptionsForm('countyFee')"
    [formCtrlName]="'countyFee'"
    [pipe]="'currency'"
    [name]="'County Fee'"
    [type]="'number'">
  </app-simple-input>

  <app-simple-input
    [parentForm]="financeOptionsForm"
    [blur]="autoSubmitFinanceOptionsForm('titleFee')"
    [formCtrlName]="'titleFee'"
    [pipe]="'currency'"
    [name]="'Title Fee'"
    [type]="'number'">
  </app-simple-input>

  <app-simple-input
    [parentForm]="financeOptionsForm"
    [blur]="autoSubmitFinanceOptionsForm('totalStateFees')"
    [formCtrlName]="'totalStateFees'"
    [pipe]="'currency'"
    [name]="'Total State Fees'"
    [listenerEnabled]="true"
    [flagChange]="flagChange"
    [type]="'number'">
  </app-simple-input>

  <app-simple-input
    [parentForm]="dealDataForm"
    formCtrlName="dmvRemarks"
    name="DMV Remarks"
    type="text"
    [maxLength]="REQS.maxLength.standard"
    [blur]="autoSubmitDmvRemarks('dmvRemarks')"
    [keydown]="flagChange">
  </app-simple-input>

  <div class="app-bg-lt-gray px-1 my-1 py-1 d-flex justify-content-between">
    <span>Fees Total</span>
    <span>{{ calcFees?.totalFees | currency }}</span>
  </div>

  <hr class="standard-section-breaker">

  <ng-container *ngIf="financeOptions">
    <app-simple-input
      [parentForm]="financeOptionsForm"
      [blur]="autoSubmitFinanceOptionsForm('privilegeTax')"
      [formCtrlName]="'privilegeTax'"
      [name]="'Privilege Tax'"
      [pipe]="'currency'"
      [type]="'number'">
    </app-simple-input>

    <app-simple-input
      [parentForm]="financeOptionsForm"
      [blur]="autoSubmitFinanceOptionsForm('salesTax')"
      [formCtrlName]="'salesTax'"
      [name]="'Sales Tax'"
      [pipe]="'currency'"
      [listenerEnabled]="true"
      [flagChange]="flagChange"
      [type]="'number'">
    </app-simple-input>

    <app-simple-input
      [parentForm]="financeOptionsForm"
      [blur]="autoSubmitFinanceOptionsForm('catTax')"
      [formCtrlName]="'catTax'"
      [name]="'CAT Recovery'"
      [pipe]="'currency'"
      [type]="'number'">
    </app-simple-input>

    <app-simple-input
      [parentForm]="financeOptionsForm"
      [blur]="autoSubmitFinanceOptionsForm('totalStateTaxes')"
      [formCtrlName]="'totalStateTaxes'"
      [name]="'Total State Taxes'"
      [pipe]="'currency'"
      [type]="'number'">
    </app-simple-input>

  </ng-container>

  <div class="app-bg-lt-gray px-1 my-1 py-1 d-flex justify-content-between">
    <span>Taxes Total</span>
    <span>{{ taxResult.totalTax | currency }}</span>
  </div>

  <hr class="standard-section-breaker">

  <app-simple-input
    [parentForm]="financeOptionsForm"
    [blur]="autoSubmitFinanceOptionsForm('invoice')"
    [formCtrlName]="'invoice'"
    [name]="'Invoice / Wholesale'"
    [pipe]="'currency'"
    [type]="'number'">
  </app-simple-input>

</form>
