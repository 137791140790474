import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { User } from "src/app/user-admin-module/models";
import { Subject } from "rxjs";
import { CalculationService, DealService, LeaseCalculationService, VehicleService } from "src/app/clearpath-module/services";
import { UserService } from "src/app/user-admin-module/services";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-sales-manager-form",
  templateUrl: "./sales-manager-form.component.html",
  styleUrls: ["./sales-manager-form.component.scss"]
})
export class SalesManagerFormComponent implements OnInit {
  private unsubscribe$ = new Subject();
  @Output() submitSalesPerson = new EventEmitter<User>();
  @Output() submitSalesManager = new EventEmitter<User>();
  @Output() flagUnsavedChanges = new EventEmitter<boolean>();

  private uiState = {
    deal: null,
    showSalesTeamForm: false,
    formOptions: {
      salesPeople: null,
      salesManagers: null,
    }
  };

  salesTeamForm = this.formBuilder.group({
    salesPersonEmployeeId: ["", Validators.required],
    salesManagerEmployeeId: ["", Validators.required]
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private calcService: CalculationService,
    private vehicleService: VehicleService,
    private dealService: DealService,
    private leaseCalcService: LeaseCalculationService
  ) { }

  ngOnInit() {
    this.getSalesPeople();
    this.getSalesManagers();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // INITIALIZATION

  private getSalesPeople() {
    this.userService.getSalesPeople()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (salesPeople: User[]) => {
          this.uiState.formOptions.salesPeople = salesPeople || [];
        }
      });
  }

  private getSalesManagers() {
    this.userService.getSalesManagers()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (salesManagers: User[]) => {
          this.uiState.formOptions.salesManagers = salesManagers || [];
        }
      });
  }

  get salesPeople(): User[] {
    return this.uiState.formOptions.salesPeople || [];
  }

  get salesManagers(): User[] {
    return this.uiState.formOptions.salesManagers || [];
  }


  autoSubmitSalesPerson = () => {
    const {salesPersonEmployeeId: employeeId} = this.salesTeamForm.value;
    if (!employeeId) { return alert("Sales Person has no employee ID."); }
    const salesPerson = this.salesPeople.find(employee => employeeId === employee.employeeId);
    this.submitSalesPerson.emit(salesPerson);
  }

  autoSubmitSalesManager = () => {
    const {salesManagerEmployeeId: employeeId} = this.salesTeamForm.value;
    if (!employeeId) { return alert("Sales Manager has no employee ID."); }
    const salesManager = this.salesManagers.find(employee => employeeId === employee.employeeId);
    this.submitSalesManager.emit(salesManager);
  }

}
