import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AppService, DealService, VehicleService } from "../../services";
import { AuthService } from "src/app/auth-module/services";
import { User } from "src/app/user-admin-module/models";
import { PubnubService } from "src/app/shared-module/services";
import { Actions, ofType } from "@ngrx/effects";
import * as appActions from "../../../clearpath-module/store/actions/app.actions";
import { DealState } from "../../store/state";

@Component({
  selector: "app-sales-person-writeups-list",
  templateUrl: "./sales-person-writeups-list.component.html",
  styleUrls: ["./sales-person-writeups-list.component.scss"]
})
export class SalesPersonWriteupsListComponent implements OnInit, OnDestroy {

  deals = [];
  loading = true;
  searchText: string;
  selectedManagerId: string;
  currentUser: User;
  private unsubscribe$ = new Subject();

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private dealService: DealService,
    private vehicleService: VehicleService,
    private pubsub: PubnubService,
    private router: Router,
    private actions$: Actions,
  ) { }

  ngOnInit() {
    this.subToCurrentUser();
    this.subToDeals();
    this.subToSearchString();
    this.subToSelectedManagerId();
    this.watchDealChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // INITIALIZATION

  private subToDeals() {
    this.dealService.dispatchGetDeals();

    this.dealService.selectDealsLoadingStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((loading: boolean) => {
        this.loading = loading;
      });

    this.dealService.selectAllDeals()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((deals: Array<any>) => {
        this.deals = deals;
      });
  }

  private subToSearchString() {
    this.appService.selectSearchString()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((searchString: string) => {
        this.searchText = searchString;
      });
  }

  private subToSelectedManagerId() {
    this.appService.selectSelectedManagerId()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((selectedManagerId: string) => {
        this.selectedManagerId = selectedManagerId;
      });
  }

  private subToCurrentUser() {
    this.authService.selectUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: User) => {
        this.currentUser = user;
      });
  }

  private watchDealChanges() {
    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(appActions.pubsubMessage),
    ).subscribe(({messageName, dealId}) => {
      console.log("Pubnub:", messageName, dealId);
      if (messageName === this.pubsub.Messages.DealApproved) {
        alert("Deal Submitted");
        this.dealService.dispatchGetDeals();
      }
      if (messageName === this.pubsub.Messages.DealCreated) {
        alert("Deal Created");
        this.dealService.dispatchGetDeals();
      }
      if (messageName === this.pubsub.Messages.DealUpdated) {
        alert("Deal Retracted");
        this.dealService.dispatchGetDeals();
      }
    });
  }

  // ACTIONS

  onSelectDeal(deal) {
    this.vehicleService.getVehicle(deal.stockNumber, null);
    this.router.navigate(["clearpath", "deal", deal.dealId]);
    // RouterEffects listens to the routing and loads the deal
  }

}

