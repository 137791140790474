import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FinanceRate } from "src/app/settings-module/models";
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { pathOr } from "ramda";

@Component({
  selector: "app-finance-rates-form",
  templateUrl: "./finance-rates-form.component.html",
  styleUrls: ["./finance-rates-form.component.scss"]
})
export class FinanceRatesFormComponent implements OnInit {
  @Input() financeRates: FinanceRate[] = [];
  @Input() waitOnAction = false;
  @Output() submitFinanceRates = new EventEmitter<FinanceRate[]>();
  @Output() unsavedChanges = new EventEmitter();

  financeRatesForm = this.formBuilder.group({
    termRates: new UntypedFormArray([])
  });

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.buildFinanceRatesForm();
    this.subToFormChanges();
  }

  // INITIALIZATION

  private buildFinanceRatesForm() {
    this.financeRates.forEach((item: FinanceRate) => {
      this.addTermRateControl(item);
    });
  }

  private subToFormChanges() {
    this.financeRatesForm.valueChanges.subscribe(() => {
      this.unsavedChanges.emit();
    });
  }

  // ACTIONS

  onSubmitFinanceRatesForm() {
    const {termRates} = this.financeRatesForm.value;
    this.submitFinanceRates.emit(termRates);
    this.financeRatesForm.markAsPristine();
  }

  // UI CONTROL & RENDERING

  onAddTermRate() {
    this.addTermRateControl();
    this.financeRatesForm.markAsDirty();
  }

  onRemoveTermRate(index: number) {
    const termRates = this.financeRatesForm.get("termRates") as UntypedFormArray;
    termRates.removeAt(index);
    this.financeRatesForm.markAsDirty();
  }

  onRemoveTier(termIndex: number, index: number) {
    const termRates = this.financeRatesForm.get("termRates") as UntypedFormArray;
    const termRateFormGroup = termRates.controls[ termIndex ] as UntypedFormGroup;
    const tieredRate = termRateFormGroup.get("tieredRates") as UntypedFormArray;
    tieredRate.removeAt(index);
    this.financeRatesForm.markAsDirty();
  }

  onAddTierRate(termIndex: number) {
    const termRates = this.financeRatesForm.get("termRates") as UntypedFormArray;
    const termRateFormGroup = termRates.controls[ termIndex ] as UntypedFormGroup;
    const tieredRates = termRateFormGroup.get("tieredRates") as UntypedFormArray;
    tieredRates.push(new UntypedFormControl([
      null,
      [Validators.required, Validators.min(0), Validators.max(1)]
    ]));
    this.financeRatesForm.markAsDirty();
  }

  get termRateControls(): AbstractControl[] {
    const termRates = this.financeRatesForm.get("termRates") as UntypedFormArray;
    return termRates.controls;
  }

  tieredRatesControls(index: number): AbstractControl[] {
    const tieredRates = this.financeRatesForm.get(["termRates", index, "tieredRates"]) as UntypedFormArray;
    return tieredRates.controls;
  }

  // FORM VALIDATION & HELPERS

  private createTermRateFormGroup(termRate: FinanceRate): UntypedFormGroup {
    const tieredRates = pathOr([], ["tieredRates"], termRate);
    const tieredRatesArray = [ // 8 Credit Tiers
      [null, [Validators.required, Validators.min(0), Validators.max(1)]],
      [null, [Validators.required, Validators.min(0), Validators.max(1)]],
      [null, [Validators.required, Validators.min(0), Validators.max(1)]],
      [null, [Validators.required, Validators.min(0), Validators.max(1)]],
      [null, [Validators.required, Validators.min(0), Validators.max(1)]],
      [null, [Validators.required, Validators.min(0), Validators.max(1)]],
      [null, [Validators.required, Validators.min(0), Validators.max(1)]],
      [null, [Validators.required, Validators.min(0), Validators.max(1)]],
    ];
    const formGroup: UntypedFormGroup = this.formBuilder.group({
      term: [null, [
        Validators.required,
        Validators.min(1)
      ]],
      minYear: [null, [
        Validators.required,
        Validators.min(1000),
        Validators.max(9999)
      ]],
      tieredRates: this.formBuilder.array(
        tieredRates.length ?
          tieredRates.slice(0, tieredRates.length) :
          tieredRatesArray
      )
    });
    return formGroup;
  }

  private addTermRateControl(termRate?: FinanceRate) {
    const formGroup = this.createTermRateFormGroup(termRate);
    if (termRate) {
      const {term, minYear, tieredRates} = termRate;
      formGroup.patchValue({term, minYear, tieredRates});
    }

    const termRates = this.financeRatesForm.get("termRates") as UntypedFormArray;
    termRates.push(formGroup);
  }

  touchedInvalid(termRateIndex: number, controlName: string): boolean {
    const control = this.financeRatesForm.get(
      ["termRates", termRateIndex, controlName]
    );
    return control.touched && control.invalid;
  }

  touchedInvalidRate(termRateIndex: number, tieredRateIndex: number): boolean {
    const control = this.financeRatesForm.get(
      ["termRates", termRateIndex, "tieredRates", tieredRateIndex]
    );
    return control.touched && control.invalid;
  }

}
