import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { DealService } from "src/app/clearpath-module/services";
import { FORM_INPUT_REQS } from "src/app/app.config";
import { Insurance } from "src/app/clearpath-module/models";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

const insuranceInformationFormTemplate = {
  companyName: [""],
  agentName: [""],
  street: [""],
  city: [""],
  state: [""],
  zip: [""],
  phone: [""],
  policyNumber: [""],
  policyEffectiveDate: [""],
  policyExpirationDate: [""]
};

@Component({
  selector: "app-insurance-info",
  templateUrl: "./insurance-info.component.html",
  styleUrls: ["./insurance-info.component.scss"]
})
export class InsuranceInfoComponent implements OnInit {
  private unsubscribe$ = new Subject();
  @Output() flagUnsavedChanges = new EventEmitter<boolean>();

  public REQS = FORM_INPUT_REQS;
  insuranceInformationForm = this.formBuilder.group(insuranceInformationFormTemplate);
  insuranceInfo: Insurance;
  uiState = {
    policyEffectiveDate: "",
    policyExpirationDate: ""
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dealService: DealService
  ) { }

  ngOnInit() {
    this.dealService.selectInsuranceInfo()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((insuranceInfo) => {
        this.insuranceInfo = insuranceInfo;
        this.insuranceInformationForm.patchValue(insuranceInfo);
        const policyEffectiveDate = new Date(this.insuranceInfo.policyEffectiveDate);
        this.uiState.policyEffectiveDate = (policyEffectiveDate.getMonth() + 1) + "/" + policyEffectiveDate.getDate() + "/" + policyEffectiveDate.getFullYear();
        const policyExpirationDate = new Date(this.insuranceInfo.policyExpirationDate);
        this.uiState.policyExpirationDate = (policyExpirationDate.getMonth() + 1) + "/" + policyExpirationDate.getDate() + "/" + policyExpirationDate.getFullYear();
      });
  }

  autoSubmitForm = () => {
    const {pristine, invalid} = this.insuranceInformationForm;
    if (pristine || invalid) { return; }

    const insurance: Partial<Insurance> = this.insuranceInformationForm.value;

    this.insuranceInformationForm.markAsUntouched();
    this.insuranceInformationForm.markAsPristine();
    this.flagChange();
    const policyEffectiveNewDate = new Date(insurance.policyEffectiveDate).toISOString();
    const policyExpirationNewDate = new Date(insurance.policyExpirationDate).toISOString();
    insurance.policyEffectiveDate = policyEffectiveNewDate;
    insurance.policyExpirationDate = policyExpirationNewDate;
    this.dealService.dispatchSetInsuranceInfo(insurance);
  };

  flagChange = () => {
    this.flagUnsavedChanges.emit(true);
  };

  clearInsuranceInfo = () => {
    this.insuranceInformationForm.patchValue({
      companyName: "",
      agentName: "",
      street: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      policyNumber: "",
      policyEffectiveDate: "0001-01-01T00:00:00Z",
      policyExpirationDate: "0001-01-01T00:00:00Z"
    });
    const insurance: Partial<Insurance> = this.insuranceInformationForm.value;
    this.insuranceInformationForm.markAsUntouched();
    this.insuranceInformationForm.markAsPristine();
    this.dealService.dispatchSetInsuranceInfo(insurance);
    this.flagChange();
  };
}
