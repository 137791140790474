import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-custom-price-dialog',
  templateUrl: './custom-price-dialog.component.html',
  styleUrls: ['./custom-price-dialog.component.scss']
})
export class CustomPriceDialogComponent {
  public title: number;
  public originalPrice: number;
  public customPrice: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CustomPriceDialogComponent>) {
    this.title = data.title;
    this.originalPrice = data.originalPrice;
    this.customPrice = data.customPrice;
  }

  isValidNumber(value: any): boolean {
    const twoDecimalRegex = /^\d*\.?\d{0,2}$/;
    const numValue = String(value);
    return value !== null &&
      value !== undefined &&
      !isNaN(Number(value)) &&
      value > 0 &&
      twoDecimalRegex.test(numValue);
  }

  formatAmount() {
    if (this.customPrice) {
      // Round to 2 decimal places
      this.customPrice = Math.round(this.customPrice * 100) / 100;
    }
  }

  updateCustomPrice() {
    this.dialogRef.close({action: 'update', value: this.customPrice});
  }

  removeCustomPrice() {
    this.dialogRef.close({action: 'remove'});
  }
}
