import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { FORM_INPUT_REQS } from "src/app/app.config";

@Component({
  selector: "app-stock-number",
  templateUrl: "./stock-number.component.html",
  styleUrls: ["./stock-number.component.scss"]
})
export class StockNumberComponent implements OnInit {

  public REQS = FORM_INPUT_REQS;
  stockNumForm: UntypedFormGroup = this.formBuilder.group({
    stockNum: [""]
  });

  constructor(private formBuilder: UntypedFormBuilder, private router: Router) { }

  ngOnInit() {
  }

  onSubmit(formValues) {
    const {
      stockNum
    } = formValues;
    this.router.navigate(["/clearpath/new-deal", stockNum.trim()]);
  }

}
