import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from "@angular/forms";
import { DealService } from "src/app/clearpath-module/services";
import { WARRANTY_SETTINGS } from "src/app/clearpath-module/components/warranty/warranty-config";
import { FORM_INPUT_REQS } from "src/app/app.config";
import { Buyer, FinanceOptions, VehicleNeeds, WarrantySettingOption } from "src/app/clearpath-module/models";
import { Observable } from "rxjs";
import { DealDefaults } from "src/app/clearpath-module/services/deal/deal-defaults.service";

@Component({
  selector: "app-buyer-form",
  templateUrl: "./buyer-form.component.html",
  styleUrls: ["./buyer-form.component.scss"]
})
export class BuyerFormComponent implements OnInit {

  @Output() flagUnsavedChanges = new EventEmitter<boolean>();

  // Customer OR CoSigner: Pick One
  @Input() set buyer(buyer: Buyer) {
    this.syncBuyerForm(buyer);
    // this.buyer = buyer;
  }

  @Input() set vehicleNeeds(vehicleNeeds: VehicleNeeds) {
    this.syncVehicleNeedsForm(vehicleNeeds);
    // this.buyer = buyer;
  }

  @Input() set financeOptions(financeOptions: FinanceOptions) {
    this.syncFinanceOptionsForm(financeOptions);
    // this.buyer = buyer;
  }

  @Input() dealDefaults: DealDefaults;
  @Input() buyerIsCustomer: boolean;
  @Input() buyerIsCoSigner: boolean;
  customer$: Observable<Buyer>;
  forceChildFormUpdate = 0;

  uiState = {completedDeal: false};

  public REQS = FORM_INPUT_REQS;
  buyerForm: UntypedFormGroup = this.formBuilder.group({
    companyName: [""],
    firstName: [""],
    middleName: [""],
    lastName: [""],
    street: [""],
    city: [""],
    county: [""],
    state: [""],
    zip: [""],
    phone: [""],
    email: [""],
    birthdate: [""],
    dmsContactId: [""],
    driverId: [""]
  });

  financeOptionsForm: UntypedFormGroup = this.formBuilder.group({
    // Select Values Return As String
    selectedCreditTier: [""],
    salesTax: [0, [Validators.min(0)]],
  });

  vehicleNeedsForm: UntypedFormGroup = this.formBuilder.group({
    // Select Values Return As String
    milesDrivenPerYear: [this.milesPerYearOptions[ 0 ].miles],
    plannedLengthOfOwnership: [this.ownershipYearsOptions[ 0 ].years]
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dealService: DealService,
  ) { }

  ngOnInit() {
    this.setFormValidators();
  }

  // INITIALIZATION

  private setFormValidators() {
    /*
     * amirchandani: buyer validation disabled for now.  Reference issue #1733.
    const { controls } = this.buyerForm;
    const validators = {
      zip: [Validators.minLength(5), Validators.maxLength(5), Validators.pattern("[0-9]*")],
      phone: [Validators.minLength(10), Validators.maxLength(10), Validators.pattern("[0-9]*")]
    };

    if (this.validationOn) {
      controls.firstName.setValidators([Validators.required]);
      controls.lastName.setValidators([Validators.required]);
      controls.street.setValidators([Validators.required]);
      controls.city.setValidators([Validators.required]);
      controls.state.setValidators([Validators.required]);
      controls.birthdate.setValidators([this.birthdateValidator]);
      controls.zip.setValidators([Validators.required, ...validators.zip]);
      controls.phone.setValidators([Validators.required, ...validators.phone]);
      controls.email.setValidators([Validators.required, Validators.email]);
    } else {
      controls.zip.setValidators([...validators.zip]);
      controls.phone.setValidators([...validators.phone]);
      controls.email.setValidators([Validators.email]);
    } */
  }

  private syncBuyerForm(buyer: Buyer) {
    if (!buyer) { return; }
    this.buyerForm.patchValue({
      companyName: buyer.companyName,
      firstName: buyer.firstName,
      middleName: buyer.middleName,
      lastName: buyer.lastName,
      street: buyer.street,
      city: buyer.city,
      state: buyer.state,
      zip: buyer.zip,
      phone: buyer.phone,
      email: buyer.email,
      birthdate: buyer.birthdate || "",
      driverId: buyer.driverId,
      county: buyer.county,
      dmsContactId: buyer.dmsContactId
    });
    this.forceChildFormUpdate = Math.random();
  }

  birthdateValidator(control: AbstractControl): ValidationErrors | null {
    const birthdate = control.value ? new Date(control.value) : "";
    if (birthdate instanceof Date && isNaN(birthdate.valueOf())) {
      return {invalidDate: {value: control.value}};
    }
    if ((birthdate.valueOf() > -2208988800000) && (birthdate.valueOf() < new Date().valueOf())) {
      //console.log("GOOD DATE");
      return;
    } else {
      //console.log("BAD DATE");
      return {invalidDate: {value: control.value}};
    }
  }

  private syncFinanceOptionsForm(financeOptions: FinanceOptions) {
    if (this.buyerIsCustomer === false || !financeOptions) { return; }

    if (
      financeOptions &&
      typeof financeOptions.selectedCreditTier === undefined &&
      !financeOptions.creditTierSelected &&
      this.dealDefaults
    ) {
      financeOptions.selectedCreditTier = this.dealDefaults.selectedCreditTier;
      financeOptions.creditTierSelected = true;
    }
    this.financeOptionsForm.patchValue({
      selectedCreditTier: financeOptions.selectedCreditTier
    });
  }

  private syncVehicleNeedsForm(vehicleNeeds: VehicleNeeds) {
    if (!this.buyerIsCustomer || !vehicleNeeds) { return; }

    let miles = vehicleNeeds.milesDrivenPerYear;
    if (!miles) {
      miles = this.dealDefaults.vehicleNeeds.milesDrivenPerYear;
    }
    const milesOptionExists = this.milesPerYearOptions.find(item => miles === item.miles);
    if (milesOptionExists) {
      this.vehicleNeedsForm.patchValue({milesDrivenPerYear: miles});
    }

    let years = vehicleNeeds.plannedLengthOfOwnership;
    if (!years) {
      years = this.dealDefaults.vehicleNeeds.plannedLengthOfOwnership;
    }
    const yearsOptionExists = this.ownershipYearsOptions.find(item => years === item.years);
    if (yearsOptionExists) {
      this.vehicleNeedsForm.patchValue({plannedLengthOfOwnership: years});
    }
  }

  // FORM ACTIONS & HELPERS

  get validationOn(): boolean {
    return this.buyerIsCustomer;
  }

  get completedDeal(): boolean {
    return this.uiState.completedDeal;
  }

  get milesPerYearOptions(): WarrantySettingOption[] {
    return WARRANTY_SETTINGS.milesDrivenPerYear;
  }

  get ownershipYearsOptions(): WarrantySettingOption[] {
    return WARRANTY_SETTINGS.plannedLengthOfOwnership;
  }

  onClearForm() {
    const confirmed = confirm("Clear Form?");
    if (!confirmed) { return; }

    this.buyerForm.reset();
    this.buyerForm.markAllAsTouched();
    this.buyerForm.markAsDirty();
    this.autoSubmitBuyerForm();

    this.financeOptionsForm.patchValue({selectedCreditTier: 1});
    this.financeOptionsForm.markAsDirty();
    this.autoSubmitFinanceOptionsForm();

    this.vehicleNeedsForm.patchValue({
      milesDrivenPerYear: this.milesPerYearOptions[ 0 ].miles,
      plannedLengthOfOwnership: this.ownershipYearsOptions[ 0 ].years
    });
    this.vehicleNeedsForm.markAsDirty();
    this.autoSubmitVehicleNeedsForm();

    this.flagChange();
  }

  isValidDate(d) {
    return d instanceof Date && !isNaN(d.getTime());
  }

  autoSubmitBuyerForm = () => {
    const {pristine, invalid} = this.buyerForm;
    if (pristine || invalid) { return; }

    const configError = this.buyerIsCustomer === this.buyerIsCoSigner;
    if (configError) { return; }

    const buyer: Partial<Buyer> = this.buyerForm.value;
    if (typeof buyer.birthdate === "string") {
      buyer.birthdate = buyer.birthdate.split("T")[ 0 ];
    }
    if (typeof buyer.birthdate === "object") {
      const tempBdate = new Date(buyer.birthdate).toISOString();
      buyer.birthdate = tempBdate.split("T")[ 0 ];
    }
    if (this.buyerIsCustomer) { this.dealService.dispatchSetCustomer(buyer); }
    if (this.buyerIsCoSigner) { this.dealService.dispatchSetCoSigner(buyer); }
    this.buyerForm.markAsUntouched();
    this.buyerForm.markAsPristine();
    this.flagChange();
  };

  autoSubmitFinanceOptionsForm = () => {
    const {pristine} = this.financeOptionsForm;
    if (pristine) { return; }

    const selectedCreditTier = +this.financeOptionsForm.value.selectedCreditTier;
    const financeOptions: Partial<FinanceOptions> = {
      selectedCreditTier,
      creditTierSelected: true
    };

    this.dealService.clearCustomMoneyFactors();
    this.dealService.dispatchSetFinanceOptions(financeOptions);
    this.financeOptionsForm.markAsPristine();
    this.flagChange();
  };

  autoSubmitVehicleNeedsForm = () => {
    const {pristine} = this.vehicleNeedsForm;
    if (pristine) { return; }

    const milesDrivenPerYear = +this.vehicleNeedsForm.value.milesDrivenPerYear;
    const plannedLengthOfOwnership = +this.vehicleNeedsForm.value.plannedLengthOfOwnership;
    const vehicleNeeds: Partial<VehicleNeeds> = {milesDrivenPerYear, plannedLengthOfOwnership};

    this.dealService.dispatchSetVehicleNeeds(vehicleNeeds);
    this.vehicleNeedsForm.markAsPristine();
    this.flagChange();
  };

  touchedInvalid(controlName: string): boolean {
    const control = this.buyerForm.get(controlName);
    return control.touched && control.invalid;
  }

  flagChange = () => {
    this.flagUnsavedChanges.emit(true);
  };

}
