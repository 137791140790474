import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { DealState, DealStatus } from "src/app/clearpath-module/store/state";
import { User } from "src/app/user-admin-module/models";
import { UserService } from "src/app/user-admin-module/services";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "app-staff-form",
  templateUrl: "./staff-form.component.html",
  styleUrls: ["./staff-form.component.scss"]
})
export class StaffFormComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();

  @Input() set deal(deal: DealState) { this.syncDealData(deal); }

  @Output() submitSalesPerson = new EventEmitter<User>();
  @Output() submitSalesManager = new EventEmitter<User>();
  @Output() flagUnsavedChanges = new EventEmitter<boolean>();

  private uiState = {
    deal: null,
    formOptions: {
      salesPeople: null,
      salesManagers: null,
    }
  };

  salesTeamForm = this.formBuilder.group({
    salesPersonEmployeeId: ["", Validators.required],
    salesManagerEmployeeId: ["", Validators.required]
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.getSalesPeople();
    this.getSalesManagers();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // INITIALIZATION

  private getSalesPeople() {
    this.userService.getSalesPeople()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (salesPeople: User[]) => {
          salesPeople = salesPeople.sort((a, b) => a.lastName.localeCompare(b.lastName));
          this.uiState.formOptions.salesPeople = salesPeople || [];
        }
      });
  }

  private getSalesManagers() {
    this.userService.getSalesManagers()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (salesManagers: User[]) => {
          salesManagers = salesManagers.sort((a, b) => a.lastName.localeCompare(b.lastName));
          this.uiState.formOptions.salesManagers = salesManagers || [];
        }
      });
  }

  private syncDealData(deal: DealState) {
    this.uiState.deal = deal;

    this.salesTeamForm.patchValue({
      salesPersonEmployeeId: deal.salesId,
      salesManagerEmployeeId: deal.managerId
    });

    const completedDeal = deal.status === DealStatus.Completed;
    if (completedDeal) { this.salesTeamForm.disable(); }
  }

  // UI CONTROL & RENDERING

  get salesPeople(): User[] {
    return this.uiState.formOptions.salesPeople || [];
  }

  get salesManagers(): User[] {
    return this.uiState.formOptions.salesManagers || [];
  }

  get salesPerson(): string {
    const {salesId, salesPerson} = this.uiState.deal;
    return salesId ? salesPerson : "---";
  }

  get salesManager(): string {
    const {managerId, salesManager} = this.uiState.deal;
    const managerObject = (this.salesManagers || []).find(mgr => {
      return mgr.employeeId === managerId;
    });
    return managerId && managerObject ?
      `${managerObject.firstName} ${managerObject.lastName}` :
      "---";
  }

  // FORM HELPER

  autoSubmitSalesPerson() {
    const {salesPersonEmployeeId: employeeId} = this.salesTeamForm.value;
    if (!employeeId) { return alert("Sales Person has no employee ID."); }
    const salesPerson = this.salesPeople.find(employee => employeeId === employee.employeeId);
    this.submitSalesPerson.emit(salesPerson);
  }

  autoSubmitSalesManager() {
    const {salesManagerEmployeeId: employeeId} = this.salesTeamForm.value;
    if (!employeeId) { return alert("Sales Manager has no employee ID."); }
    const salesManager = this.salesManagers.find(employee => employeeId === employee.employeeId);
    this.submitSalesManager.emit(salesManager);
  }

  touchedInvalid(controlName: string): boolean {
    const control = this.salesTeamForm.get(controlName);
    return control.touched && control.invalid;
  }

  flagChange() {
    this.flagUnsavedChanges.emit(true);
  }

}
