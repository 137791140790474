import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from "@angular/core";
import {DealState, DealStatus} from "src/app/clearpath-module/store/state";
import {FinanceOptions, TradeIn} from "src/app/clearpath-module/models";
import {CalculationService, DealService} from "src/app/clearpath-module/services";
import {DealIncentive, VehicleIncentive} from "src/app/settings-module/models/incentives";
import {DealDefaults} from "src/app/clearpath-module/services/deal/deal-defaults.service";
import {Store} from "@ngrx/store";
import {combineLatest, Observable, Subject} from "rxjs";
import {selectNetTradeInValue} from "../../../../clearpath-module/store/selectors/deal";
import {Lender} from "../../../models/data";
import {take, takeUntil} from "rxjs/operators";
import {CustomPriceDialogComponent} from "../../dialogs/custom-price-dialog/custom-price-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: "app-cash-form",
  templateUrl: "./cash-form.component.html",
  styleUrls: ["./cash-form.component.scss"]
})
export class CashFormComponent implements OnInit, OnDestroy, OnChanges {

  private unsubscribe$ = new Subject();

  @Input() financeOptions: FinanceOptions;
  @Input() dealIncentives: DealIncentive[];
  @Input() vehicleIncentives: VehicleIncentive[];
  @Input() dealDefaults: DealDefaults;
  @Input() lienHolder: Lender;
  @Input() tradeIn: TradeIn;
  @Input() verified: boolean;

  @Input() set dealStatus(status: string) {
    this.syncFormStatus(status);
  }

  @Output() submitDealType = new EventEmitter<string>();
  @Output() submitIncentives = new EventEmitter<DealIncentive[]>();
  @Output() flagUnsavedChanges = new EventEmitter<boolean>();

  private uiState = {completedDeal: false};

  tradeInNetValue$: Observable<number> = this.store.select(selectNetTradeInValue);

  totalVehicleCashPrice$ = this.calcService.totalVehicleCashPrice$();
  calcTax$ = this.calcService.calcTax$({finance: true});

  lender;
  deal: DealState;

  constructor(
    private store: Store<DealState>,
    private calcService: CalculationService,
    private dealService: DealService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.lender = this.lienHolder;

    combineLatest([
      this.dealService.selectDeal(),
    ]).pipe(takeUntil(this.unsubscribe$)).subscribe(([deal]: [DealState]) => {
      this.deal = deal;
    });
  }

  ngOnChanges() {
    this.totalVehicleCashPrice$ = this.calcService.totalVehicleCashPrice$();
    this.lender = this.lienHolder;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private syncFormStatus(status: string) {
    this.uiState.completedDeal = status === DealStatus.Completed;
  }

  /*onUpdateIncentives(incentives: DealIncentive[]) {
    this.submitIncentives.emit(incentives);
  }*/

  get cashPurchase(): boolean {
    return this.financeOptions.cashPurchase;
  }

  get completedDeal(): boolean {
    return this.uiState.completedDeal;
  }

  flagChange = () => {
    this.flagUnsavedChanges.emit(true);
  };

  updateMonthlyPriceOverride() {
    this.totalVehicleCashPrice$.pipe(take(1)).subscribe(originalPrice => {
      const dialogRef = this.dialog.open(CustomPriceDialogComponent, {
        width: '500px',
        autoFocus: false,
        data: {
          title: 'Override Cash Total Payment',
          originalPrice,
          customPrice: this.deal.financeOptionsEdits.amountFinanced
        }
      });
      dialogRef.beforeClosed().subscribe(result => {
        if (result) {
          // Handle the return value here
          // console.log('Dialog result:', result);
          this.flagChange();
          if (result.action === 'remove') {
            this.dealService.dispatchSetFinanceOptionsEdits({amountFinanced: null});
          }
          if (result.action === 'update') {
            // console.log("Updated Price: ", result.value);
            this.dealService.dispatchSetFinanceOptionsEdits({amountFinanced: result.value});
          }
        }
      });
    });
  }
}
