<section class="header-layout">

  <div class="header-title-container">
    <h1 class="h2 header-title">Vehicle Service Agreement</h1>
    <div class="cost-own-container">
      <span>5 Year Cost to Own -</span>
      <a href="https://www.aaa.com/autorepair/drivingcosts" target="KBBView">AAA Estimate</a>
      <span>|</span>
      <a href="https://www.edmunds.com/tco.html" target="EdmundsView">Edmunds</a>
    </div>
  </div>

  <form [formGroup]="vsaForm" class="header-form">
    <select
      class="header-form-control form-control"
      formControlName="termCostIndex">
      <option value="" disabled>Select A Plan</option>
      <option *ngFor="let option of termCosts; let i = index" [value]="i">
        {{ option.term / 12 }} Years / {{ option.miles || "Unlimited" }} Miles -
        {{ option.price | currency: "USD":"symbol":"1.0-0" }}
      </option>
    </select>
  </form>
</section>

<app-cna-list-dialog-header dialogName="Vehicle Service Agreement"></app-cna-list-dialog-header>

<ul class="nav nav-pills mb-3">
  <li *ngFor="let tab of viewTabs" class="nav-item">
    <a
      [routerLink]="[]"
      class="nav-link"
      [ngClass]="{ active: activeView(tab.id) }"
      (click)="onSelectView(tab.id)">
      {{ tab.title }}
    </a>
  </li>
</ul>

<section *ngIf="activeView('comparison')" class="card card-body mb-3">
  <img
    *ngIf="vehicleCondition == 'new'"
    style="width: 100%; height: 100%; max-width: fit-content"
    src="/static/images/insurance/vsa-02212023.png"
    alt=""/>
  <img
    *ngIf="vehicleCondition !== 'new'"
    style="width: auto; height: auto; max-width: fit-content"
    src="/static/images/insurance/vsa-02212023.png"
    alt=""/>
</section>

<section *ngIf="activeView('vehicleDependability2023')" class="card card-body mb-3">
  <app-jd-study-info></app-jd-study-info>
  <img
    style="width: 100%; height: 100%; max-width: fit-content"
    src="/static/images/insurance/vsa/vehicleDependability2023.png"
    alt=""/>
</section>

<section *ngIf="activeView('vehicleDependability2020')" class="card card-body mb-3">
  <app-jd-study-info></app-jd-study-info>
  <img
    style="width: 100%; height: 100%; max-width: fit-content"
    src="/static/images/insurance/vsa/vehicleDependability2020.png"
    alt=""/>
</section>

<section *ngIf="activeView('cutaway')" class="card card-body mb-3">
  <section class="text-center">
    <img src="/static/images/insurance/Prius.png" class="w-100 p-0"/>
  </section>
</section>

<section *ngIf="activeView('components')" class="card card-body mb-3">
  <strong>TODO:</strong> Add Covered Components Content
</section>

<section *ngIf="activeView('overview')" class="card card-body mb-3">
  <section class="text-center">
    <img src="/static/images/insurance/vsahead.jpg" class="w-100 p-0"/>
  </section>
  <strong>TODO:</strong> Add Overview Content
</section>

<div class="btn-layout">
  <button
    class="btn"
    [ngClass]="planSelected() ? 'app-btn-secondary' : 'app-btn-outline-secondary'"
    type="button"
    (click)="onAcceptPlan()">
    Yes: Include Coverage
  </button>
  <button
    class="btn"
    [ngClass]="noPlanSelected() ? 'app-btn-dark' : 'app-btn-outline-dark'"
    type="button"
    (click)="onDeclinePlan()">
    Decline
  </button>
</div>
