import { Component, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ARCHIVE_OBJECTS, ARCHIVE_PATHS } from "src/app/app.config";
import { MigrateService } from "../../services";
import { AlertService } from "src/app/shared-module/services";

@Component({
  selector: "app-archive",
  templateUrl: "./archive.component.html",
  styleUrls: ["./archive.component.scss"]
})
export class ArchiveComponent implements OnInit {

  constructor(
    private formBuilder: UntypedFormBuilder,
    private migrateService: MigrateService,
    private alertService: AlertService
  ) { }

  archiveRequestLoading: boolean;
  migrateRequestLoading: boolean;
  migrateRequestForm: UntypedFormGroup;
  ARCHIVE_PATHS;

  get objectKeys() {
    return Object.keys(ARCHIVE_OBJECTS);
  }

  getArchivePath(key) {
    return `production${this.ARCHIVE_PATHS[ key ]}${new Date().toISOString().split("T")[ 0 ]}.json`;
  }

  get objectsFormArray() {
    return this.migrateRequestForm.controls.objects as UntypedFormArray;
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.ARCHIVE_PATHS = ARCHIVE_PATHS;
    this.migrateRequestForm = this.formBuilder.group({
      objects: this.formBuilder.array([])
    });
    Object.keys(ARCHIVE_OBJECTS).forEach(key => {
      const formArray = this.migrateRequestForm.controls.objects as UntypedFormArray;
      const formGroup = this.formBuilder.group({
        selected: [true],
        sourceUrl: [this.getArchivePath(key)],
        property: [ARCHIVE_OBJECTS[ key ]]
      });
      formArray.push(formGroup);
    });
  }

  hasSourceUrl(i: number) {
    return this.objectsFormArray.controls[ i ].value.sourceUrl && this.objectsFormArray.controls[ i ].value.selected;
  }

  selectionChanged(i: number) {
    this.objectsFormArray.controls[ i ].value.selected = !this.objectsFormArray.controls[ i ].value.selected;
  }

  runArchiver() {
    this.archiveRequestLoading = true;
    this.migrateService.runArchiver().subscribe({
      next: () => {
        window.scroll({top: 0, behavior: "smooth"});
        this.alertService.success("Request Successful");
        this.archiveRequestLoading = false;
      },
      error: (err) => {
        window.scroll({top: 0, behavior: "smooth"});
        this.alertService.error(err);
        this.archiveRequestLoading = false;
      }
    });
  }

  migrateRequest() {
    if (!this.migrateRequestForm.valid) {
      window.scroll({top: 0, behavior: "smooth"});
      return this.alertService.error("Please make sure the form is correctly filled out.");
    }
    let migrateReq = this.migrateRequestForm.value.objects;
    migrateReq = migrateReq.filter(obj => {
      return obj.selected && obj.sourceUrl.length;
    });
    //console.log("Migrate Request:", migrateReq);
    this.migrateService.migrateFromUrl(migrateReq).subscribe({
      next: () => {
        window.scroll({top: 0, behavior: "smooth"});
        this.alertService.success("Migration Request Successful. " + migrateReq.length + " targets selected for migration.");
        this.migrateRequestLoading = false;
        this.initForm();
      },
      error: (err) => {
        window.scroll({top: 0, behavior: "smooth"});
        this.alertService.error(err);
        this.migrateRequestLoading = false;
      }
    });
  }

  touchedInvalid(index: number): boolean {
    const touched = this.objectsFormArray.controls[ index ].touched;
    const valid = this.objectsFormArray.controls[ index ].valid;
    return touched && !valid;
  }
}

