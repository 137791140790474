<div class="app-page-container">

  <button
    *ngFor="let button of buttons"
    class="link-button btn btn-block app-btn-secondary mx-auto mb-3"
    [routerLink]="button.link">
    {{ button.name }}
  </button>

</div>
