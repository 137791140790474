import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { VehicleService } from "../../services";
import { FORM_INPUT_REQS } from "src/app/app.config";
import { Actions, ofType } from "@ngrx/effects";
import { combineLatest, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import * as vehicleActions from "src/app/clearpath-module/store/actions/vehicle.actions";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { HistoryService } from "../../services/history.service";

@Component({
  selector: "app-stock-number",
  templateUrl: "./stock-number.component.html",
  styleUrls: ["./stock-number.component.scss"]
})
export class StockNumberComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();
  @Output() formSubmitFlag = new EventEmitter();
  error = "";
  currentStockNumber = '';

  public REQS = FORM_INPUT_REQS;
  stockNumberForm: UntypedFormGroup = this.formBuilder.group({
    stockNumber: [""],
    odometer: [null, Validators.min(0)],
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private vehicleService: VehicleService,
    private router: Router,
    private actions$: Actions,
    private activatedRoute: ActivatedRoute,
    private historyService: HistoryService,
  ) { }

  ngOnInit() {

    combineLatest([
      this.vehicleService.selectVehicle()
    ])
      .pipe(take(1))
      .subscribe(([vehicle]) => {
        this.currentStockNumber = vehicle.stockNumber;
      });

    this.activatedRoute.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        if (params.stockNumber !== "") {
          this.stockNumberForm.patchValue({stockNumber: params.stockNumber});
        }
      });

    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(
        vehicleActions.changeStockNumberSuccess,
        vehicleActions.changeStockNumberFailure
      ),
    )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((action) => {
        if ('error' in action) {
          this.error = action.error;
        } else {
          this.formSubmitFlag.emit();
        }
      });

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openVehicleLookup() {
    const navigationExtras: NavigationExtras = {
      queryParams: {changeDealOnSelectVehicle: false, dispatchStockNumberOnly: true,},
    };
    this.router.navigate([{
      outlets: {
        modal: ["vehicle-lookup"]
      },
    }], navigationExtras);
  }

  onSubmitForm() {
    const {stockNumber, odometer} = this.stockNumberForm.value;
    this.vehicleService.dispatchChangeStockNumber(stockNumber, odometer);
    this.historyService.dispatchAddEvent({
      shortName: "Stock Number Changed",
      oldValue: this.currentStockNumber,
      newValue: stockNumber,
    });
  }
}
