import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { pathOr } from "ramda";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "app-simple-input",
  templateUrl: "./simple-input.component.html",
  styleUrls: ["./simple-input.component.scss"]
})
export class SimpleInputComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild("main", {static: false}) main: ElementRef;
  private unsubscribe$ = new Subject();

  @ViewChild("input", {static: false}) set content(input: ElementRef) {
    if (input) { // initially setter gets called with undefined
      this.inputPlaceHolder = input;
      if (this.waitingForFocus) {
        setTimeout(() => {
          this.inputPlaceHolder.nativeElement.focus();
          this.inputPlaceHolder.nativeElement.select();
        }, 100);
      }
    }
  }

  private inputPlaceHolder: ElementRef;

  constructor() { }

  @Input() parentForm: UntypedFormGroup;
  @Input() forceUpdate: any;
  @Input() formCtrlName: string;
  @Input() fieldValue;
  @Input() name: string;
  @Input() type: string;
  @Input() pipe: string;
  @Input() maxLength: number;
  @Input() minLength: number;
  @Input() required: boolean;
  @Input() additionalFieldValue;
  @Input() disabled: boolean;
  @Input() readOnly: boolean;
  @Input() isBold = false;
  @Input() listenerEnabled = false;
  listenerAdded = false;
  waitingForFocus = false;
  editing = false;
  value;
  @Input() blur = () => { };
  @Input() keydown = () => { };
  @Input() change = () => { };
  @Input() flagChange = () => { };

  ngOnInit() {
  }

  ngAfterViewInit() {

    if (this.listenerEnabled && !this.listenerAdded && this.parentForm && this.formCtrlName) {
      this.listenerAdded = true;
      //console.log("Added Listener to SimpleInputComponent:", this.formCtrlName)
      this.parentForm.get(this.formCtrlName).valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
        //console.log('Field value changed:', value);
        if (value !== this.fieldValue) {
          //console.log("SimpleInput Value Changed:", this.formCtrlName, value, this.fieldValue)
          //this.flagChange();
        }
        this._init();
      });
    }
    this._init();
  }

  ngOnChanges() {
    this._init();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  _init() {
    const value = pathOr(null, ["parentForm", "value", this.formCtrlName], this);
    if (value || value === "" || value === 0) {
      this.fieldValue = value;
    }
    if (this.fieldValue === null || this.fieldValue === undefined) {
      if (this.type === "text") {
        this.fieldValue = "";
      }
      if (this.type === "number") {
        this.fieldValue = 0;
      }
    }
    if (this.type === "number" && isNaN(parseFloat(this.fieldValue))) {
      this.fieldValue = 0;
    }


  }

  get isDisabled() {
    if (this.disabled) { return true; }
    if (!this.parentForm) { return true; }
    const control = this.parentForm.get(this.formCtrlName);
    if (control) {
      return control.disabled;
    }
  }

  editValue() {
    this.editing = true;
    if (this.inputPlaceHolder?.nativeElement) {
      setTimeout(() => {
        this.inputPlaceHolder.nativeElement.focus();
        this.inputPlaceHolder.nativeElement.select();
      }, 100);
    } else {
      this.waitingForFocus = true;
    }
  }

  focusAction() {
    if (this.inputPlaceHolder?.nativeElement) {
      this.inputPlaceHolder.nativeElement.focus();
      this.inputPlaceHolder.nativeElement.select();
    }
    this.editing = true;
  }

  blurAction() {
    this.editing = false;
    this._init();
    this.blur();
  }

  touchedInvalid(): boolean {
    if (!this.parentForm) { return; }
    const control = this.parentForm.get(this.formCtrlName);
    if (control) {
      return control.touched && control.invalid;
    }
  }

  showForm() {
    // if (this.formCtrlName === "acquisitionFee") {
    //   console.log("type:");
    // }
    let show = false;
    const value = pathOr(null, ["parentForm", "value", this.formCtrlName], this) || this.fieldValue;
    if (this.parentForm) {
      if (this.editing) {
        show = true;
      }
      if (this.type === "text" && (value === "" || value === null)) {
        show = true;
      }
      if (this.type === "date" && (value === "" || value === null)) {
        show = true;
      }
      if (this.type === "number" && value === null || value === undefined) {
        show = true;
      }
      if (this.type === "string" && (value === "" || value === null)) {
        show = true;
      }
    }
    return show;
  }

  hasAdditionalFieldValue() {
    return this.additionalFieldValue !== undefined;
  }

  onMainFocus() {
    this.editValue();
  }

  onMainFocusOut() {
    this.editing = false;
  }

  onInputFocusOut() {
    this.editing = false;
  }

  isDefaultDate = (d: any): boolean => {
    if (d === "" || d === undefined || d === null || d === "0001-01-01T00:00:00Z") {return true; }
    if (!(d instanceof Date))
      d = new Date(d);
    if (!(typeof d.getFullYear === "function") || d.getTime() === 0) {return true; }
    return d.getFullYear() === 0;
  };

  formatDateString(d: any): string {
    try {
      if (this.isDefaultDate(d)) {
        return "MM/DD/YYYY";
      } else if (d instanceof Date) {
        const [year, month, day] = d.toISOString().substr(0, 10).split("-");
        return [month, day, year].join("/");
      }
      const d2 = new Date(d.replace(',', '').substr(0, 10));
      const [year, month, day] = d2.toISOString().substr(0, 10).split("-");
      return [month, day, year].join("/");
    } catch (e) {
      //console.log("formatDateString error. falling back to today's date. error=", e.getMessage())
      const [year, month, day] = new Date().toISOString().substr(0, 10).split("-");
      return [month, day, year].join("/");
    }
  }
}
