import { Component, Input } from "@angular/core";
import { AppService } from "src/app/clearpath-module/services";
import { Router } from "@angular/router";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent {

  @Input() showRecords: boolean;

  writeupListLength$ = this.appService.selectWriteupListLength();
  userRoles: string[];

  constructor(private appService: AppService, private router: Router) { }

  feedback() {
    this.router.navigate([{outlets: {modal: "feedback"}}]);
  }
}
