import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, NavigationEnd, NavigationError, Params, Router, RouterEvent} from "@angular/router";
import {combineLatest, Subject} from "rxjs";
import {first, map, takeUntil} from "rxjs/operators";
import {AppService, VehicleService} from "./clearpath-module/services";
import {AuthService} from "./auth-module/services";
import {User} from "./user-admin-module/models";
import {PubnubService} from "./shared-module/services";
import {USER_ROLES} from "./app.config";
import {Vehicle} from "./clearpath-module/models";
import * as rg4js from "raygun4js";
import {consoleLog, FeatureFlag, getCurrentEnv, isFlagEnabled} from "./util/featureFlags";
import {AuthService as Auth0Service} from '@auth0/auth0-angular';
import {DOCUMENT} from "@angular/common";

export function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
}

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<any> = new Subject();

  // private timerSubscription: Subscription;
  // private readonly CHECK_INTERVAL = 60000; // 1 minute in milliseconds

  currentRelease: string = "2.0.50";
  currentUser: User;
  currentRoute: string;

  initialDataLoaded: boolean = false;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private router: Router,
    private vehicleService: VehicleService,
    private pubsub: PubnubService,
    private activatedRoute: ActivatedRoute,
    private auth0: Auth0Service,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  ngOnInit() {
    //this.redirectToHttps();

    console.log('---------------------------------------------------');
    console.log("Starting: Northstar_Release_" + this.currentRelease);
    console.log(`ENV: ${getCurrentEnv()}`);
    consoleLog(`FEATURE FLAGS:`);
    consoleLog(`◦ CONSOLE_LOG: ${isFlagEnabled(FeatureFlag.CONSOLE_LOGS)}`);
    consoleLog(`◦ VITU: ${isFlagEnabled(FeatureFlag.VITU)}`);

    this.appService.originalUrl = window.location.pathname;

    //this.startTokenTimer();

    this.auth0.appState$.subscribe(appState => {
      if (appState.returnTo && appState.returnTo.length > 1) {
        this.appService.returnTo = appState.returnTo.replace("(modal:submit-writeup)", "");
      }
    });


    combineLatest([this.auth0.isAuthenticated$]).pipe(
      map(([isAuthenticated]) => {
        if (!isAuthenticated) {
          console.log("User not authenticated in root (Auth0 login).");
          this.auth0.loginWithRedirect({
            appState: {
              returnTo: this.appService.originalUrl
            }
          });
        } else {
          console.log("User is authenticated in root.");
          combineLatest([this.auth0.getAccessTokenSilently()])
            .pipe(first())
            .subscribe(([token]) => {

              console.log("token", token);
              if (!this.authService.token) {
                console.log("User authenticated in root but no user token--fetching token.");
                this.authService.loginWithToken(token);
              }
            });
        }
      })).subscribe();

    this.setToken();
    this.subToCurrentUserAndVehicles();
    this.subToRouterEvents();
    this.pubsub.watchMsgChannel();
  }

  /*  private startTokenTimer() {
      this.timerSubscription = interval(this.CHECK_INTERVAL)
        .pipe(
          tap(() => {
            this.auth0.isAuthenticated$.subscribe(isAuthenticated => {
              if (!isAuthenticated) {
                // console.log('Token expired, initiating logout...');
                //this.authService.logout();
              } else {
                // console.log('Token still valid.');
              }
            });
          })
        )
        .subscribe();


      this.timerSubscription = interval(this.CHECK_INTERVAL)
        .pipe(
          tap(() => {
            this.auth0.getAccessTokenSilently().subscribe(token => {
              //console.log(token);
              if (!token) return 0;
              try {
                const tokenPayload = JSON.parse(atob(token.split('.')[ 1 ]));
                // console.log(tokenPayload);
                const expirationTime = tokenPayload.exp * 1000; // Convert to milliseconds
                const timeRemaining = Math.max(0, expirationTime - Date.now());
                // console.log(`Token expires in: ${this.formatTimeRemaining(timeRemaining)}`);
              } catch (e) {
                // console.error('Error parsing token:', e);
                return 0;
              }
            });
          })
        )
        .subscribe();
    }*/

  /*formatTimeRemaining(ms: number): string {
    const minutes = Math.floor(ms / 60000);
    const seconds = Math.floor((ms % 60000) / 1000);
    return `${minutes}m ${seconds}s`;
  }*/

  /**
   * Redirect to HTTPS if currently on HTTP
   */

  /*  private redirectToHttps(): void {
      if (
        this.document.location.protocol === 'http:' &&
        !this.document.location.hostname.includes('localhost')
      ) {
        const httpsUrl = new URL(this.document.location.href);
        httpsUrl.protocol = 'https:';

        // Preserve path and query parameters
        httpsUrl.pathname = this.document.location.pathname;
        httpsUrl.search = this.document.location.search;
        httpsUrl.hash = this.document.location.hash;

        // Perform the redirect
        this.document.location.href = httpsUrl.toString();
      }
    }*/

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // login with param token or try to reuse token on refresh
  setToken() {
    // subscribe to router event and extract token from params if exists
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.token) {
        this.authService.loginWithToken(params.token);
      } else {
        this.authService.reuseToken();
      }
    });
  }

  subToCurrentUserAndVehicles() {
    combineLatest([
      this.authService.selectUser(),
      this.vehicleService.selectVehicles()
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([user, vehicles]: [User, Vehicle[]]) => {
        this.currentUser = user;
        if ((!vehicles || !vehicles.length) && user) {
          this.vehicleService.dispatchGetAllVehicles();
        }

        if (user && !this.initialDataLoaded) {
          this.initialDataLoaded = true;
          this.appService.getAppSettingsData();
        }
      });
  }

  get isSalesManager(): boolean {
    if (this.currentUser) {
      const userRoles = User.unpackSecurityGroups(this.currentUser.securityGroups);
      if (userRoles.includes(USER_ROLES.sales_manager)) {
        return true;
      }
    }
  }

  subToRouterEvents() {
    this.router.events
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((event: RouterEvent) => {
        const navigationEnd = event instanceof NavigationEnd;
        const includesUrl = event.url !== undefined;
        if (navigationEnd && includesUrl) {
          this.currentRoute = event.url;
        }

        if (event instanceof NavigationEnd) {
          rg4js("trackEvent", {
            type: "pageView",
            path: event.url
          });
        }

        // Track navigation errors when the NavigationError event occurs
        if (event instanceof NavigationError) {
          // Track navigation error
          rg4js("send", {
            error: event.error
          });
        }
      });
  }
}
