import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { TradeIn } from "src/app/clearpath-module/models";
import { Lender } from "../../../models/data";
import { LENDERS } from "../../../data/lenders";

@Component({
  selector: "app-trade-info",
  templateUrl: "./trade-info.component.html",
  styleUrls: ["./trade-info.component.scss"]
})
export class TradeInfoComponent implements OnChanges {
  @Output() flagUnsavedChanges = new EventEmitter<boolean>();
  @Output() submitSalesManagerTradeIn = new EventEmitter<Partial<TradeIn>>();

  public forceChildFormUpdate: number = 0;
  public selectedLenderIndex: number;
  public customLienHolderSelected: boolean = false;

  public tradeInForm: UntypedFormGroup = this.formBuilder.group({
    color: [""],
    condition: [""],
    holding: [""],
    lienHolderAddress: [""],
    lienHolderCity: [""],
    lienHolderName: [""],
    lienHolderPhone: [""],
    lienHolderPostal: [""],
    lienHolderProvince: [""],
    make: [""],
    model: [""],
    trim: [""],
    bodyType: [""],
    vin: [""],
    license: [""],
    tradeEstimate: [0],
    odometer: [0],
    payOff: [0],
    payOffEstimate: [0],
    pbsVehicleRef: [""],
    stockNumber: [""],
    year: [0]
  });

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnChanges() { }

  get lenders(): Lender[] {
    return LENDERS;
  }

  selectLender(i: any): void {
    this.selectedLenderIndex = parseInt(i, 10);
    if (i >= 0) {
      this.customLienHolderSelected = false;
      const lender: Lender = this.lenders[ this.selectedLenderIndex ];
      if (this.tradeInForm.get("lienHolderName").value !== lender.name.replace(/\*/g, "")) {
        this.tradeInForm.patchValue({
          lienHolderName: lender.name.replace(/\*/g, ""),
        });
        this.forceChildFormUpdate = Math.random();
        this.tradeInForm.markAsDirty();
        this.autoSubmitForm();
      }
    }
  }

  clearLienHolder() {
    this.customLienHolderSelected = false;
    this.tradeInForm.patchValue({
      lienHolderName: ""
    });
    this.selectLender(-1);
  }

  enableCustomLienHolder() {
    this.customLienHolderSelected = true;
    this.tradeInForm.patchValue({
      lienHolderName: ""
    });
    this.forceChildFormUpdate = Math.random();
    this.tradeInForm.markAsDirty();
    this.autoSubmitForm();
  }

  @Input()
  set tradeIn(tradeIn: TradeIn) {
    if (!tradeIn) { return; }
    this.tradeInForm.patchValue({
      color: tradeIn.color,
      condition: tradeIn.condition,
      holding: tradeIn.holding,
      lienHolderAddress: tradeIn.lienHolderAddress,
      lienHolderCity: tradeIn.lienHolderCity,
      lienHolderName: tradeIn.lienHolderName,
      lienHolderPhone: tradeIn.lienHolderPhone,
      lienHolderPostal: tradeIn.lienHolderPostal,
      lienHolderProvince: tradeIn.lienHolderProvince,
      make: tradeIn.make,
      model: tradeIn.model,
      trim: tradeIn.trim,
      bodyType: tradeIn.bodyType,
      vin: tradeIn.vin,
      license: tradeIn.license,
      tradeEstimate: tradeIn.tradeEstimate,
      odometer: tradeIn.odometer,
      payOff: tradeIn.payOff || tradeIn.payOffEstimate,
      payOffEstimate: tradeIn.payOffEstimate || tradeIn.payOff,
      pbsVehicleRef: tradeIn.pbsVehicleRef,
      stockNumber: tradeIn.stockNumber,
      year: tradeIn.year,
    });

    this.selectedLenderIndex = this.lenders.findIndex(lender => {
      return lender.name.replace(/\*/g, "") === tradeIn.lienHolderName;
    });

    if (this.selectedLenderIndex >= 0) {
      this.selectLender(this.selectedLenderIndex);
    } else if (tradeIn.lienHolderName) {
      this.customLienHolderSelected = true;
    }

    this.forceChildFormUpdate = Math.random();
  }

  autoSubmitForm = () => {
    const {pristine, invalid} = this.tradeInForm;
    if (pristine || invalid) { return; }

    const tradeIn: Partial<TradeIn> = {
      color: this.tradeInForm.value.color,
      condition: this.tradeInForm.value.condition,
      holding: this.tradeInForm.value.holding,
      lienHolderAddress: this.tradeInForm.value.lienHolderAddress,
      lienHolderCity: this.tradeInForm.value.lienHolderCity,
      lienHolderName: this.tradeInForm.value.lienHolderName,
      lienHolderPhone: this.tradeInForm.value.lienHolderPhone,
      lienHolderPostal: this.tradeInForm.value.lienHolderPostal,
      lienHolderProvince: this.tradeInForm.value.lienHolderProvince,
      make: this.tradeInForm.value.make,
      model: this.tradeInForm.value.model,
      trim: this.tradeInForm.value.trim,
      bodyType: this.tradeInForm.value.bodyType,
      vin: this.tradeInForm.value.vin,
      license: this.tradeInForm.value.license,
      tradeEstimate: this.tradeInForm.value.tradeEstimate,
      odometer: this.tradeInForm.value.odometer,
      payOff: this.tradeInForm.value.payOff || this.tradeInForm.value.payOffEstimate,
      payOffEstimate: this.tradeInForm.value.payOffEstimate || this.tradeInForm.value.payOff,
      pbsVehicleRef: this.tradeInForm.value.pbsVehicleRef,
      stockNumber: this.tradeInForm.value.stockNumber,
      year: this.tradeInForm.value.year
    };

    tradeIn.tradeEstimate = +tradeIn.tradeEstimate;
    tradeIn.payOff = +tradeIn.payOff;
    tradeIn.payOffEstimate = tradeIn.payOff;
    tradeIn.year = +tradeIn.year;
    tradeIn.odometer = +tradeIn.odometer;
    tradeIn.tradeValue = tradeIn.tradeEstimate - tradeIn.payOff;

    this.submitSalesManagerTradeIn.emit(tradeIn);
    this.tradeInForm.markAsUntouched();
    this.tradeInForm.markAsPristine();
    this.flagChange();
  };

  flagChange = () => {
    this.flagUnsavedChanges.emit(true);
  };

}
