import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "app-trade-in-owner-form",
  templateUrl: "./trade-in-owner-form.component.html",
  styleUrls: ["./trade-in-owner-form.component.scss"]
})
export class TradeInOwnerFormComponent {
  @Input() formLabel = "Trade In Owner";
  @Input() formGroup: UntypedFormGroup;
  @Input() showCopyButton = false;
  @Output() copyRequested = new EventEmitter();
}
