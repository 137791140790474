import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { VehicleIncentive } from "src/app/settings-module/models";
import { FinanceOptions, FinanceRate, FinancingSettings, LeaseOptions, Vehicle } from "../../models";
import { DealIncentive, FinanceOffer, LeaseOffer, LeaseOfferTerm } from "src/app/settings-module/models/incentives";
import { clone, pathOr } from "ramda";
import { DealIncentivesService, DealService, VehicleService } from "../../services";
import { HistoryService } from "../../services/history.service";

@Component({
  selector: "app-vehicle-incentive",
  templateUrl: "./vehicle-incentive.component.html",
  styleUrls: ["./vehicle-incentive.component.scss"]
})
export class VehicleIncentiveComponent implements OnInit, OnDestroy {

  @Input() vehicle: Vehicle;
  @Input() financingSettings: FinancingSettings;
  @Input() vehicleIncentive: VehicleIncentive;
  @Input() dealIncentive: DealIncentive;
  @Input() financeOptions: FinanceOptions;
  @Input() leaseOptions: LeaseOptions;
  @Output() updateDealIncentive = new EventEmitter<DealIncentive>();

  financeTierForm: UntypedFormGroup = this.formBuilder.group({
    selectedCreditTier: [0]
  });

  leaseTierForm: UntypedFormGroup = this.formBuilder.group({
    selectedCreditTier: [0]
  });

  cashOffersForm: UntypedFormGroup = this.formBuilder.group({
    collegeRebate: [false],
    bonusCash: [false],
    leaseCash: [false],
    financeCash: [false],
    militaryRebate: [false],
    otherRebate: [false],
    customerLoyalty500: [false],
    customerLoyalty1000: [false],
    uberRebate: [false],
  });

  selectedOffers = {
    customerCash: false,
    financeOffer: false,
    leaseOffer: false,
    militaryRebate: false,
    collegeRebate: false,
    bonusCash: false,
    leaseCash: false,
    financeCash: false,
    customerLoyalty500: false,
    customerLoyalty1000: false,
    uberRebate: false,
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private vehicleService: VehicleService,
    private dealService: DealService,
    private historyService: HistoryService,
  ) { }

  ngOnInit() {
    this.vehicleIncentive = clone(this.vehicleIncentive);
    this.initForms();
    this.subToFormChanges();
    if (this.dealIncentive) {
      // console.log("this.dealIncentive", this.dealIncentive)
      if (this.dealIncentive.leaseCash && !this.dealIncentive.leaseCashDisabled) {
        this.selectedOffers.leaseCash = true;
      }
      if (this.dealIncentive.financeCash && !this.dealIncentive.financeCashDisabled) {
        this.selectedOffers.financeCash = true;
      }
      if (this.dealIncentive.customerCash && !this.dealIncentive.customerCashDisabled) {
        this.selectedOffers.customerCash = true;
      }
      if (this.dealIncentive.bonusCash && !this.dealIncentive.bonusCashDisabled) {
        this.selectedOffers.bonusCash = true;
      }
      if (this.leaseOffer.leaseOfferTerms.length) {
        this.selectedOffers.leaseOffer = true;
      }

      this.selectedOffers.financeOffer = this.financeOptions.aprSubventionDisabled;

      /*if (
        (this.aprSubventionTiers || this.financeRates) &&
        this.selectedOffers.customerCash === false
      ) {
        this.selectedOffers.financeOffer = true;
      }*/
    }
  }

  ngOnDestroy() {
    /*if (!this.selectedOffers.financeOffer) {
      if (pathOr(false, ["dealIncentive", "financeOffer"], this)) {
        this.dealIncentive.financeOffer.aprSubventionTiers = [];
        this.dealIncentive.financeOffer.financeRates = [];
        this.dealService.dispatchSetFinanceOptions({incentiveSelected: false});
      } else {
        this.dealIncentive = {
          ...this.dealIncentive,
          financeOffer: {
            aprSubventionTiers: [],
            financeRates: []
          }
        };
      }
    }
    if (this.selectedOffers.financeOffer) {
      this.dealIncentive.customerCashDisabled = true;
      this.dealService.dispatchSetFinanceOptions({incentiveSelected: true});
    }
    this.updateDealIncentive.emit(this.dealIncentive);*/
    this.updateDealIncentive.emit(this.dealIncentive);
    this.vehicleService.getCurrentVehicle();
  }

  initForms() {
    const selectedCreditTier = this.financeOptions.selectedCreditTier < 4 ?
      this.financeOptions.selectedCreditTier : 0;
    this.financeTierForm.patchValue({selectedCreditTier});
    this.leaseTierForm.patchValue({selectedCreditTier});
    this.cashOffersForm.patchValue({
      leaseCash: this.dealIncentive && !!this.dealIncentive.leaseCash && !this.dealIncentive.leaseCashDisabled || false,
      financeCash: this.dealIncentive && !!this.dealIncentive.financeCash && !this.dealIncentive.financeCashDisabled || false,
      collegeRebate: this.dealIncentive && !!this.dealIncentive.collegeRebate || false,
      militaryRebate: this.dealIncentive && !!this.dealIncentive.militaryRebate || false,
      otherRebate: this.dealIncentive && !!this.dealIncentive.otherRebate || false,
      uberRebate: this.dealIncentive && !!this.dealIncentive.uberRebate || false,
      customerLoyalty500: this.dealIncentive && !!this.dealIncentive.customerLoyalty500 || false,
      customerLoyalty1000: this.dealIncentive && !!this.dealIncentive.customerLoyalty1000 || false,
      bonusCash: this.dealIncentive &&
        !this.dealIncentive.bonusCashDisabled &&
        !!this.dealIncentive.bonusCash || false
    });
  }

  private subToFormChanges() {
    this.cashOffersForm.controls.leaseCash.valueChanges.subscribe((_ => {
      this.onToggleIncentive("leaseCash", this.leaseCash);
    }));
    this.cashOffersForm.controls.financeCash.valueChanges.subscribe((_ => {
      this.onToggleIncentive("financeCash", this.financeCash);
    }));
    this.cashOffersForm.controls.militaryRebate.valueChanges.subscribe((_ => {
      this.onToggleIncentive("militaryRebate", this.militaryRebate);
    }));
    this.cashOffersForm.controls.collegeRebate.valueChanges.subscribe((_ => {
      this.onToggleIncentive("collegeRebate", this.collegeRebate);
    }));
    this.cashOffersForm.controls.otherRebate.valueChanges.subscribe((_ => {
      this.onToggleIncentive("otherRebate", this.otherRebate);
    }));
    this.cashOffersForm.controls.bonusCash.valueChanges.subscribe((_ => {
      this.onToggleIncentive("bonusCash", this.bonusCash);
    }));
    this.cashOffersForm.controls.uberRebate.valueChanges.subscribe((_ => {
      this.onToggleIncentive("uberRebate", this.uberRebate);
    }));
    this.cashOffersForm.controls.customerLoyalty500.valueChanges.subscribe((_ => {
      this.onToggleIncentive("customerLoyalty500", this.customerLoyalty500);
    }));
    this.cashOffersForm.controls.customerLoyalty1000.valueChanges.subscribe((_ => {
      this.onToggleIncentive("customerLoyalty1000", this.customerLoyalty1000);
    }));
  }


  historySelectionToggle(name, isSelected) {
    console.log("historySelectionToggle", name, !!isSelected);
    this.historyService.dispatchAddEvent({
      shortName: name,
      oldValue: !!isSelected ? "Unselected" : "Selected",
      newValue: !!isSelected ? "Selected" : "Unselected"
    });
  }


  // ACTIONS

  onToggleIncentive(type: string, val: any) {
    if (!this.dealIncentive) {
      this.dealIncentive = {
        incentiveGroupName: this.vehicleIncentive.incentiveGroupName,
        [ type ]: val
      };
    }
    if (type === "militaryRebate") {
      this.historySelectionToggle("Military Rebate", !this.dealIncentive.militaryRebate);
      if (this.dealIncentive.militaryRebate) {
        this.dealIncentive.militaryRebate = 0;
        this.selectedOffers.militaryRebate = false;
      } else {
        this.dealIncentive.militaryRebate = this.militaryRebate;
        this.selectedOffers.militaryRebate = true;
      }
      //console.log("militaryRebate", this.dealIncentive.militaryRebate, this.selectedOffers.militaryRebate);
    }
    if (type === "collegeRebate") {
      this.historySelectionToggle("College Rebate", !this.dealIncentive.collegeRebate);
      if (this.dealIncentive.collegeRebate) {
        this.dealIncentive.collegeRebate = 0;
        this.selectedOffers.collegeRebate = false;
      } else {
        this.dealIncentive.collegeRebate = this.collegeRebate;
        this.selectedOffers.collegeRebate = true;
      }
    }
    if (type === "uberRebate") {
      this.historySelectionToggle("Uber Rebate", !this.dealIncentive.uberRebate);
      if (this.dealIncentive.uberRebate) {
        this.dealIncentive.uberRebate = 0;
        this.selectedOffers.uberRebate = false;
      } else {
        this.dealIncentive.uberRebate = this.uberRebate;
        this.selectedOffers.uberRebate = true;
      }
    }
    if (type === "customerLoyalty500") {
      this.historySelectionToggle("Finance Loyalty Rebate", !this.dealIncentive.customerLoyalty500);
      if (this.dealIncentive.customerLoyalty500) {
        this.dealIncentive.customerLoyalty500 = 0;
        this.selectedOffers.customerLoyalty500 = false;
      } else {
        this.dealIncentive.customerLoyalty500 = this.customerLoyalty500;
        this.selectedOffers.customerLoyalty500 = true;
      }
    }
    if (type === "customerLoyalty1000") {
      this.historySelectionToggle("Lease Loyalty Rebate", !this.dealIncentive.customerLoyalty1000);
      if (this.dealIncentive.customerLoyalty1000) {
        this.dealIncentive.customerLoyalty1000 = 0;
        this.selectedOffers.customerLoyalty1000 = false;
      } else {
        this.dealIncentive.customerLoyalty1000 = this.customerLoyalty1000;
        this.selectedOffers.customerLoyalty1000 = true;
      }
    }
    if (type === "bonusCash") {
      this.historySelectionToggle("Bonus Cash Rebate", !this.dealIncentive.bonusCashDisabled);
      if (!this.dealIncentive.bonusCashDisabled) {
        this.dealIncentive.bonusCashDisabled = true;
        this.selectedOffers.bonusCash = false;
      } else {
        this.dealIncentive.bonusCash = val;
        this.dealIncentive.bonusCashDisabled = false;
        this.selectedOffers.bonusCash = true;
        this.selectedOffers.financeOffer = false;
      }
    }
    if (type === "financeCash") {
      this.historySelectionToggle("Finance Cash Rebate", !this.dealIncentive.financeCashDisabled);
      if (!this.dealIncentive.financeCashDisabled) {
        //console.log("financeCash toggled 1")
        this.dealIncentive.financeCashDisabled = true;
        this.selectedOffers.financeCash = false;
        this.updateDealIncentive.emit(this.dealIncentive);
      } else {
        //console.log("financeCash toggled 2")
        if (!this.dealIncentive.leaseCashDisabled) {
          this.cashOffersForm.patchValue({
            leaseCash: false,
          });
          this.dealIncentive.leaseCashDisabled = true;
          this.selectedOffers.leaseCash = false;
          this.updateDealIncentive.emit(this.dealIncentive);
        }

        // disable apr subvention
        this.selectedOffers.financeOffer = false;
        this.dealIncentive.financeOfferSelectedBuffer = false;
        this.dealIncentive.aprSubventionSelectedBuffer = false;
        this.financeOptions.aprSubventionDisabled = true;
        this.dealService.dispatchSetFinanceOptions({incentiveSelected: false, aprSubventionDisabled: true});

        // disable subvention cash
        this.updateSubventionCashDisabled(true);

        this.dealIncentive.financeCash = val;
        this.dealIncentive.financeCashDisabled = false;
        this.selectedOffers.financeCash = true;
        this.dealService.setFinancingTerm(48);
        this.dealIncentive.financeOfferSelectedBuffer = true;
        this.dealIncentive.leaseOfferSelectedBuffer = false;
        this.updateDealIncentive.emit(this.dealIncentive);
      }
    }
    if (type === "leaseCash") {
      //console.log("this.dealIncentive.leaseCashDisabled",this.dealIncentive.leaseCashDisabled)
      this.historySelectionToggle("Lease Cash Rebate", !this.dealIncentive.leaseCashDisabled);
      if (!this.dealIncentive.leaseCashDisabled) {
        //console.log("Lease Cash toggled 1")
        this.dealIncentive.leaseCashDisabled = true;
        this.selectedOffers.leaseCash = false;
        this.dealIncentive.leaseOfferSelectedBuffer = false;
        this.dealIncentive.leaseOfferTermBuffer = null;
        this.dealService.setLeasingTerm(null);
        this.updateDealIncentive.emit(this.dealIncentive);
      } else {
        //console.log("Lease Cash toggled 2")

        if (!this.dealIncentive.financeCashDisabled) {
          this.cashOffersForm.patchValue({
            financeCash: false,
          });
          this.dealIncentive.leaseCashDisabled = true;
          this.selectedOffers.leaseCash = false;
          this.updateDealIncentive.emit(this.dealIncentive);
        }

        // disable apr subvention
        this.selectedOffers.financeOffer = false;
        this.dealIncentive.financeOfferSelectedBuffer = false;
        this.dealIncentive.aprSubventionSelectedBuffer = false;
        this.financeOptions.aprSubventionDisabled = true;
        this.dealService.setFinancingTerm(null);
        this.dealService.dispatchSetFinanceOptions({incentiveSelected: false, aprSubventionDisabled: true});

        // disable subvention cash
        this.updateSubventionCashDisabled(true);

        this.dealIncentive.leaseCash = val;
        this.dealIncentive.leaseCashDisabled = false;
        this.selectedOffers.leaseCash = true;
        this.dealIncentive.leaseOfferSelectedBuffer = true;
        this.dealIncentive.leaseOfferTermBuffer = 36;
        this.dealService.setLeasingTerm(this.dealIncentive.leaseOfferTermBuffer);
        this.updateDealIncentive.emit(this.dealIncentive);
      }
    }
    if (type === "customerCash") {
      this.historySelectionToggle("Customer Cash Rebate", !this.dealIncentive.customerCashDisabled);
      if (!this.dealIncentive.customerCashDisabled) {
        this.dealIncentive.customerCashDisabled = true;
        this.selectedOffers.customerCash = false;
      } else {
        this.dealIncentive.customerCash = val;
        this.dealIncentive.customerCashDisabled = false;
        this.selectedOffers.customerCash = true;
        this.selectedOffers.financeOffer = false;
      }
    }
    if (type === "leaseOffer") {
      this.historySelectionToggle("Lease Subvention", this.selectedOffers.leaseOffer !== true);
      if (this.selectedOffers.leaseOffer === true) {
        this.selectedOffers.leaseOffer = false;
        this.dealIncentive.leaseOffer.leaseOfferTerms = [];
      } else {
        this.dealIncentive.leaseOffer.leaseOfferTerms = this.vehicleLeaseOfferTerms;
        this.selectedOffers.leaseOffer = true;
      }
    }
    if (type === "financeOffer") {
      this.historySelectionToggle("APR Subvention", this.selectedOffers.financeOffer !== true);
      if (this.selectedOffers.financeOffer === true) {
        this.selectedOffers.financeOffer = false;
        this.dealIncentive[ type ].financeRates = [];
      } else {
        this.dealIncentive[ type ] = this.financeOffer;
        this.dealIncentive.customerCashDisabled = true;
        this.selectedOffers.customerCash = false;
        this.selectedOffers.financeOffer = true;
      }
    }
  }

  // UI & RENDERING


  get showCash(): boolean {
    return !!this.vehicleIncentive.incentive.cashDetails.customerCash;
  }

  get showFinance(): boolean {
    return (this.financeRatesAreValid ||
        this.financeSubventionIsValid) &&
      this.vehicleIncentive.incentive.financeOffer != null;
  }

  get showLease(): boolean {
    return (
      this.vehicleIncentive.incentive.leaseOffer.leaseOfferTerms &&
      this.vehicleIncentive.incentive.leaseOffer.leaseOfferTerms.length > 0
    );
  }

  get leaseSubventionOffer() {
    const selectedLeaseTerm = this.leaseOptions.selectedLeaseTerm;
    const selectedLeaseOfferTermIndex = this.leaseOfferTerms
      .findIndex((leaseOfferTerm: LeaseOfferTerm) => leaseOfferTerm.term === selectedLeaseTerm);
    if (
      this.leaseOfferTerms[ selectedLeaseOfferTermIndex ] &&
      this.leaseOfferTerms[ selectedLeaseOfferTermIndex ].leaseSubvention
    ) {
      return this.leaseOfferTerms[ selectedLeaseOfferTermIndex ].leaseSubvention;
    }
  }

  get customerCash(): number {
    return this.vehicleIncentive.incentive.cashDetails.customerCash;
  }

  get militaryRebate(): number {
    return 500;
  }

  get vehicleLeaseOfferTerms() {
    return this.vehicleIncentive.incentive.leaseOffer.leaseOfferTerms;
  }

  get collegeRebate(): number {
    return 500;
  }

  get uberRebate(): number {
    return 750;
  }

  get customerLoyalty500(): number {
    return 500;
  }

  get customerLoyalty1000(): number {
    return 1000;
  }

  get bonusCash(): number {
    return this.vehicleIncentive.incentive.cashDetails.bonusCash;
  }

  get leaseCash(): number {
    return this.vehicleIncentive.incentive.cashDetails.leaseCash;
  }

  get financeCash(): number {
    return this.vehicleIncentive.incentive.cashDetails.financeCash;
  }

  get otherRebate(): number {
    return this.vehicleIncentive.incentive.cashDetails.otherRebate;
  }

  get financeOffer(): FinanceOffer {
    return this.vehicleIncentive.incentive.financeOffer;
  }

  get aprSubventionTiers(): number[] {
    return pathOr([], ["aprSubventionTiers"], this.financeOffer);
  }

  get leaseOffer(): LeaseOffer {
    return this.vehicleIncentive.incentive.leaseOffer;
  }

  get creditTiers(): string[] {
    return ["Tier 1+ (720+)", "Tier 1 (690 - 719)", "Tier 2 (670 - 689)", "Tier 3 (650 - 669)", "Tier 4 (630 - 649)"];
  }

  get selectedFinanceTier() {
    return this.financeTierForm.controls.selectedCreditTier.value;
  }

  get selectedLeaseTier() {
    return this.leaseTierForm.controls.selectedCreditTier.value;
  }

  get financeRates(): FinanceRate[] {
    return pathOr([], ["financeOffer", "financeRates"], this);
  }

  get leaseOfferTerms(): LeaseOfferTerm[] {
    return this.vehicleIncentive.incentive.leaseOffer.leaseOfferTerms || [];
  }

  get maxLeaseOfferMonths(): number {
    const sortedTerms = this.vehicleIncentive.incentive.leaseOffer.leaseOfferTerms.sort((a, b) => {
      return b.term - a.term;
    });
    return sortedTerms[ 0 ].term;
  }

  get financeRatesAreValid() {
    return this.financeRates.filter((rate: FinanceRate) => {
      const validTierRates = rate.tieredRates.filter((rate: number) => {
        return rate != null;
      });
      return validTierRates.length > 0;
    }).length > 0;
  }

  get financeSubventionIsValid() {
    return pathOr([], ["financeOffer", "aprSubventionTiers"], this).filter((tier: number) => {
      return tier && tier > 0;
    }).length > 0;
  }

  get financeRatesExistForTier(): boolean {
    // return this.financeRatesAreValid && this.selectedFinanceTier < 4;
    return this.selectedFinanceTier < 5;

  }

  get incentiveGroupName() {
    return this.vehicleIncentive.incentiveGroupName;
  }

  get dealerFinancingRequired() {
    return this.vehicleIncentive.incentive.dealerFinancingRequired;
  }

  get exclusions() {
    return this.vehicleIncentive.exclusions;
  }

  get leaseRatesExistForTier(): boolean {
    return this.selectedLeaseTier < 4;
  }

  getFinanceRate(rates) {
    const tier = this.selectedFinanceTier;
    //console.log(tier, rates)
    return rates[ tier ];
  }

  getTieredLeaseMF(leaseOfferTerm: LeaseOfferTerm) {
    return leaseOfferTerm.tieredLeaseMFs[ this.selectedLeaseTier ];
  }

  showLeaseSubventionTerm(leaseOfferTerm) {
    if (!this.financingSettings || !this.vehicle) return false;
    if (this.vehicle.isCertified) {
      return this.financingSettings.leaseDefault.certUsedTermsShown.includes(leaseOfferTerm);
    } else if (!this.vehicle.isCertified && !this.vehicle.isUsed) {
      return this.financingSettings.leaseDefault.newTermsShown.includes(leaseOfferTerm);
    }
    return false;
  }

  isLeaseOfferTermBuffer(leaseOfferTerm, selectedLeaseTerm, subventionCashDisabled) {
    //console.log("isLeaseOfferTermBuffer", leaseOfferTerm, selectedLeaseTerm, this.dealIncentive.leaseOfferSelectedBuffer, this.dealIncentive.leaseOfferTermBuffer)
    if (!subventionCashDisabled) {
      if (this.leaseOptions && selectedLeaseTerm) {
        return selectedLeaseTerm == leaseOfferTerm.term;
      }
      if (this.dealIncentive.leaseOfferSelectedBuffer && this.dealIncentive.leaseOfferTermBuffer === leaseOfferTerm.term)
        return true;
    }
    return false;
  }


  isAprSubventionBuffer(financeOffer) {
    return financeOffer;
  }


  onToggleAprSubvention(financeOffer) {
    if (financeOffer) {
      this.selectedOffers.financeOffer = false;
      this.dealIncentive.financeOfferSelectedBuffer = false;
      this.dealIncentive.aprSubventionSelectedBuffer = false;
      this.financeOptions.aprSubventionDisabled = true;
      this.updateDealIncentive.emit(this.dealIncentive);
      this.dealService.setFinancingTerm(null);
      this.dealService.dispatchSetFinanceOptions({incentiveSelected: false, aprSubventionDisabled: true});
    } else {
      this.updateSubventionCashDisabled(true);
      this.dealIncentive.leaseOfferSelectedBuffer = false;
      this.dealIncentive.leaseOfferTermBuffer = null;
      this.financeOptions.aprSubventionDisabled = false;
      this.dealService.setLeasingTerm(null);
      this.dealService.setFinancingTerm(48);
      this.selectedOffers.financeOffer = true;
      this.dealService.dispatchSetFinanceOptions({incentiveSelected: true, aprSubventionDisabled: false});
      this.dealIncentive.financeOfferSelectedBuffer = true;
      this.dealIncentive.aprSubventionSelectedBuffer = true;
      //this.onToggleIncentive('financeOffer', this.financeOffer)
      const vehicleIncentive = this.vehicle.incentives[ 0 ];
      this.dealIncentive.financeOffer = vehicleIncentive.incentive.financeOffer;
      this.updateDealIncentive.emit(this.dealIncentive);
    }
  }

  onToggleLeaseSubvention(leaseOfferTerm, subventionCashDisabled) {
    if (this.leaseOptions && this.leaseOptions.selectedLeaseTerm && !subventionCashDisabled) {
      //console.log("Disabling Subvention 1", subventionCashDisabled)
      //console.log("onToggleLeaseSubvention", this.leaseOptions, this.leaseOptions.selectedLeaseTerm, this.dealIncentive.leaseOfferTermBuffer, this.dealIncentive.leaseOfferSelectedBuffer, this.dealIncentive.leaseOfferTermBuffer)
      this.dealIncentive.leaseOfferSelectedBuffer = false;
      this.dealIncentive.leaseOfferTermBuffer = null;
      this.dealService.setLeasingTerm(null);
      this.updateDealIncentive.emit(this.dealIncentive);
      return;
    }
    if (!subventionCashDisabled && this.dealIncentive.leaseOfferSelectedBuffer && this.dealIncentive.leaseOfferTermBuffer === leaseOfferTerm.term) {
      //console.log("Disabling Subvention 2")
      this.dealIncentive.leaseOfferSelectedBuffer = false;
      this.dealIncentive.leaseOfferTermBuffer = null;
      this.dealService.setLeasingTerm(null);
    } else if (!subventionCashDisabled && this.dealIncentive.leaseOfferSelectedBuffer) {
      //console.log("Updating Subvention")
      this.updateSubventionCashDisabled(false);
      this.dealIncentive.leaseOfferTermBuffer = leaseOfferTerm.term;
    } else {
      //console.log("Enabling Subvention")
      this.updateSubventionCashDisabled(false);
      this.dealIncentive.leaseOfferSelectedBuffer = true;
      this.dealIncentive.leaseOfferTermBuffer = leaseOfferTerm.term;
      this.dealService.setLeasingTerm(this.dealIncentive.leaseOfferTermBuffer);
      this.updateDealIncentive.emit(this.dealIncentive);
      this.financeOptions.aprSubventionDisabled = true;
      this.dealService.dispatchSetFinanceOptions({incentiveSelected: false, aprSubventionDisabled: true});
    }
  }

  updateSubventionCashDisabled = (bool: boolean) => {
    // disable subvention cash
    this.leaseOptions.subventionCashDisabled = bool;
    this.dealService.dispatchSetLeaseOptions({subventionCashDisabled: this.leaseOptions.subventionCashDisabled});
    if (!bool) {
      if (!this.dealIncentive.leaseCashDisabled) {
        this.cashOffersForm.patchValue({
          leaseCash: false,
        });
        this.dealIncentive.leaseCashDisabled = true;
        this.selectedOffers.leaseCash = false;
        this.updateDealIncentive.emit(this.dealIncentive);
      }
      if (!this.dealIncentive.financeCashDisabled) {
        this.cashOffersForm.patchValue({
          financeCash: false,
        });
        this.selectedOffers.financeOffer = false;
        this.dealIncentive.financeOfferSelectedBuffer = false;
        this.dealIncentive.aprSubventionSelectedBuffer = false;
        this.dealService.setFinancingTerm(null);
        this.financeOptions.aprSubventionDisabled = true;
        this.dealService.dispatchSetFinanceOptions({incentiveSelected: false, aprSubventionDisabled: true});
        this.dealIncentive.financeCashDisabled = true;
        this.selectedOffers.financeCash = false;
        this.updateDealIncentive.emit(this.dealIncentive);
      }
    }
    this.dealService.dispatchSetLeaseOptions({
      subventionCashDisabled: this.leaseOptions.subventionCashDisabled
    });
  };
}
