<div *ngIf="currentUser" style="background: #fff;margin:0;box-sizing:border-box;padding: 0;min-height: 100vh">
  <app-navbar
    [currentRelease]="currentRelease"
    [currentUser]="currentUser"
    [currentRoute]="currentRoute">
  </app-navbar>
  <app-alert></app-alert>
  <router-outlet></router-outlet>
  <router-outlet name="modal"></router-outlet>
</div>
