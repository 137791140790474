import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services";
import { map, take } from "rxjs/operators";
import { combineLatest } from "rxjs";
import { AlertService } from "src/app/shared-module/services";
import { User } from "../../../user-admin-module/models";
import { USER_ROLES } from "../../../app.config";
import { Router } from "@angular/router";
import { AppService } from "../../../clearpath-module/services";

@Component({
  templateUrl: "login.component.html",
  styleUrls: ["./login.component.scss"]
})

export class LoginComponent implements OnInit {
  loading = false;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private alertService: AlertService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.alertService.clear();

    combineLatest([this.authService.selectUser()]).pipe(
      take(1),
      map(([user]) => {
        if (user) {
          if (this.appService.returnTo && this.appService.returnTo.length > 1) {
            this.router.navigate([this.appService.returnTo]);
          } else if (User.unpackSecurityGroups(user.securityGroups).includes(USER_ROLES.sys_admin)) {
            this.router.navigate(["/settings"]);
          } else if (User.unpackSecurityGroups(user.securityGroups).includes(USER_ROLES.sales_manager)) {
            this.router.navigate(["/sales-manager/list"]);
          } else {
            this.router.navigate(["/clearpath/list"]);
          }
        }
      })
    ).subscribe();
  }
}
