<div mat-dialog-title>
  <h1 style="display: flex;align-items: center;margin-top:-4px;">
    <mat-icon style="margin-left:-8px;margin-top:4px;margin-right:-2px;">bolt</mat-icon>
    {{ title }}
  </h1>
</div>
<div mat-dialog-content>
  <div
    style="display: flex;flex-direction: column;gap:10px;align-items: stretch;justify-content: stretch;cursor: default">
    <div style="display: flex;align-items: center">
      <span style="min-width:200px;">Original Payment:</span>
      <div style="width:100%"></div>
      $<input
      value="{{ (data.originalPrice | currency)?.replace('$','') }}"
      style="max-width:150px;margin-left:3px;padding:0 14px 0 0;text-align: right;"
      disabled></div>
    <div style="display: flex;align-items: center;font-weight: bold;color:#195f30">
      <span style="min-width:200px;">Override Payment:</span>
      <div style="width:100%"></div>
      $<input
      [(ngModel)]="customPrice"
      (keyup.enter)="updateCustomPrice();"
      (blur)="formatAmount()"
      step="0.01"
      min="0" type="number"
      style="font-weight:bold;max-width:150px;margin-left:3px;padding:0;text-align: right;"></div>
  </div>
</div>
<div mat-dialog-actions style="display: flex;justify-content: center;gap:24px">
  <button
    mat-button style="flex-grow:2;margin:0;background: #7a4e4e;color:#fff;"
    (click)="removeCustomPrice()">Clear
    Override
  </button>
  <button
    mat-button
    type="submit"
    style="flex-grow:2;margin:0"
    (click)="updateCustomPrice()"
    [disabled]="!customPrice || !isValidNumber(customPrice)">
    Update Override
  </button>
</div>
