<div
  class="cash app-text-light px-1 app-text-bold my-1 py-1 d-flex justify-content-between primary-section-header-block align-items-center">
  <app-deal-type-selector (submitDealType)="submitDealType.emit($event)"></app-deal-type-selector>
  <span class="app-text-secondary app-text-white" style="font-size:20px;width:100%;text-align: right">
    {{ deal.financeOptionsEdits.amountFinanced !== null ? (deal.financeOptionsEdits.amountFinanced | currency) : (totalVehicleCashPrice$ | async | currency) }}
  </span>
  <div (click)="updateMonthlyPriceOverride()"
       style="margin-top:3px;margin-right:-12px;margin-left:-3px;padding:0 4px;cursor: pointer"
       matTooltipPosition="above" matTooltip="Override Lease Monthly Payment">
    <mat-icon [style.color]="deal.financeOptionsEdits.amountFinanced !== null ? '#fff200' : '#ffffff'">bolt</mat-icon>
  </div>

</div>

<app-lender-select
  [lender]="lender"
  [lenderName]="lender.name"
  [flagChange]="flagChange">
</app-lender-select>

<!--<app-smw-rebates-form
  [dealIncentives]="dealIncentives"
  [vehicleIncentives]="vehicleIncentives"
  dealType="cash"
  (updateIncentives)="onUpdateIncentives($event)">
</app-smw-rebates-form>-->

<div class="section-header">Products</div>

<app-smw-insurance-products-form
  [dealDefaults]="dealDefaults"
  (flagUnsavedChanges)="flagChange()"
  type="cash">
</app-smw-insurance-products-form>

<!-- <app-simple-input [fieldValue]="totalInsuranceProductsPrice$ | async | currency"
                  [name]="'Total Protection Adds'"
                  [type]="'number'">
</app-simple-input> -->

<app-simple-input
  [fieldValue]="tradeInNetValue$ | async"
  name="Trade Net"
  type="number"
  pipe="currency">
</app-simple-input>

<app-simple-input
  [fieldValue]="
  deal.financeOptionsEdits.amountFinanced !== null
  ? (deal.financeOptionsEdits.amountFinanced)
  : (totalVehicleCashPrice$ | async)"
  name="Total Amount Due"
  type="number"
  pipe="currency"
  isBold="true">
</app-simple-input>
