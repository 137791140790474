import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { MenuItem } from "src/app/clearpath-module/models";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FORM_INPUT_REQS } from "src/app/app.config";

@Component({
  selector: "app-menu-link-form",
  templateUrl: "./menu-link-form.component.html",
  styleUrls: ["./menu-link-form.component.scss"]
})
export class MenuLinkFormComponent implements OnInit, OnDestroy {

  public REQS = FORM_INPUT_REQS;
  private unsubscribe$ = new Subject();
  @Input() menuItem: MenuItem;
  @Input() title: string;
  @Input() hideButtons: boolean;
  @Output() formChanges = new EventEmitter<MenuItem>();
  menuItemForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.initForm();
    if (this.hideButtons) {
      this.watchForm();
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initForm() {
    this.menuItemForm = this.formBuilder.group({
      iconUrl: [this.menuItem.iconUrl, Validators.required],
      linkUrl: [this.menuItem.linkUrl, Validators.required],
      description: [this.menuItem.description, Validators.required]
    });
  }

  watchForm() {
    this.menuItemForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(menuItemForm => {
        this.formChanges.emit(menuItemForm);
      });
  }


  reset() {
    this.menuItemForm.setValue(this.menuItem);
  }

  save() {
    this.formChanges.emit(this.menuItemForm.value);
  }

  deleteItem() {
    this.formChanges.emit(null);
  }

}
