<body id="spacing">
<div
  *ngIf="loading"
  class="d-flex app-text-success justify-content-center align-items-center my-5"
>
  <span class="spinner-border spinner-border-sm mr-1"></span>
  Loading Deals...
</div>

<section *ngIf="!loading" class="data-table-container">
  <table class="data-table mx-auto w-100">
    <tr style="background: #eee;">

      <th *ngIf="isSalesManager" class="selectable" style="width: 70px;pointer-events: none">
        <div class="d-flex align-items-center">Activity</div>
      </th>

      <th
        class="selectable"
        style="width: auto"
        [ngClass]="{ selected: isActiveSort('customer') }"
        (mousedown)="onSelectSort('customer')"
      >
        <div class="d-flex align-items-center">
          Customer
          <i *ngIf="isActiveSort('customer')" class="material-icons">
            {{ ascending ? "arrow_drop_down" : "arrow_drop_up" }}
          </i>
        </div>
      </th>

      <th
        class="selectable"
        style="width: 75px"
        [ngClass]="{ selected: isActiveSort('stockNumber') }"
        (mousedown)="onSelectSort('stockNumber')"
      >
        <div class="d-flex align-items-center">
          Stock
          <i *ngIf="isActiveSort('stockNumber')" class="material-icons">
            {{ ascending ? "arrow_drop_down" : "arrow_drop_up" }}
          </i>
        </div>
      </th>

      <th
        class="selectable"
        [ngClass]="{ selected: isActiveSort('vehicle') }"
        (mousedown)="onSelectSort('vehicle')"
      >
        <div class="d-flex align-items-center">
          Vehicle
          <i *ngIf="isActiveSort('vehicle')" class="material-icons">
            {{ ascending ? "arrow_drop_down" : "arrow_drop_up" }}
          </i>
        </div>
      </th>

      <th
        *ngIf="isSalesManager"
        class="selectable"
        style="width: 75px"
        [ngClass]="{ selected: isActiveSort('state') }"
        (mousedown)="onSelectSort('state')"
      >
        <div class="d-flex align-items-center">
          State
          <i *ngIf="isActiveSort('state')" class="material-icons">
            {{ ascending ? "arrow_drop_down" : "arrow_drop_up" }}
          </i>
        </div>
      </th>

      <th
        class="selectable"
        style="width: 175px"
        [ngClass]="{ selected: isActiveSort('salesPerson') }"
        (mousedown)="onSelectSort('salesPerson')"
      >
        <div class="d-flex align-items-center">
          Sales Person
          <i *ngIf="isActiveSort('salesPerson')" class="material-icons">
            {{ ascending ? "arrow_drop_down" : "arrow_drop_up" }}
          </i>
        </div>
      </th>

      <th
        class="selectable"
        style="width: 150px"
        [ngClass]="{ selected: isActiveSort('salesManager') }"
        (mousedown)="onSelectSort('salesManager')"
      >
        <div class="d-flex align-items-center">
          Sales Manager
          <i *ngIf="isActiveSort('salesManager')" class="material-icons">
            {{ ascending ? "arrow_drop_down" : "arrow_drop_up" }}
          </i>
        </div>
      </th>

      <th
        class="selectable"
        [ngClass]="{ selected: isActiveSort('dealType') }"
        (click)="onSelectSort('dealType')"
        style="width: 90px"
      >
        <div class="d-flex align-items-center">
          Type
          <i *ngIf="isActiveSort('dealType')" class="material-icons">
            {{ ascending ? "arrow_drop_down" : "arrow_drop_up" }}
          </i>
        </div>
      </th>

      <th
        class="selectable"
        style="width: 100px"
        [ngClass]="{ selected: isActiveSort('status') }"
        (mousedown)="onSelectSort('status')"
      >
        <div class="d-flex align-items-center">
          Status
          <i *ngIf="isActiveSort('status')" class="material-icons">
            {{ ascending ? "arrow_drop_down" : "arrow_drop_up" }}
          </i>
        </div>
      </th>

      <th
        class="selectable"
        [ngClass]="{ selected: isActiveSort('statusDate') }"
        (mousedown)="onSelectSort('statusDate')"
        style="width: 110px"
      >
        <div class="d-flex align-items-center">
          Status Date
          <i *ngIf="isActiveSort('statusDate')" class="material-icons">
            {{ ascending ? "arrow_drop_down" : "arrow_drop_up" }}
          </i>
        </div>
      </th>
    </tr>

    <ng-container *ngIf="salesManagerDeals.length">
      <tr
        *ngFor="
            let deal of salesManagerDeals
              | dealFilter: searchText
              | teamFilter: selectedManagerId
              | dealSort: sortParam:ascending;
            let i = index
          "
        class="selectable"
        [ngClass]="{ 'app-bg-lt-blue': hasAlert(deal) }"
        (mousedown)="onSelectDeal(deal)"
      >
        <td *ngIf="isSalesManager" (mousedown)="onSelectDealHistory(deal); $event.stopPropagation()">
          <app-activity></app-activity>
        </td>
        <td class="app-text-success" style="width: 150px">
          {{ deal.customer.firstName }} {{ deal.customer.lastName }}
        </td>
        <td>{{ deal.stockNumber }}</td>
        <td>{{ deal.vehicle | vehicleName }}</td>
        <td *ngIf="isSalesManager">{{ deal.customer.state?.toUpperCase() || "--" }}</td>
        <td>{{ deal.salesId ? deal.salesPerson : "--" }}</td>
        <td>{{ this.getSalesManagerName(deal) }}</td>
        <td>{{ deal.dealType?.toUpperCase() || '--'}}</td>
        <td>{{ deal.status?.toUpperCase() }}</td>
        <td>{{ deal.statusDate | date }}</td>
      </tr>
    </ng-container>

    <ng-container *ngIf="otherDeals.length">
      <tr
        *ngFor="
            let deal of otherDeals
              | dealFilter: searchText
              | teamFilter: selectedManagerId
              | dealSort: sortParam:ascending;
            let i = index
          "
        class="selectable"
        (mousedown)="onSelectDeal(deal)"
        [ngClass]="{ 'app-bg-lt-blue': hasAlert(deal) }"
      >
        <td *ngIf="isSalesManager" (mousedown)="onSelectDealHistory(deal); $event.stopPropagation()">
          <app-activity></app-activity>
        </td>
        <td class="app-text-success">
          {{ deal.customer.firstName }} {{ deal.customer.lastName }}
        </td>
        <td>{{ deal.stockNumber }}</td>
        <td>{{ deal.vehicle | vehicleName }}</td>
        <td *ngIf="isSalesManager">{{ deal.customer.state?.toUpperCase() || "--" }}</td>
        <td>{{ deal.salesId ? toTitleCase(deal.salesPerson) : "--" }}</td>
        <td>{{ toTitleCase(this.getSalesManagerName(deal)) }}</td>
        <td>{{ deal.dealType?.toUpperCase() || '--'}}</td>
        <td>{{ deal.status?.toUpperCase() }}</td>
        <td>{{ deal.statusDate | date }}</td>
      </tr>
    </ng-container>

    <ng-container *ngIf="deals.length && !otherDeals.length">
      <tr
        *ngFor="
            let deal of dealList
              | dealFilter: searchText
              | teamFilter: selectedManagerId
              | dealSort: sortParam:ascending;
            let i = index
          "
        class="selectable"
        [ngClass]="{ 'app-bg-lt-blue': hasAlert(deal) }"
        (mousedown)="onSelectDeal(deal)"
      >
        <td *ngIf="isSalesManager" (mousedown)="onSelectDealHistory(deal); $event.stopPropagation()">
          <app-activity></app-activity>
        </td>
        <td class="app-text-success">
          {{ deal.customer.firstName }} {{ deal.customer.lastName }}
        </td>
        <td>{{ deal.stockNumber }}</td>
        <td>{{ deal.vehicle | vehicleName }}</td>
        <td *ngIf="isSalesManager">{{ deal.customer.state?.toUpperCase() || "--" }}</td>
        <td>{{ deal.salesId ? toTitleCase(deal.salesPerson) : "--" }}</td>
        <td>{{ toTitleCase(this.getSalesManagerName(deal)) }}</td>
        <td>{{ deal.dealType?.toUpperCase() || '--'}}</td>
        <td>{{ deal.status?.toUpperCase() }}</td>
        <td>{{ deal.statusDate | date }}</td>
      </tr>
    </ng-container>
  </table>
</section>
</body>
