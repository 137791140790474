<form *ngIf="parentForm" [formGroup]="parentForm" class="mt-2">
  <div *ngIf="product" [formGroupName]="productKey" style="padding:1px">
    <div class="d-flex align-items-center">
      <input
        type="checkbox"
        class="check-box-btn mr-2"
        [ngClass]="checkBoxColor"
        (click)="onToggleAccept()"
        [disabled]="disabled"
        [checked]="this.parentForm.get(this.productKey).get('accept').value"/>
      <span class="color-grey-4" [ngClass]="{ 'app-text-danger': !disabled && unverified }">{{product.name}}</span>
    </div>
    <div class="app-inline-form-layout">
      <!-- product select -->
      <select
        class="border border-primary"
        formControlName="selectedTerm"
        [attr.disabled]="this.parentForm.get(this.productKey).get('accept').value ? null : 'disabled'"
        (change)="submitParentForm()">
        <option *ngFor="let termCost of product.termCosts | orderBy: 'term';let i = index" [ngValue]="i">
          {{ termCost.term }} Months / {{ isUnlimitedMiles(termCost.miles) ? "Unlimited" : (termCost.miles | number) }} Miles
        </option>
      </select>
      <!-- price -->
      <div *ngIf="showForm() || touchedInvalid()">
        <input
          #priceOverrideInput
          id="priceOverrideInput"
          class="input-underline input-edit-type"
          [formControlName]="'priceOverride'"
          [value]="productPrice"
          (keyup.enter)="$event.preventDefault();priceOverrideInput.blur()"
          (blur)="onSubmitPriceOverride()"/>
      </div>
      <a *ngIf="!showForm()" class="btn btn-link app-text-blue tight-padding" (click)="editValue()">{{ productPrice | currency }}</a>
    </div>
  </div>
  <div *ngIf="product.isFallbackTermCosts" style="color: #f66;font-weight:bold;font-size:11px;padding: 0 3px">Fallback term costs</div>
  <hr style="margin:5px 0 0 0">
</form>
