import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {combineLatest, Subject} from "rxjs";
import {DealState} from "../../../store/state";
import {Vehicle} from "../../../models";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {DealService, VehicleService} from "../../../services";
import {ActivatedRoute, Router} from "@angular/router";
import {take, takeUntil} from "rxjs/operators";
import {AuthService} from "../../../../auth-module/services";
import swal, {SweetAlertOptions} from "sweetalert2";
import {MatTableData} from "../../../../util/matTableData";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'app-report-bug-modal',
  templateUrl: './report-bug-modal.component.html',
  styleUrls: ['./report-bug-modal.component.scss']
})
export class ReportBugModalComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();
  public responseReceived = false;
  public responseData: any = {};
  public deal!: DealState;
  public vehicle!: Vehicle;

  @ViewChild(MatSort, {static: false}) sort: MatSort;
  public displayedColumns = ['name', 'size', 'lastModifiedDate', 'deleteSampleFile',];
  public tableData: MatTableData = new MatTableData(this.displayedColumns, 100);
  public currentFileCount = 0;
  public currentFileSize = 0;

  reportBugForm: FormGroup = this.formBuilder.group({
    reporterName: new FormControl(''),
    reporterEmail: new FormControl(''),
    description: new FormControl(''),
  });

  constructor(
    private dealService: DealService,
    private vehicleService: VehicleService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    combineLatest([
      this.activatedRoute.queryParams,
      this.dealService.selectDeal(),
      this.vehicleService.selectVehicle(),
      this.authService.selectUser()
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([params, deal, vehicle, currentUser]) => {
        this.deal = deal;
        this.vehicle = vehicle;
        this.reportBugForm.patchValue({
          reporterName: currentUser.firstName + " " + currentUser.lastName,
          reporterEmail: currentUser.email,
        });
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  submitBugReport() {
    this.dealService.reportBug(
      this.deal,
      this.vehicle,
      this.reportBugForm.value,
      this.tableData.allItems || []
    )
      .pipe(take(1))
      .subscribe(
        response => {
          console.log("Response from API:", response);
          swal.fire({
            title: "Bug Report Submitted",
            html: "Your bug report has been submitted successfully.  Thank you for taking the time to submit this report.  Our development team will be taking a look at your report and may reach out to you for additional information if necessary.",
            icon: "success",
            showConfirmButton: true,
            confirmButtonText: "OK",
          } as SweetAlertOptions).then(_ => {
            this.closeModal();
          });
          this.responseReceived = true;
          this.responseData = response;
        },
        error => {
          console.error("Error from API:", error);
        }
      );
  }

  closeModal() {
    this.router.navigate([{outlets: {modal: null}}]).then();
  }

  addFiles($event: any): void {
    if ($event && $event.target && $event.target.files) {
      let list = $event.target.files;
      const finalList = [];
      list = Array.from(list);
      list.forEach((file: any) => finalList.push(file));
      this.addFiles2(finalList, this.sort);
      this.changeDetectorRef.markForCheck();
    }
  }

  addFiles2(newFiles: any[], sort: MatSort = null, paginator: MatPaginator = null): void {
    if (newFiles && newFiles.length > 0) {
      const files: Array<any> = newFiles;
      const list = this.tableData.allItems || [];
      const existing = [];

      let existingFileCount = 0;
      let existingFileSize = 0;
      list.forEach(file => {
        existingFileCount++;
        existingFileSize += file.size;
        existing.push(file.name);
      });

      let newFileCount = 0;
      let newFileSize = 0;
      Array.from(files).forEach((file: any) => {
        list.push(file);
        newFileCount++;
        newFileSize += file.size;
      });
      this.currentFileCount = existingFileCount + newFileCount;
      this.currentFileSize = existingFileSize + newFileSize;
      this.tableData.updateItems(list, sort, paginator);
    }
  }

  getSizeFormatted(size: number = 0): string {
    if (size < 1024) {
      return Math.ceil(size * 100) / 100 + ' bytes';
    } else if (size < 1024 * 1024) {
      return Math.ceil(size / 1024 * 100) / 100 + ' KB';
    } else if (size < 1024 * 1024 * 1024) {
      return Math.ceil(size / (1024 * 1024) * 100) / 100 + ' MB';
    } else if (size < 1024 * 1024 * 1024 * 1024) {
      return Math.ceil(size / (1024 * 1024 * 1024) * 100) / 100 + ' GB';
    } else if (size < 1024 * 1024 * 1024 * 1024 * 1024) {
      return Math.ceil(size / 1024 * 1024 * 1024 * 1024 * 100) / 100 + ' TB';
    } else {
      return Math.ceil(size * 100) / 100 + ' bytes';
    }
  }

  public removeFile(item: any): void {
    this.tableData.allItems.splice(this.tableData.allItems.indexOf(item), 1);
    this.tableData.updateItems(this.tableData.allItems, this.sort);
  }
}
