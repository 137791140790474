import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { take } from "rxjs/operators";
import { CalculationService } from "../../../services/calculations/calculation.service";
import { DealService } from "../../../services/deal/deal.service";
import { VehicleService } from "../../../services/vehicle.service";
import { FinanceOptions, TradeIn } from "../../../models";

@Component({
  selector: "app-roll-payment-calculator",
  templateUrl: "./roll-payment-calculator.component.html",
  styleUrls: ["./roll-payment-calculator.component.scss"]
})
export class RollPaymentCalculatorComponent implements OnInit {
  @Output() formSubmitFlag = new EventEmitter();
  principal: number;
  interestRate$;
  interestRate: number;
  tradeEquity = 0;
  rollPaymentForm: UntypedFormGroup = this.formBuilder.group({
    desiredPayment: [400, Validators.required],
    downPayment: [0, Validators.required],
    numberOfMonthlyPayments: [60, Validators.required]
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private calculationService: CalculationService,
    private dealService: DealService,
    private vehicleService: VehicleService
  ) { }

  ngOnInit() {
    this.subToVehicle();
    this.subToFinanceOptions();
    this.subToTradeIn();
    const term = this.rollPaymentForm.value.numberOfMonthlyPayments;
    this.calculationService.findInterestRate$({term})
      .subscribe(interestRate => {
        this.interestRate = interestRate;
        this.calcDownPayment();
      });
  }

  // INITIALIZATION
  private subToVehicle() {
    this.vehicleService.selectVehicle()
      .pipe(take(1))
      .subscribe((vehicle: any) => {
        this.principal = vehicle.retail;
      });
  }

  private subToFinanceOptions() {
    this.dealService.selectFinanceOptions()
      .pipe(take(1))
      .subscribe((financeOptions: FinanceOptions) => {
        const {
          desiredPayment,
          numberOfMonthlyPayments
        } = financeOptions;
        if (desiredPayment) {
          this.rollPaymentForm.patchValue({desiredPayment});
        }
        if (numberOfMonthlyPayments) {
          this.rollPaymentForm.patchValue({numberOfMonthlyPayments});
        }
      });
  }

  private subToTradeIn() {
    this.dealService.selectTradeIn()
      .pipe(take(1))
      .subscribe((tradeIn: TradeIn) => {
        const {tradeEstimate, payOffEstimate} = tradeIn;
        this.tradeEquity = tradeEstimate - payOffEstimate;
      });
  }

  // ACTIONS
  calcDownPayment() {
    if (!this.rollPaymentForm.valid) { return; }
    const {desiredPayment, numberOfMonthlyPayments} = this.rollPaymentForm.value;
    const interest = this.calculationService.getSimpleInterest(
      this.principal,
      this.interestRate,
      numberOfMonthlyPayments
    );
    const downPayment = this.calculationService.getDownPayment(
      interest,
      this.principal,
      desiredPayment,
      numberOfMonthlyPayments,
      this.tradeEquity
    );
    this.rollPaymentForm.patchValue({downPayment});
    this.rollPaymentForm.markAsDirty();
  }

  onSubmitRollPaymentForm() {
    const {desiredPayment, downPayment, numberOfMonthlyPayments} = this.rollPaymentForm.value;
    this.dealService.submitRollPaymentForm(
      desiredPayment,
      this.calculationService.calculationUtilityService.truncateDecimal(downPayment, 0),
      numberOfMonthlyPayments
    );
    this.formSubmitFlag.emit();
  }

  // FORM VALIDATION & HELPERS
  touchedInvalid(controlName: string): boolean {
    const touched = this.rollPaymentForm.controls[ controlName ].touched;
    const valid = this.rollPaymentForm.controls[ controlName ].valid;
    return touched && !valid;
  }
}
