import { AfterViewInit, Directive, ElementRef, OnDestroy } from "@angular/core";
import { Actions, ofType } from "@ngrx/effects";
import * as authActions from "../store/actions/auth.actions";
import * as appActions from "../../clearpath-module/store/actions/app.actions";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AuthService } from "../services";

@Directive({
  selector: "[appFlutterLogout]"
})
export class FlutterLogoutDirective implements AfterViewInit, OnDestroy {

  messageSent: boolean;
  private unsubscribe$ = new Subject();

  constructor(
    private elementRef: ElementRef,
    private authService: AuthService,
    private actions$: Actions
  ) { }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit() {

    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(appActions.flutterMessage),
    ).subscribe(({msg}) => {
      this.postMessage(msg);
    });

    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(authActions.login),
    ).subscribe(() => {
      this.messageSent = false;
    });

    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(authActions.logout),
    ).subscribe(() => {
      if (!this.messageSent) {
        this.postMessage("__LOGGED_OUT__");
        this.messageSent = true;
        this.authService.token = "";
      }
    });

  }

  postMessage(message) {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.innerHTML = `if(typeof Print !== 'undefined') {Print.postMessage('${message}');}`;
    this.elementRef.nativeElement.appendChild(s);
  }

}
