<section class="header-layout">
  <h1 class="h2 header-title">
    Tire &amp; Wheel
  </h1>

  <form [formGroup]="twpForm"
        class="header-form">
    <select class="header-form-control form-control"
            formControlName="termCostIndex">
      <option value=""
              disabled>Select A Plan
      </option>
      <option *ngFor="let option of termCosts; let i = index"
              [value]="i">
        {{ option.term / 12}} Years / Unlimited Miles - {{ option.price | currency:'USD':'symbol':'1.0-0' }}
      </option>
    </select>
  </form>
</section>

<ul class="nav nav-pills mb-3">
  <li *ngFor="let tab of viewTabs"
      class="nav-item">
    <a [routerLink]="[]"
       class="nav-link"
       [ngClass]="{ 'active': activeView(tab.id) }"
       (click)="onSelectView(tab.id)">
      {{ tab.title }}
    </a>
  </li>
</ul>

<section *ngIf="activeView('overview')"
         class="text-center">
  <img src="/static/images/insurance/tiredamage.jpg"
       class="w-100 p-0">
</section>

<section *ngIf="activeView('damages')">
  <div class="coverage-layout card card-body app-bg-dark app-text-white font-weight-bold small mb-3">
    <span>Customer Costs</span>
    <span class="text-right">No Plan</span>
    <span class="text-right">T&amp;W</span>
  </div>
  <div *ngFor="let item of coverage"
       class="coverage-layout card card-body mb-3">
    <span><strong>{{ item.benefit }}</strong> {{ item.condition }}</span>
    <span class="text-right">${{ item.value }}</span>
    <span class="text-right">$0</span>
  </div>
</section>

<p class="text-center">Use the protection as you need it. No claim limits.</p>

<div class="btn-layout">
  <button class="btn"
          [ngClass]="planSelected() ? 'app-btn-secondary' : 'app-btn-outline-secondary'"
          type="button"
          (click)="onAcceptPlan()">
    Yes: Include Coverage
  </button>
  <button class="btn"
          [ngClass]="noPlanSelected() ? 'app-btn-dark' : 'app-btn-outline-dark'"
          type="button"
          (click)="onDeclinePlan()">
    Decline
  </button>
</div>
