import { Component, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { CREDIT_TIERS } from "src/app/app.config";
import { CreditTier } from "src/app/clearpath-module/models";

@Component({
  selector: "app-credit-tier-select",
  templateUrl: "./credit-tier-select.component.html",
  styleUrls: ["./credit-tier-select.component.scss"]
})
export class CreditTierSelectComponent {
  @Input() parentForm: UntypedFormGroup;
  @Input() formCtrlName: string;
  @Input() change;

  constructor() { }

  get creditTiers(): CreditTier[] {
    return CREDIT_TIERS;
  }

}
