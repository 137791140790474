import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {DealService} from "../../services";
import {InsuranceProduct, TermCost} from "../../models";
import {HistoryService} from "../../services/history.service";

@Component({
  selector: "app-insurance-item-ppm",
  templateUrl: "./insurance-item-ppm.component.html",
  styleUrls: ["./insurance-item-ppm.component.scss"]
})
export class InsuranceItemPpmComponent implements OnInit {
  @Input() product: InsuranceProduct;
  @Input() selectedTermIndex = null;
  @Output() closeModal = new EventEmitter();

  private uiState = {
    activeView: "included",
    tabs: [
      {title: "What's Included", id: "included"},
      {title: "Maintenance Schedule", id: "schedule"},
    ]
  };

  ppmForm: UntypedFormGroup = this.formBuilder.group({
    termCostIndex: [0]
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dealService: DealService,
    private historyService: HistoryService,
  ) {
  }

  ngOnInit() {
    this.syncSelectedPlan();
    this.ppmForm.valueChanges.subscribe(({termCostIndex}) => {
      this.dealService.dealInsuranceService.submitInsuranceProduct(this.product, +termCostIndex);
    });
  }

  // INITIALIZATION

  syncSelectedPlan() {
    const termCostIndex = this.selectedTermIndex || 0;
    this.ppmForm.patchValue({termCostIndex});
  }

  // ACTIONS

  onAcceptPlan() {
    const {termCostIndex} = this.ppmForm.value;
    this.historyService.dispatchAddEvent({
      shortName: "PPM Plan Accepted",
    })
    this.dealService.dealInsuranceService.submitInsuranceProduct(this.product, +parseInt(termCostIndex, 10));
    this.closeModal.emit();
  }

  onDeclinePlan() {
    this.historyService.dispatchAddEvent({
      shortName: "PPM Plan Declined",
    })
    this.dealService.dealInsuranceService.removeInsuranceProduct(this.product);
    this.closeModal.emit();
  }

  // UI CONTROL & RENDERING

  get termCosts(): TermCost[] {
    const terms = this.product ? this.product.termCosts : false;
    return terms || [];
  }

  planSelected(): boolean {
    const {termCostIndex} = this.ppmForm.value;
    return this.selectedTermIndex === +termCostIndex;
  }

  noPlanSelected(): boolean {
    return this.selectedTermIndex === null;
  }

  get viewTabs() {
    return this.uiState.tabs;
  }

  activeView(type: string): boolean {
    return this.uiState.activeView === type;
  }

  onSelectView(type: string) {
    this.uiState.activeView = type;
    let eventName: string = "";
    switch (type) {
      case "included":
        eventName = "What's Included Tab Selected";
        break;
      case "schedule":
        eventName = "Maintenance Schedule Tab Selected";
        break;
    }
    if (eventName) {
      this.historyService.dispatchAddEvent({
        shortName: eventName,
      });
    }
  }
}
