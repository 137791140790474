<form [formGroup]="tradeInForm">

  <section class="trade-in-layout mb-5">
    <div>
      <label [ngClass]="{ 'app-text-danger': touchedInvalid('year') }">
        Year
      </label>
      <input class="form-control"
             formControlName="year"
             type="number"
             min="1000"
             max="9999"
             (blur)="autoSubmitForm()"
             (change)="flagChange()"
             (keydown)="flagChange()">
    </div>

    <div>
      <label>Make</label>
      <input class="form-control"
             formControlName="make"
             type="text"
             [maxlength]="REQS.maxLength.standard"
             (blur)="autoSubmitForm()"
             (keydown)="flagChange()">
    </div>

    <div>
      <label>Model</label>
      <input class="form-control"
             formControlName="model"
             type="text"
             [maxlength]="REQS.maxLength.standard"
             (blur)="autoSubmitForm()"
             (keydown)="flagChange()">
    </div>

    <div class="grid-item-span-2">
      <label>Trim</label>
      <input class="form-control"
             formControlName="trim"
             type="text"
             [maxlength]="REQS.maxLength.standard"
             (blur)="autoSubmitForm()"
             (keydown)="flagChange()">
    </div>

    <div>
      <label>Color</label>
      <input class="form-control"
             formControlName="color"
             type="text"
             [maxlength]="REQS.maxLength.standard"
             (blur)="autoSubmitForm()"
             (keydown)="flagChange()">
    </div>

    <div class="grid-item-span-2">
      <label>VIN</label>
      <input class="form-control"
             formControlName="vin"
             type="text"
             [maxlength]="REQS.maxLength.standard"
             (blur)="autoSubmitForm()"
             (keydown)="flagChange()">
    </div>

    <div>
      <label [ngClass]="{ 'app-text-danger': touchedInvalid('odometer') }">
        Mileage
      </label>
      <input class="form-control"
             formControlName="odometer"
             type="number"
             inputmode="numeric"
             min="0"
             (blur)="autoSubmitForm()"
             (change)="flagChange()"
             (keydown)="flagChange()">
    </div>

    <div>
      <label>Holding</label>
      <input class="form-control"
             formControlName="holding"
             type="text"
             [maxlength]="REQS.maxLength.standard"
             (blur)="autoSubmitForm()"
             (keydown)="flagChange()">
    </div>

    <div>
      <label>Stock #</label>
      <input class="form-control"
             formControlName="stockNumber"
             type="text"
             inputmode="numeric"
             [maxlength]="REQS.maxLength.standard"
             (blur)="autoSubmitForm()"
             (keydown)="flagChange()">
    </div>
  </section>

  <section class="trade-in-layout">
    <div>
      <label [ngClass]="{ 'app-text-danger': touchedInvalid('tradeEstimate') }">
        Trade Offer
      </label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">$</span>
        </div>
        <input class="form-control text-right"
               formControlName="tradeEstimate"
               type="number"
               min="0"
               (blur)="autoSubmitForm()"
               (change)="flagChange()"
               (keydown)="flagChange()">
        <!-- <div class="input-group-append">
          <div class="input-group-text">
            <input type="checkbox">
          </div>
        </div> -->
      </div>
    </div>

    <div>
      <label [ngClass]="{ 'app-text-danger': touchedInvalid('payOff') }">
        Pay Off
      </label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">$</span>
        </div>
        <input class="form-control text-right"
               formControlName="payOff"
               type="number"
               min="0"
               (blur)="autoSubmitForm()"
               (change)="flagChange()"
               (keydown)="flagChange()">
        <!-- <div class="input-group-append">
          <div class="input-group-text">
            <input type="checkbox">
          </div>
        </div> -->
      </div>
    </div>
  </section>

</form>

<div class="d-flex align-items-center justify-content-between">
  <div class="app-text-neutral mr-3">
    {{ tradeInForm.touched && tradeInForm.invalid ? 'Current form changes will not be saved.' : '' }}
  </div>
  <button class="btn app-btn-success rounded-pill"
          type="button"
          (click)="onClearForm()"
          [disabled]="completedDeal">
    Clear Form
  </button>
</div>