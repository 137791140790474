import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { combineLatest, Subject } from "rxjs";
import { CalculationService, DealService } from "../../services";

@Component({
  selector: "app-down-payment",
  templateUrl: "./down-payment.component.html",
  styleUrls: ["./down-payment.component.scss"]
})
export class DownPaymentComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();
  uiState = {
    showDetails: false,
    downPayment: 0,
  };

  downPaymentForm: UntypedFormGroup = this.formBuilder.group({
    downPayment: [0, [Validators.required, Validators.min(0)]],
  });

  constructor(
    private dealService: DealService,
    private formBuilder: UntypedFormBuilder,
    private calculationService: CalculationService
  ) { }

  ngOnInit() {
    this.subToFinanceOptionsData();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // INITIALIZATION

  private subToFinanceOptionsData() {
    combineLatest([
      this.dealService.selectDeal(),
      this.calculationService.customizedVehiclePrice$({excludeIncentives: true}),
      this.calculationService.tradeEquityEstimate$()
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([deal, customizedVehiclePrice, tradeEquity]) => {
        if (!deal.dealId) {
          return;
        }
        let {downPayment, defaultDownPayment, downPaymentZero} = deal.financeOptions;
        if (!downPaymentZero && (defaultDownPayment || customizedVehiclePrice)) {  // default down payment to 20% of customized veh price
          if (downPayment) {
            this.downPaymentForm.patchValue({downPayment});
            this.uiState.downPayment = downPayment;
            return;
          }
          if (!customizedVehiclePrice) {
            return;
          }
          downPayment = defaultDownPayment || customizedVehiclePrice / 5;
          downPayment = Math.round(downPayment);
          this.downPaymentForm.patchValue({downPayment});
          this.uiState.downPayment = downPayment;
          if (downPayment > 0) { this.autoSubmitDownPaymentForm(); }
        } else {
          this.downPaymentForm.patchValue({downPayment});
          this.uiState.downPayment = downPayment;
        }
      });
  }

  // UI CONTROL & RENDERING

  onToggleDetails() {
    this.uiState.showDetails = !this.uiState.showDetails;
  }

  get showDetails(): boolean {
    return this.uiState.showDetails;
  }

  get downPayment(): number {
    return this.uiState.downPayment;
  }

  // FORM VALIDATION & HELPERS

  autoSubmitDownPaymentForm() {
    if (this.downPaymentForm.value.downPayment === null) {
      this.downPaymentForm.patchValue({downPayment: 0});
    }
    if (this.downPaymentForm.valid) {
      const downPayment = this.downPaymentForm.value.downPayment;
      this.dealService.submitDownPayment(downPayment, this.uiState.downPayment === downPayment ? 0 : this.uiState.downPayment);
    }
  }

  touchedInvalid(controlName: string): boolean {
    const touched = this.downPaymentForm.controls[ controlName ].touched;
    const valid = this.downPaymentForm.controls[ controlName ].valid;
    return touched && !valid;
  }

}
