<div style="border-right:10px solid #343A40;">
  <ul class="list-unstyled my-0">
    <li>
    <span class="d-flex align-items-center">
      <i class="material-icons app-text-danger">fiber_manual_record</i>
      <span>Northstar Settings</span>
    </span>
    </li>
    <li>
    <span class="d-flex align-items-center">
      <i class="material-icons">fiber_manual_record</i>
      <span>Northstar Calculations</span>
    </span>
    </li>
    <li>
    <span class="d-flex align-items-center">
      <i class="material-icons app-text-blue">fiber_manual_record</i>
      <span>DMS</span>
    </span>
    </li>
  </ul>

  <hr class="m-0"/>

  <ul class="list-unstyled my-0 mx-1">
    <li class="app-text-bold">Vehicle Info</li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small app-text-blue">{{ isUsed ? 'Market Price' : 'MSRP' }}</span>
      <span class="app-text-blue">{{
          msrp | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small app-text-blue">Order Price</span>
      <span class="app-text-blue">{{
          app.vehicle.order.price | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small app-text-blue">MPG</span>
      <span class="app-text-blue">{{ app.vehicle.mpg }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small app-text-blue">Odometer</span>
      <span class="app-text-blue">{{ app.vehicle.odometer }}</span>
    </li>

    <hr class="m-0"/>

    <li class="app-text-bold">Incentives</li>

    <li
      *ngFor="let incentive of selectedCashIncentives$ | async"
      class="d-flex justify-content-between align-content-center"
    >
      <span class="app-text-small app-text-danger">{{ incentive.type }}</span>
      <span class="app-text-danger">{{ incentive.value }}</span>
    </li>

    <hr class="m-0"/>

    <li class="app-text-bold">Accessories</li>

    <li
      *ngFor="let accessory of selectedAccessories$ | async"
      class="d-flex justify-content-between align-content-center"
    >
      <span class="app-text-small app-text-danger">{{ accessory.name }}</span>
      <span class="app-text-danger">{{ accessory.price }}</span>
    </li>

    <hr class="m-0"/>

    <!-- CUSTOMIZED VEHICLE PRICE -->

    <li class="app-text-bold">Customized Vehicle Price</li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small app-text-blue">Retail Price</span>
      <span class="app-text-blue">{{
          retail | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Accessories</span>
      <span>{{
          accessoriesTotal$ | async | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Incentives Total</span>
      <span>{{
          incentivesTotal$ | async | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Customized Vehicle Price</span>
      <span>{{
          customizedVehiclePrice$ | async | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <hr class="m-0"/>

    <li class="app-text-bold">Trade</li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Value</span>
      <span>{{
          deal.tradeIn.tradeValue | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Payoff</span>
      <span>{{
          deal.tradeIn.payOffEstimate | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Trade Equity</span>
      <span>{{ tradeEquity$ | async | currency: "USD":"symbol":"1.2-2" }}</span>
    </li>

    <hr class="m-0"/>

    <!-- TAX BREAKDOWN -->

    <li class="app-text-bold">Tax</li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small app-text-danger">Privilege Tax Rate</span>
      <span>{{ privilegeTaxRate | percent: "2.3-5" }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Privilege Tax</span>
      <span>{{
          (calcTax$ | async)?.privilegeTax | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small app-text-danger">CAT Tax Rate</span>
      <span>{{ catTaxRate | percent: "2.3-5" }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">CAT Tax</span>
      <span>{{
          (calcTax$ | async)?.catTax | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small app-text-danger">WA Sales Tax Rate</span>
      <span>{{ waSalesTaxRate | percent: "2.3-5" }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Sales Tax</span>
      <span>{{
          (calcTax$ | async)?.salesTax | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Total Tax</span>
      <span>{{
          (calcTax$ | async)?.totalTax | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <hr class="m-0"/>

    <!-- FEE BREAKDOWN -->

    <li class="app-text-bold">Fees</li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Registration Fees</span>
      <span>{{
          (calcFees$ | async)?.regFee | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Doc Fees</span>
      <span>{{
          (calcFees$ | async)?.docFees | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Title Fee</span>
      <span>{{
          (calcFees$ | async)?.titleFee | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">County Fee</span>
      <span>{{
          (calcFees$ | async)?.countyFee | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">E-filing Fee</span>
      <span>{{
          deal.financeOptions.eFilingFee | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Total State Fees</span>
      <span>{{
          deal.financeOptions.totalStateFees | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Total Fees</span>
      <span>{{
          (calcFees$ | async)?.totalFees | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <hr class="m-0"/>

    <!-- BASE AMOUNT FINANCED -->

    <li class="app-text-bold">Base Finance Amount</li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Total Tax</span>
      <span>{{
          (calcTax$ | async)?.totalTax | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Total Fees</span>
      <span>{{
          (calcFees$ | async)?.totalFees | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Trade Equity</span>
      <span>{{ tradeEquity$ | async | currency: "USD":"symbol":"1.2-2" }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Down Payment</span>
      <span>{{
          deal.financeOptions.downPayment | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Base Finance Amount</span>
      <span>{{
          vehicleBaseFinanceAmount$ | async | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <hr class="m-0"/>

    <!-- FINANCE MONTHLY PAYMENT -->

    <li class="app-text-bold">Finance</li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Interest Rate</span>
      <span>{{ findInterestRate$ | async }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Base Monthly Finance Payment</span>
      <span>{{
          baseMonthlyPayment$ | async | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <li
      *ngFor="let product of financeInsuranceProducts$ | async"
      class="d-flex justify-content-between align-content-center"
    >
      <span class="app-text-small app-text-danger">{{ product.name }}</span>
      <span class="app-text-danger">{{
          insuranceProductMonthlyPayment$(product)
            | async
            | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Total Insurance Products</span>
      <span>{{
          totalFinanceInsuranceProductsPrice$
            | async
            | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Total Monthly Finance Payment</span>
      <span>{{
          totalVehicleFinanceMonthlyPayment$
            | async
            | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small app-text-danger">Days To First Pay</span>
      <span class="app-text-danger">{{
          app.financing.financeDefault.daysToFirstPay
        }}</span>
    </li>

    <hr class="m-0"/>

    <!-- LEASE MONTHLY PAYMENT -->

    <li class="app-text-bold">Lease</li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Gross cap Cost</span>
      <span>{{ grossCapCost$ | async | currency: "USD":"symbol":"1.2-2" }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Cap Cost Reduction</span>
      <span>{{ CCR$ | async | currency: "USD":"symbol":"1.2-2" }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Adjusted Cap Cost</span>
      <span>{{
          adjustedCapCost$ | async | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Residual Value</span>
      <span>{{ residualValue$ | async | currency: "USD":"symbol":"1.2-2" }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Depreciation</span>
      <span>{{
          depreciationAmount$ | async | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Rent Charge</span>
      <span>{{ rentCharge$ | async | currency: "USD":"symbol":"1.2-2" }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Money Factor</span>
      <span>{{ moneyFactor$ | async }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Base Monthly Lease Payment</span>
      <span>{{ baseMonthlyLeasePayment$ | async }}</span>
    </li>

    <li
      *ngFor="let product of leaseInsuranceProducts$ | async"
      class="d-flex justify-content-between align-content-center"
    >
      <span class="app-text-small app-text-danger">{{ product.name }}</span>
      <span class="app-text-danger">{{
          insuranceProductMonthlyPaymentForLease$(product)
            | async
            | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Total Insurance Products</span>
      <span>{{
          calcTotalLeaseInsuranceProductsPrice$
            | async
            | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>

    <li class="d-flex justify-content-between align-content-center">
      <span class="app-text-small">Total Monthly Lease Payment</span>
      <span>{{ totalMonthlyLeasePayment$ | async }}</span>
    </li>

    <hr class="m-0"/>

    <!-- INSURANCE PRODUCTS -->

    <li class="app-text-bold">Insurance Products</li>
    <li class="app-text-small">* Shows currently selected term price</li>

    <li
      *ngFor="let product of allInsuranceProducts$ | async"
      class="d-flex justify-content-between align-content-center"
    >
      <span class="app-text-small app-text-danger">{{ product.name }}</span>
      <span class="app-text-danger">{{
          pathOr(0, ["termCosts", product.selectedTerm, "price"], product)
            | currency: "USD":"symbol":"1.2-2"
        }}</span>
    </li>
  </ul>
</div>
