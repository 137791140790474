import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { DealService } from "../../services";
import { InsuranceProduct, TermCost } from "../../models";
import { HistoryService } from "../../services/history.service";

@Component({
  selector: "app-insurance-item-vsa",
  templateUrl: "./insurance-item-vsa.component.html",
  styleUrls: ["./insurance-item-vsa.component.scss"]
})
export class InsuranceItemVsaComponent implements OnInit {
  @Input() product: InsuranceProduct;
  @Input() selectedTermIndex = null;
  @Input() vehicleCondition: string;
  @Output() closeModal = new EventEmitter();
  private uiState = {
    activeView: "comparison",
    tabs: [
      {title: "Comparison", id: "comparison"},
      {title: "2020 Vehicle Dependability", id: "vehicleDependability2020"},
      {title: "2023 Vehicle Dependability", id: "vehicleDependability2023"},
      // { title: "Vehicle Cutaway", id: "cutaway" },
      // { title: "Components", id: "components" },
      // { title: "Overview", id: "overview" }
    ]
  };

  vsaForm: UntypedFormGroup = this.formBuilder.group({
    termCostIndex: [0]
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dealService: DealService,
    private historyService: HistoryService,
  ) { }

  ngOnInit() {
    this.syncSelectedPlan();
    this.vsaForm.valueChanges.subscribe(({termCostIndex}) => {
      this.dealService.dealInsuranceService.submitInsuranceProduct(this.product, +termCostIndex);
    });
  }

  // INITIALIZATION

  syncSelectedPlan() {
    const termCostIndex = this.selectedTermIndex || 0;
    this.vsaForm.patchValue({termCostIndex});
  }

  // ACTIONS

  onAcceptPlan() {
    const {termCostIndex} = this.vsaForm.value;
    this.historyService.dispatchAddEvent({
      shortName: "VSA Plan Accepted",
    })
    this.dealService.dealInsuranceService.submitInsuranceProduct(this.product, +termCostIndex);
    this.closeModal.emit();
  }

  onDeclinePlan() {
    this.historyService.dispatchAddEvent({
      shortName: "VSA Plan Declined",
    })
    this.dealService.dealInsuranceService.removeInsuranceProduct(this.product);
    this.closeModal.emit();
  }

  // UI CONTROL & RENDERING

  onSelectView(type: string) {
    this.uiState.activeView = type;
    let eventName: string = "";
    switch (type) {
      case "comparison":
        eventName = "Comparison Tab Selected";
        break;
      case "vehicleDependability2020":
        eventName = "2020 Dependability Tab Selected";
        break;
      case "vehicleDependability2023":
        eventName = "2023 Dependability Tab Selected";
        break;
    }
    if (eventName) {
      this.historyService.dispatchAddEvent({
        shortName: eventName,
      });
    }
  }

  activeView(type: string): boolean {
    return this.uiState.activeView === type;
  }

  get viewTabs() {
    return this.uiState.tabs;
  }

  get termCosts(): TermCost[] {
    const terms = this.product ? this.product.termCosts : false;
    return terms || [];
  }

  planSelected(): boolean {
    const {termCostIndex} = this.vsaForm.value;
    return this.selectedTermIndex === +termCostIndex;
  }

  noPlanSelected(): boolean {
    return this.selectedTermIndex === null;
  }

}
