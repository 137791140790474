import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

export class MatTableData {
  displayedColumns: any[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  allItems: any[];
  filteredItems: any[];
  pageSize = 10;
  pageIndex = 0;
  length = 0;

  constructor(displayedColumns: any[], pageSize = 10) {
    this.displayedColumns = displayedColumns;
    this.pageSize = pageSize;
  }

  updateItems(items: any[] = [], sort: MatSort = null, paginator: MatPaginator = null) {
    this.dataSource.data = items ? items : [];
    if (sort) {
      this.dataSource.sort = sort;
    }
    this.allItems = this.filteredItems = this.dataSource.data;
    this.length = this.filteredItems.length;
    this.pageIndex = 1;
    if (paginator) {
      this.dataSource.paginator = paginator;
    }
    this.pageIndex = 0;
  }
}
