export interface WarrantySettings {
  plannedLengthOfOwnership: WarrantySettingOption[];
  milesDrivenPerYear: WarrantySettingOption[];
}

export interface WarrantySettingOption {
  title: string;
  years?: number;
  miles?: number;
}

export interface WarrantyUi {
  activeViewIndex: number;
  maxViewIndex: number;
  titleIndex: {
    basic: number[];
    better: number[];
    best: number[];
  };
  milesPerYear: number;
  ownershipYears: number;
  maxOwnershipYears: number;
  vehicleAge: number;
  vehicleCondition: string;
  vehicleOdometer: number;
  productGraphs: WarrantyBarGraph[];
  backgroundGraphs: WarrantyBarGraphSegment[];
}

export interface WarrantyBarGraph {
  riskInfo: WarrantyBarGraphInfo;
  product: WarrantyBarGraphSegment;
  defaults: WarrantyBarGraphSegment[][];
}

export interface WarrantyBarGraphInfo {
  title: string;
  details: string[];
  visibility: number[];
  bgClass?: string;
}

export interface WarrantyBarGraphSegment {
  title: string;
  bgClass: string;
  width: number; // Width Percentage: 0 to 100
  details: string[];
  productKey?: string;
  imageSrc?: string;
  term?: number;
  miles?: number;
  visibility: number[];
}
