import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { User } from "src/app/user-admin-module/models";
import { AuthService } from "src/app/auth-module/services";
import { Router } from "@angular/router";
import { USER_ROLES } from "src/app/app.config";
import { DealService, VehicleService } from "src/app/clearpath-module/services";
import { map, takeUntil } from "rxjs/operators";
import { Actions, ofType } from "@ngrx/effects";
import * as dealActions from "../../../clearpath-module/store/actions/deal.actions";
import swal, { SweetAlertOptions } from "sweetalert2";
import { SettingsService } from "../../../settings-module/services";
import { Subject } from "rxjs";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { CnaService } from "../../../clearpath-module/services/cna.service";
import { PubNubAngular } from "pubnub-angular2";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit {
  @ViewChild('cnaModal') modalElement!: ElementRef;
  @ViewChild('getInventoryModal') getInventoryModal!: ElementRef;
  private unsubscribe$ = new Subject();
  @Input() currentUser: User;
  @Input() currentRelease: string;
  @Input() currentRoute: string;
  userRoles: string[];

  getInventoryVin: string = "";

  updateGetInventoryVin($event) {
    this.getInventoryVin = $event;
    // console.log("updateGetInventoryVin:", this.getInventoryVin)
  }

  private uiState = {
    routes: {
      admin: [],
      standard: []
    }
  };

  constructor(
    private pubnub: PubNubAngular,
    private authService: AuthService,
    public cnaService: CnaService,
    private dealService: DealService,
    private router: Router,
    private actions$: Actions,
    private settingsService: SettingsService,
    private vehicleService: VehicleService
  ) {
  }

  ngOnInit() {
    this.userRoles = User.unpackSecurityGroups(this.currentUser.securityGroups);
    this.initializeRoutes();
    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(dealActions.notificationsClicked),
    ).subscribe({
      next: () => {
        if (this.router.url !== "/sales-manager/list") {
          this.router.navigate(["/sales-manager/list"]);
        }
      }
    });

    this.pubnub.subscribe({
      channels: [environment.msgChannel],
      triggerEvents: true
    });
    const self = this;
    this.pubnub.getMessage(environment.msgChannel, function ({message}) {
      if (!(message.length == 2 && message[0].startsWith('{deal'))) {
        //console.log("Message Received Navbar:", message, self.currentUser);
        if (message.type == 'WriteupCreated' && (self.isSalesManager() || self.isSysAdmin()) && !self.currentUser.suppressPresentationStarted && message.userId != self.currentUser.employeeId) {
          //console.log("user check", message.userId, self.currentUser.employeeId);
          swal.fire({
            title: "Presentation Started",
            html: `Sales Person: <b>${ message.salesPersonName }</b><br>Customer: <b>${ message.customerName }</b><br>Vehicle: <b>${ message.vehicle }</b><br>Stock #: <b>${ message.stockNumber }</b><br><br><a href="/sales-manager/activity/${ message.dealId }">View Activity Tracker</a>`,
            icon: "success",
            showConfirmButton: true,
            confirmButtonText: "OK",
          } as SweetAlertOptions).then();
        }
      }
    });
  }

  // INITIALIZATION

  initializeRoutes() {
    const {
      adminRoutes,
      standardOpenRoutes,
      standardRoleBasedRoutes
    } = this.routeRegistry;

    if (this.isSysAdmin()) {
      adminRoutes.forEach(route => {
        this.uiState.routes.admin.push(route);
      });
    }

    standardOpenRoutes.forEach(route => {
      this.uiState.routes.standard.push(route);
    });

    standardRoleBasedRoutes.forEach(route => {
      const authorizedUser = this.isSysAdmin() || this.userRoles.includes(route.role);
      if (authorizedUser) {
        if (this.isSysAdmin()) {
          this.addSysAdminRouteDescription(route);
        }
        this.uiState.routes.standard.push(route);
      }
    });
  }

  get routeRegistry() {
    return {
      adminRoutes: [
        {routerLink: "/user", title: "Users"},
        {routerLink: "/settings", title: "Settings"},
      ],
      standardOpenRoutes: [
        {routerLink: "/clearpath/credit-check", title: "Credit Check"},
        {routerLink: "/clearpath/new-deal", title: "Create Writeup"},
        {routerLink: "/clearpath/list", title: "Presentation"},
      ],
      standardRoleBasedRoutes: [
        // {routerLink: "/cna/list", title: "Customer Portrait", role: USER_ROLES.sales_floor},
        // {routerLink: "/inventory/list", title: "Inventory", role: USER_ROLES.sales_floor},
        {routerLink: "/sales-manager/list", title: "Desking", role: USER_ROLES.sales_manager},
        {routerLink: "/settings", title: "Administration", role: USER_ROLES.sys_admin},
        // {routerLink: "/sales-queue-admin", title: "Cue Admin", role: USER_ROLES.cue_admin},
        // {routerLink: "/sales-queue-view", title: "Cue View", role: USER_ROLES.sales_floor},
        // {routerLink: "/sales-queue-display", title: "Cue Display", role: USER_ROLES.cue_display},
      ],
    };
  }

  addSysAdminRouteDescription(route) {
    if (route.routerLink === "/inventory/list") {
      route.title = "Inventory";
    }
    if (route.routerLink === "/cna/list") {
      route.title = "CNA";
    }
    if (route.routerLink === "/sales-manager/list") {
      route.title = "Desking";
    }
    if (route.routerLink === "/clearpath/list") {
      route.title = "Presentation";
    }
  }

  logoClicked() {
    this.saveDeal();
    if (this.isSysAdmin()) {
      this.router.navigate(["/settings"]).then();
    } else if (this.isSalesManager()) {
      this.router.navigate(["/sales-manager/list"]).then();
    } else {
      this.router.navigate(["/clearpath/list"]).then();
    }
  }

  btLogoClicked(e) {
    if (e) e.preventDefault();
    this.saveDeal();
    window.location.href = 'https://www.beavertontoyota.com/';
  }

  // ACTIONS

  onSelectRoute(route: string) {
    console.log("Navigating to: ", route);
    if (route === "/clearpath/new-deal") {
      ($(this.modalElement.nativeElement) as any).modal('show');
    }
    this.router.navigate([route]);
  }

  notificationsClicked() {
    this.dealService.dispatchNotificationsClicked();
  }

  saveDeal() {
    this.dealService.dispatchSaveDeal();
  }

  // UI RENDERING

  isActiveRoute(route: string): boolean {
    return !this.currentRoute ? false : this.currentRoute.includes(route);
  }

  // Determines if current route should use the contextual portion of the nav bar
  isContextualRoute(): boolean {
    if (this.currentRoute && this.currentRoute.includes("sales-manager")) {
      return true;
    }

    const {admin, standard} = this.uiState.routes;
    const allRoutes = admin.concat(standard);

    for (const route of allRoutes) {
      if (this.currentRoute && this.currentRoute.includes(route.routerLink)) {
        return true;
      }
    }

    return false;
  }

  get userName(): string {
    const {firstName, lastName} = this.currentUser;
    return `${ firstName } ${ lastName }`;
  }

  get adminRoutes() {
    return this.uiState.routes.admin || [];
  }

  get standardRoutes() {
    return this.uiState.routes.standard || [];
  }

  get viewingDeal(): boolean {
    const route = this.currentRoute || "";
    return route.includes("sales-manager") && !route.includes("list");
  }

  countNotifications$() {
    if (this.isSalesManager()) {
      return this.dealService.getSalesManagerAlertIndices$().pipe(map(indices => indices.length));
    } else {
      return this.dealService.getSalesPersonAlertIndices$().pipe(map(indices => indices.length));
    }
  }

  isSysAdmin(): boolean {
    return this.userRoles.includes(USER_ROLES.sys_admin);
  }

  isSalesManager(): boolean {
    return this.userRoles.includes(USER_ROLES.sales_manager);
  }

  isContextual(): boolean {
    return this.isActiveRoute("/clearpath/list") || this.isActiveRoute("/sales-manager/list");
  }

  showTeamSelect(): boolean {
    const listView = this.isActiveRoute("/clearpath/list");
    const managerListView = this.isActiveRoute("/sales-manager/list");
    const validView = listView || managerListView;
    const validUser = this.isSalesManager() || this.isSysAdmin();
    return validView && validUser;
  }

  public getInventoryForVin() {
    this.settingsService.runPbsImporterForVin(this.getInventoryVin.trim()).pipe().subscribe({
      next: () => {
        this.vehicleService.dispatchGetAllVehicles();
        swal.fire({
          title: "Vehicle Import Complete",
          html: "The vehicle information for vin " + this.getInventoryVin + " has been retrieved successfully.",
          icon: "success",
          showConfirmButton: true,
          confirmButtonText: "OK",
        } as SweetAlertOptions).then(_ => {
          location.reload();
        });
      },
      error: (e) => {
        if (e && e.indexOf("o vehicle found")) {
          swal.fire({
            title: "VIN Not Found",
            html: "The vin \"" + this.getInventoryVin + "\" could not be found. Check the vin you've entered and try again.",
            icon: "error",
            showConfirmButton: true,
            confirmButtonText: "OK",
          } as SweetAlertOptions).then();
        } else {
          swal.fire({
            title: "Inventory Import Error",
            html: "The request to import inventory has failed.  Please contact your System Administrator.",
            icon: "error",
            showConfirmButton: true,
            confirmButtonText: "OK",
          } as SweetAlertOptions).then();
        }
      }
    });
  }

  public getAllInventory() {
    this.settingsService.runPbsImporter().subscribe({
      next: () => {
        swal.fire({
          title: "Inventory Import Started",
          html: "May take up to 1 hour.",
          icon: "success",
          showConfirmButton: true,
          confirmButtonText: "OK",
        } as SweetAlertOptions).then(_ => ($(this.getInventoryModal.nativeElement) as any).modal('hide'));
      },
      error: () => {
        swal.fire({
          title: "Inventory Import Error",
          html: "The request to import inventory has failed.  Please contact your System Administrator.",
          icon: "error",
          showConfirmButton: true,
          confirmButtonText: "OK",
        } as SweetAlertOptions).then();
      }
    });
  }

  public getInventory2() {
    ($(this.getInventoryModal.nativeElement) as any).modal('show');
  }

  logout() {
    this.authService.logout();
  }

  drop(event: CdkDragDrop<string[]>) {
    // console.log("Event:", event)
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      if (event.container.id === "targetList") {
        // console.log("Target list length:", event.container.data.length)
        if (event.container.data.length >= 4) {
          // console.log("Target list too long! Not adding Item");
          swal.fire({
            title: "Maximum Priorities Reached",
            html: "A maximum of 4 customer priorities is permitted at once.  Please remove a priority before adding a new one to the list.",
            icon: "warning",
            showConfirmButton: true,
            confirmButtonText: "OK",
          } as SweetAlertOptions).then();
          return;
        }
      }
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  updateCustomerFirstName($event) {
    this.cnaService.firstName = $event;
  }

  updateCustomerLastName($event) {
    this.cnaService.lastName = $event;
  }

  resetForm() {
    this.cnaService.resetForm();
  }

  getOptionIcon(option: string): string {
    return "/static/images/cna_icons/" + (option.toLowerCase().replace(' ', '_')) + '.png';
  }

  openRouteOneTab() {
    window.open('https://www.routeone.net/digital-retail-ui/?dealerId=SY5CM&source=531vc4', '_blank');
  }

}
