import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { DealState } from "../../clearpath-module/store/state";
import { FinanceOptions, InsuranceProduct, TaxResult, Vehicle } from "../../clearpath-module/models";
import { formatCurrency } from "@angular/common";
import { FeesResult } from "../../clearpath-module/models/calculations";
import { pathOr } from "ramda";
import { CalculationService } from "../../clearpath-module/services";

export interface PrintDisclosure {
  totalFinanced: number;
  deal: DealState,
  vehicle: Vehicle,
  financeOptionsEdits: FinanceOptions
  fees: FeesResult
  taxes: TaxResult
  customizedVehiclePrice: number,
  baseFinanceAmount: number
  interestRate: number
  tradeEquity: number
  baseVehiclePrice: number
  baseMonthlyLeasePayment: number
  vehicleBaseFinanceAmount: number
  baseMonthlyFinancePayment: number
  totalVehicleFinancePrice: number
  totalVehicleFinanceMonthlyPayment: number
  approvedProducts: InsuranceProduct[]
  declinedProducts: InsuranceProduct[]
  accessories: any[]
  dealType: string
  grossCapCost: number
  monthlyProductLeaseCostByName: any
  financingSettings: any
  totalMonthlyLeasePayment: number
  ccr: number
  tradeEquityEstimate: number
  dueAtSigning: number
  taxesBasePriceOnly: TaxResult
  residualValue: number
  rebates: number
}

export class PrintDisclosureForm {

  pd: PrintDisclosure;

  constructor(
    private calcService: CalculationService
  ) {}

  generatePdf(pd: PrintDisclosure, action = 'open') {
    this.pd = pd;
    const documentDefinition = this.getDocumentDefinition();
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const tableLayouts = {
      exampleLayout: {
        hLineWidth: function (i, node) {
          if (i === 0 || i === node.table.body.length) {
            return 0;
          }
          return (i === node.table.headerRows) ? 2 : 1;
        },
        vLineWidth: function (i) {
          return 0;
        },
        hLineColor: function (i) {
          return i === 1 ? 'black' : '#aaa';
        },
        paddingLeft: function (i) {
          return i === 0 ? 0 : 8;
        },
        paddingRight: function (i, node) {
          return (i === node.table.widths.length - 1) ? 0 : 8;
        }
      },
      smallLayout: {
        hLineWidth: function (i, node) { return 0;},
        vLineWidth: function (i, node) { return 0;},
        paddingLeft: function (i, node) { return 2; },
        paddingRight: function (i, node) { return 2; },
        paddingTop: function (i, node) { return 2; },
        paddingBottom: function (i, node) { return 2; },
        fontSize: 9
      }
    };

    const filename = this.generateFileName();


    switch (action) {
      case 'print':
        pdfMake.createPdf(documentDefinition, tableLayouts).print(filename);
        break;
      case 'download':
        pdfMake.createPdf(documentDefinition, tableLayouts).download(filename);
        break;
      case 'open':
      default:
        pdfMake.createPdf(documentDefinition, tableLayouts).open();
        break;
    }
  }

  generateFileName() {
    return "PrintDisclosure.pdf";
  }

  getDocumentDefinition() {
    let content = [];
    content = content.concat(this.renderDocumentTitle('Price Disclosure'));
    content = content.concat(this.renderSectionTitle(this.pd.deal.customer.firstName + " " + this.pd.deal.customer.lastName + "'s " + this.pd.vehicle.make, 11, 0));
    content = content.concat(this.renderPriceOverviewTable());
    //content = content.concat(this.renderSectionTitle('Monthly Payment Options'))
    content = content.concat(this.renderSectionTitle('Confirm', 16, 10));
    content = content.concat(this.generateConfirm());
    content = content.concat(this.renderCustomerSignature());
    //console.log("content", content)
    return {
      info: {title: "Print Disclosure"},
      pageSize: 'letter',
      pageOrientation: 'portrait',
      pageMargins: [40, 40, 40, 40],
      content,
      styles: {
        header: {
          fontSize: 22,
          bold: true
        },
        overviewLeftCell: {
          alignment: 'left',
          bold: false,
          fontSize: 9
        },
        overviewRightCell: {
          alignment: 'right',
          bold: true,
          fontSize: 9
        },
        cell: {
          color: '#000000',
          bold: true,
          fontSize: 10
        },
        superSmallCell: {
          color: '#000000',
          bold: false,
          fontSize: 8
        },
        miniLeftCell: {
          alignment: 'left',
          color: '#000000',
          bold: false,
          fontSize: 9
        },
        miniRightCell: {
          alignment: 'right',
          color: '#000000',
          bold: false,
          fontSize: 9
        },
        miniLeftBoldCell: {
          alignment: 'left',
          color: '#000000',
          bold: true,
          fontSize: 10
        },
        miniRightBoldCell: {
          alignment: 'right',
          color: '#000000',
          bold: true,
          fontSize: 10
        }
      }
    };
  }

  generateConfirm() {
    let leftColumn = this.renderDealDetailsSection();
    let rightColumn = [];

    if (this.pd.dealType === 'lease') {
      rightColumn = rightColumn.concat([
        this.renderDueAtSigningSection(),
        {
          text: ' ',
          bold: true,
          fontSize: 10,
          alignment: 'left',
          margin: [0, 0, 0, 5]
        },
      ]);
    }

    rightColumn = rightColumn.concat([
      this.renderDealMoreSection()
    ]);

    if (this.pd.declinedProducts && this.pd.declinedProducts.length > 0) {
      rightColumn = rightColumn.concat([
        {
          text: ' ',
          bold: true,
          fontSize: 10,
          alignment: 'left',
          margin: [0, 0, 0, 5]
        },
        this.renderDeclineSection()
      ]);
    }


    return {
      columns: [
        leftColumn,
        rightColumn
      ],
      columnGap: 20
    };
  }

  renderDocumentTitle(pageTitle: string): any[] {
    return [
      {
        text: pageTitle,
        bold: true,
        fontSize: 20,
        alignment: 'left',
        margin: [0, 0, 0, 20]
      }
    ];
  }

  renderSectionTitle(pageTitle: string, fontSize = 16, bottomMargin = 20): any[] {
    return [
      {
        text: pageTitle,
        bold: true,
        fontSize,
        alignment: 'left',
        margin: [0, 0, 0, bottomMargin]
      }
    ];
  }

  renderPriceOverviewTable(): any[] {

    const now = new Date();

    // Use Intl.DateTimeFormat to format the date and time
    // You can customize 'en-US' to your locale and adjust the options as needed
    const formatter = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      weekday: 'long',
      hour: 'numeric',
      minute: '2-digit',
      second: '2-digit',
      hour12: true
    });

    // Return the formatted date and time string
    const dateString = formatter.format(now);

    let body = [

      ['', ''],
      [{text: 'Date/Time:', style: 'overviewLeftCell'}, {text: dateString, style: 'overviewRightCell'}],
      [{text: 'Vehicle:', style: 'overviewLeftCell'}, {text: this.getVehicleFullName(), style: 'overviewRightCell'}],
      [{text: 'VIN:', style: 'overviewLeftCell'}, {text: this.pd.vehicle.vin, style: 'overviewRightCell'}],
      [{text: 'Vehicle Price (MSRP):', style: 'overviewLeftCell'}, {text: this.formatCurrency(this.getMSRP()), style: 'overviewRightCell'}],
      [{text: 'Customized Vehicle Price:', style: 'overviewLeftCell'}, {text: this.formatCurrency(this.getCustomizedVehiclePrice()), style: 'overviewRightCell'}],
      [{text: this.getFinalBaseAmountLabel('Total') + ':', style: 'overviewLeftCell'}, {
        text:
          this.pd.dealType !== 'lease' ? this.formatCurrency(this.pd.dealType === 'finance' ? this.pd.totalVehicleFinancePrice :
            this.pd.financeOptionsEdits.amountFinanced !==null ? this.pd.financeOptionsEdits.amountFinanced : this.pd.totalVehicleFinancePrice) : this.formatCurrency(this.pd.grossCapCost)
        , style: 'overviewRightCell'
      }],
    ];

    return [
      {
        layout: 'exampleLayout',
        table: {
          headerRows: 1,
          widths: [150, '*'],
          body
        },
        margin: [0, 0, 0, 20]
      }
    ];
  }

  renderDealDetailsSection() {

    let body: any[] = [
      ['', ''],
      [{text: 'Base Price', style: 'miniLeftCell'}, {text: this.formatCurrency(this.getBaseVehiclePrice()), style: 'miniRightCell',}],
    ];

    if (this.pd.accessories)
      this.pd.accessories.forEach(accessory => {
        body = body.concat([
          [{text: '+ ' + accessory.name, style: 'miniLeftCell'}, {text: this.formatCurrency(accessory.price), style: 'miniRightCell'}],
        ]);
      });

    body = body.concat([[{text: '---', style: 'miniLeftCell'}, {text: '---', style: 'miniRightCell',}]]);

    body = body.concat([
      [{text: 'Customized Vehicle Price', style: 'miniLeftCell'}, {
        text: this.formatCurrency(this.getCustomizedVehiclePrice()),
        style: 'miniRightCell',
      }],
    ]);

    if (this.pd.rebates !== 0)
      body = body.concat([
        [{text: 'Rebates', style: 'miniLeftCell'}, {
          text: this.formatCurrency(this.pd.rebates),
          style: 'miniRightCell',
        }],
      ]);

    if (this.pd.dealType !== 'lease') {
      if (Math.round(this.getTradeEquity()) != 0) {
        body = body.concat([
          [{text: 'Trade Equity', style: 'miniLeftCell'}, {text: (this.getTradeEquity() > 0 ? '-' : '') + this.formatCurrency(this.getTradeEquity()), style: 'miniRightCell'}],
        ]);
      }
      //console.log('this.getDownPayment()', this.getDownPayment())
      if (Math.round(this.getDownPayment()) != 0) {
        body = body.concat([
          [{text: 'Down Payment', style: 'miniLeftCell'}, {text: (this.getDownPayment() > 0 ? '-' : '') + this.formatCurrency(this.getDownPayment()), style: 'miniRightCell'}],
        ]);
      }
    } else {
      body = body.concat([
        [{text: 'Acquisition Fee', style: 'miniLeftCell'}, {text: this.formatCurrency(this.pd.deal.leaseOptions.acquisitionFee || this.pd.financingSettings.leaseDefault.acquisitionFee), style: 'miniRightCell'}],
      ]);
    }

    body = body.concat([
      [{text: 'DMV & Document Fees', style: 'miniLeftCell'}, {text: this.formatCurrency(this.getTotalFees()), style: 'miniRightCell'}],
      [{text: 'Taxes', style: 'miniLeftCell'}, {text: this.formatCurrency(this.pd.dealType !== 'lease' ? this.pd.taxesBasePriceOnly.totalTax : this.getTotalTaxes()), style: 'miniRightCell'}],
      [{text: this.getFinalBaseAmountLabel(), style: 'miniLeftBoldCell'}, {text: this.pd.dealType !== 'lease' ? this.formatCurrency(this.getBaseAmountFinanced()) : this.formatCurrency(this.pd.grossCapCost), style: 'miniRightBoldCell'}],
    ]);

    return [
      {
        width: 600,
        style: 'tableExample',
        color: '#555',
        table: {
          width: '100%',
          body: [
            [
              {
                text: this.getVehicleFullName(),
                style: 'cell',
                width: 600
              }
            ],
            [
              {
                layout: 'smallLayout', // optional
                table: {
                  headerRows: 1,
                  widths: [176, 60],
                  body
                },
                margin: [0, 0, 0, 0],

              },
            ]
          ]
        },
        layout: {
          hLineWidth: function (i, node) { return (i === 0 || i === node.table.body.length) ? 1 : 0;},
          vLineWidth: function (i, node) { return (i === 0 || i === node.table.widths.length) ? 1 : 0;},
          paddingLeft: function (i, node) { return 5; },
          paddingRight: function (i, node) { return 5; },
          paddingTop: function (i, node) { return 5; },
          paddingBottom: function (i, node) { return 5; }
        }
      }
    ];

  }

  renderDealMoreSection() {

    let title = 'Cash';

    let totalAccTaxes: number = 0;
    let monthlyAccTaxes: number = 0;

    if (this.pd.dealType !== 'lease') {
      totalAccTaxes -= this.pd.vehicleBaseFinanceAmount;
      monthlyAccTaxes -= this.pd.baseMonthlyFinancePayment;
    }

    switch (this.pd.dealType) {
      case 'finance':
        title = this.pd.deal.financeOptions.selectedFinancingTerm + ' Month Financing';
        break;
      case 'lease':
        title = this.pd.deal.leaseOptions.selectedLeaseTerm + ' Month Lease';
        break;
    }

    let body = [
      ['', {text: 'Amount', style: 'miniRightCell'}, {text: 'Monthly', style: 'miniRightCell'}],
      [
        {text: 'Base Payment', style: 'miniLeftCell'},
        {text: this.pd.dealType !== 'lease' ? this.formatCurrency(this.pd.vehicleBaseFinanceAmount) : '', style: 'miniRightCell'},
        {
          text: this.pd.dealType !== 'lease' ?
            (this.pd.dealType === 'finance' ? this.formatCurrency(this.pd.baseMonthlyFinancePayment) : 'N/A') :
            this.formatCurrency(this.pd.baseMonthlyLeasePayment)
          , style: 'miniRightCell'
        }],
    ];

    if (this.pd.approvedProducts)
      this.pd.approvedProducts.forEach(product => {

        if (this.pd.dealType !== 'lease') {
          totalAccTaxes -= this.displayFinanceInsuranceProductsAmount(product);
          monthlyAccTaxes -= this.calcService.insuranceProductMonthlyPaymentCalc(this.pd.interestRate, this.pd.deal.financeOptions, product);
        }

        body = body.concat([[{
          text: '+ ' + product.name, style: 'miniLeftCell'
        },
          {text: this.formatCurrency(this.displayFinanceInsuranceProductsAmount(product)), style: 'miniRightCell'},
          {
            text: this.pd.dealType !== 'lease' ?
              (this.pd.dealType === 'finance' ? this.formatCurrency(
                this.calcService.insuranceProductMonthlyPaymentCalc(this.pd.interestRate, this.pd.deal.financeOptions, product)
              ) : 'N/A')
              : this.formatCurrency(this.pd.monthlyProductLeaseCostByName[ product.name ]),
            style: 'miniRightCell'
          }],
        ]);
      });

    if (this.pd.dealType !== 'lease') {
      totalAccTaxes += this.pd.totalVehicleFinancePrice;
      monthlyAccTaxes += this.pd.totalVehicleFinanceMonthlyPayment;
    }

    if (this.pd.dealType !== 'lease' && (totalAccTaxes !== 0)) {
      body = body.concat([
        [
          {text: 'Taxes', style: 'miniLeftCell'},
          {text: this.formatCurrency(totalAccTaxes), style: 'miniRightCell'},
          {text: this.pd.dealType === 'finance' ? this.formatCurrency(monthlyAccTaxes) : 'N/A', style: 'miniRightCell'}
        ],
      ]);
    }

    body = body.concat([[
      {
        text:
          (this.pd.dealType !== 'lease' ? (this.pd.dealType === 'finance' ? 'Total Amount Financed' : 'Total Amount Paid') : 'Total')
        , style: 'miniLeftBoldCell'
      },
      {text: this.pd.dealType !== 'lease' ? this.formatCurrency(this.pd.dealType === 'finance' ? this.pd.totalVehicleFinancePrice :
          (this.pd.financeOptionsEdits.amountFinanced !==null ? this.pd.financeOptionsEdits.amountFinanced : this.pd.totalVehicleFinancePrice )) : '', style: 'miniRightBoldCell'},
      {
        text: this.pd.dealType !== 'lease' ?
          (this.pd.dealType === 'finance' ? this.formatCurrency(this.pd.financeOptionsEdits.monthlyPayment !==null ? this.pd.financeOptionsEdits.monthlyPayment : this.pd.totalVehicleFinanceMonthlyPayment) : 'N/A') :
          this.formatCurrency(this.pd.financeOptionsEdits.monthlyPayment !==null ? this.pd.financeOptionsEdits.monthlyPayment : this.pd.totalMonthlyLeasePayment)
        , style: 'miniRightBoldCell'
      }],
    ]);

    if (this.pd.dealType === 'finance') {
      body = body.concat([[{text: '---', style: 'miniLeftCell'}, {text: '', style: 'miniRightCell'}, {text: '---', style: 'miniRightCell'}]]);
      const interestRate = this.getInterestRate();
      body = body.concat([
        [{text: 'Interest Rate', style: 'miniLeftCell'}, {text: '', style: 'miniRightBoldCell'}, {text: (interestRate ? Math.round(interestRate * 100) / 100 : 0) + '% APR', style: 'miniRightCell'}],
      ]);
    }

    if (this.pd.dealType === 'lease') {
      body = body.concat([[{text: '---', style: 'miniLeftCell'}, {text: '', style: 'miniRightCell'}, {text: '---', style: 'miniRightCell'}]]);
      body = body.concat([
        [{text: 'Mileage Allowance', style: 'miniLeftCell'}, {text: '', style: 'miniRightBoldCell'}, {text: this.formatNumber(this.pd.deal.vehicleNeeds.milesDrivenPerYear) + '/year', style: 'miniRightCell'}],
        [{text: 'Guaranteed Future Value', style: 'miniLeftCell'}, {text: '', style: 'miniRightBoldCell'}, {text: this.formatCurrency(this.pd.residualValue), style: 'miniRightCell'}],
      ]);
    }


    let bodySections = [
      [
        {
          text: title,
          style: 'cell'
        }
      ],
      [
        {
          layout: 'smallLayout',
          margin: [0, 0, 0, 0],
          table: {
            headerRows: 1,
            widths: [120, 55, 55],
            body
          },
        },
      ],
    ];

    if (this.pd.dealType === 'finance') {

      bodySections = bodySections.concat([
        [
          {
            text: '* On approved credit. Monthly payment on this worksheet may differ from actual monthly payment.',
            style: 'superSmallCell'
          }
        ],
      ],);
    }
    return [
      {
        width: '100%',
        style: 'tableExample',
        color: '#555',
        table: {
          width: '100%',
          margin: [0, 20, 0, 0],
          body: bodySections
        },
        layout: {
          hLineWidth: function (i, node) { return (i === 0 || i === node.table.body.length) ? 1 : 0;},
          vLineWidth: function (i, node) { return (i === 0 || i === node.table.widths.length) ? 1 : 0;},
          paddingLeft: function (i, node) { return 5; },
          paddingRight: function (i, node) { return 5; },
          paddingTop: function (i, node) { return 5; },
          paddingBottom: function (i, node) { return 5; }
        }
      }
    ];

  }

  renderDeclineSection() {

    let body = [
      ['', {text: 'Amount', style: 'miniRightCell'}, {text: 'Monthly', style: 'miniRightCell'}],
    ];

    if (this.pd.declinedProducts)
      this.pd.declinedProducts.forEach(product => {
        body = body.concat([
          [{text: '+ ' + product.name, style: 'miniLeftCell'}, {
            text:
              this.formatCurrency(
                this.displayFinanceInsuranceProductsAmount(product)
              )
            , style: 'miniRightCell'
          }, {
            text: this.pd.dealType !== 'lease' ?
              (this.pd.dealType === 'finance' ? this.formatCurrency(
                this.calcService.insuranceProductMonthlyPaymentCalc(this.pd.interestRate, this.pd.deal.financeOptions, product, this.pd.deal.financeOptions.selectedFinancingTerm)
              ) : 'N/A')
              : this.formatCurrency(this.pd.monthlyProductLeaseCostByName[ product.name ]), style: 'miniRightCell'
          }
          ]]);
      });

    return [
      {
        width: '100%',
        style: 'tableExample',
        color: '#555',
        table: {
          width: '100%',
          margin: [0, 20, 0, 0],
          body: [
            [
              {
                text: 'Declined Products',
                style: 'cell'
              }
            ],
            [
              {
                layout: 'smallLayout',
                table: {
                  headerRows: 1,
                  widths: [120, 55, 55],
                  body,
                },
                margin: [0, 0, 0, 0],
              },
            ]
          ],
        },
        layout: {
          hLineWidth: function (i, node) { return (i === 0 || i === node.table.body.length) ? 1 : 0;},
          vLineWidth: function (i, node) { return (i === 0 || i === node.table.widths.length) ? 1 : 0;},
          paddingLeft: function (i, node) { return 5; },
          paddingRight: function (i, node) { return 5; },
          paddingTop: function (i, node) { return 5; },
          paddingBottom: function (i, node) { return 5; }
        }
      }
    ];
  }

  renderDueAtSigningSection() {

    let body: any[] = [
      ['', ''],
    ];

    if (Math.round(this.pd.deal.financeOptions.downPayment) !== 0)
      body = body.concat([
        [{text: 'Down Payment', style: 'miniLeftCell'}, {text: this.formatCurrency(this.pd.deal.financeOptions.downPayment), style: 'miniRightCell'}]
      ]);

    if (Math.round(this.pd.tradeEquityEstimate) !== 0)
      body = body.concat([
        [{text: 'Trade Equity', style: 'miniLeftCell'}, {text: this.formatCurrency(this.pd.tradeEquityEstimate), style: 'miniRightCell'}],
      ]);

    if (Math.round(this.pd.dueAtSigning) !== 0)
      body = body.concat([
        [{text: 'Total', style: 'miniLeftCell'}, {text: this.formatCurrency(this.pd.dueAtSigning), style: 'miniRightCell'}],
        [{text: '---', style: 'miniLeftCell'}, {text: '---', style: 'miniRightCell'}],
      ]);

    body = body.concat([
      [{text: 'Due Today', style: 'miniLeftBoldCell'}, {text: '', style: 'miniRightCell'}],
      [{text: 'First Month\'s Payment', style: 'miniLeftCell'}, {text: this.formatCurrency(this.pd.financeOptionsEdits.monthlyPayment !==null ? this.pd.financeOptionsEdits.monthlyPayment : this.pd.totalMonthlyLeasePayment), style: 'miniRightCell'}],
      //[{text: 'Capitalized Cost Reduction', style: 'miniLeftCell'}, {text: this.formatCurrency(this.pd.ccr), style: 'miniRightCell'}],
    ]);

    return [
      {
        width: 600,
        style: 'tableExample',
        color: '#555',
        table: {
          width: '100%',
          body: [
            [
              {
                text: "Due at Signing",
                style: 'cell',
                width: 600
              }
            ],
            [
              {
                layout: 'smallLayout', // optional
                table: {
                  headerRows: 1,
                  widths: [174, 60],
                  body
                },
                margin: [0, 0, 0, 0],

              },
            ]
          ]
        },
        layout: {
          hLineWidth: function (i, node) { return (i === 0 || i === node.table.body.length) ? 1 : 0;},
          vLineWidth: function (i, node) { return (i === 0 || i === node.table.widths.length) ? 1 : 0;},
          paddingLeft: function (i, node) { return 5; },
          paddingRight: function (i, node) { return 5; },
          paddingTop: function (i, node) { return 5; },
          paddingBottom: function (i, node) { return 5; }
        }
      }
    ];

  }

  renderCustomerSignature(): any[] {
    return [
      {
        text: '____________________________________________________',
        bold: true,
        fontSize: 9,
        alignment: 'left',
        margin: [0, 60, 0, 0]
      },
      {
        text: 'Customer Signature',
        italics: true,
        fontSize: 9,
        alignment: 'left',
        margin: [0, 3, 0, 0]
      }
    ];
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // HELPER FUNCTIONS
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  formatNumber(val: number) {
    return Intl.NumberFormat().format(val);
  }

  formatCurrency(val: number) {
    return formatCurrency(val ? Math.round(val * 100) / 100 : 0, "en-US", "$", "USD");
  }

  displayFinanceInsuranceProductsAmount(product: any): any {
    //console.log(product)
    if (product) {
      const priceOverride = pathOr(0, ["termCosts", product.selectedTerm, "priceOverride"], product);
      const price = pathOr(0, ["termCosts", product.selectedTerm, "price"], product);
      return priceOverride || price;
    }
    return 0;
  }

  getMSRP() {
    return this.pd.financeOptionsEdits.msr || this.pd.vehicle.msr;
  }

  getDownPayment() {
    return this.pd.financeOptionsEdits.downPayment || this.pd.deal.financeOptions.downPayment;
  }

  getBaseAmountFinanced() {
    return this.pd.baseFinanceAmount;
  }

  getVehicleFullName() {
    return this.pd.vehicle.year + " " + this.pd.vehicle.make + " " + this.pd.vehicle.model;
  }

  getTotalFees() {
    return this.pd.fees.totalFees;
  }

  getTotalTaxes() {
    return this.pd.taxes.totalTax;
  }

  getCustomizedVehiclePrice() {
    return this.pd.customizedVehiclePrice;
  }

  getInterestRate() {
    return this.pd.interestRate * 100;
  }

  getTradeEquity() {
    return this.pd.tradeEquity;
  }

  getBaseVehiclePrice() {
    return this.pd.baseVehiclePrice;
  }

  getFinalBaseAmountLabel = (type = 'Base') => {
    switch (this.pd.dealType) {
      case 'finance':
        return type + ' Amount Financed';
      case 'lease':
        return 'Base Gross Capitalized Cost';
      case 'cash':
        return type + ' Amount Paid';
    }
  };
}
