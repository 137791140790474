import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import * as fromRoot from "../../store/state";
import {DealState, DealType} from "../../store/state";
import * as appActions from "../../store/actions/app.actions";
import * as appSelectors from "../../store/selectors/clearpath";
import * as dealActions from "../../store/actions/deal.actions";
import * as dealSelectors from "../../store/selectors/deal";
import {combineLatest, forkJoin, Observable} from "rxjs";
import {map, take} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {
  Accessory,
  Buyer,
  FinanceOptions,
  FinancingSettings,
  Insurance,
  LeaseOptions,
  NewDealRequest,
  TradeIn,
  Vehicle,
  VehicleNeeds
} from "../../models";
import {FileUploadService} from "src/app/shared-module/services";
import {SignatureData} from "../../models/customer";
import {DealInsuranceService} from "./deal-insurance.service";
import {User} from "src/app/user-admin-module/models";
import {DealIncentive} from "src/app/settings-module/models/incentives";
import {AngularFireUploadTask} from "@angular/fire/storage/task";
import {UploadTaskSnapshot} from "@angular/fire/storage/interfaces";
import {AuthService} from "src/app/auth-module/services";
import {DEAL_STATUSES} from "src/app/app.config";
import {HistoryService} from "../history.service";
import {Lender} from "src/app/sales-manager-module/models/data";
import {AppService} from "../app.service";
import {HistoryEvent} from "../../models/history";

const api = "/v1/deal";
const routes = {
  list: `${api}/list`,
  readPrintDeals: `${api}/readprintdeals`,
  listSummary: `${api}/readdealsummaries`,
  getByStock: stockNum => `${api}/read/${stockNum}`,
  getByDealId: dealId => `${api}/get/${dealId}`,
  // Deal Lifecycle
  create: `${api}/create`,
  change: `${api}/change`,
  submit: `${api}/submit`,
  retract: dealId => `${api}/retract/${dealId}`,
  update: `${api}/update`,
  save: `${api}/save`,
  approve: dealId => `${api}/approve/${dealId}`,
  print: dealId => `${api}/print/${dealId}`,
  decline: dealId => `${api}/decline/${dealId}`,
  complete: `${api}/complete`,
  getVituTaxes: `${api}/getVituTaxes`,
  reportBug: `${api}/reportBug`,
};

@Injectable({
  providedIn: "root"
})
export class DealService {

  taxLookupDeal: DealState;
  taxLookupVehicle: Vehicle;

  constructor(
    private store: Store<fromRoot.DealState>,
    private authService: AuthService,
    private historyService: HistoryService,
    private appService: AppService,
    private http: HttpClient,
    private fileUploadService: FileUploadService,
    public dealInsuranceService: DealInsuranceService,
  ) {
  }


  // SELECTORS

  selectDeal(): Observable<DealState> {
    return this.store.select(dealSelectors.selectDeal);
  }

  selectAllDeals(): Observable<Array<any>> {
    return this.store.select(appSelectors.selectAllDeals);
  }

  selectSalesManagerDeals(): Observable<Array<DealState>> {
    return combineLatest([
      this.authService.isSalesManager$(),
      this.authService.selectUser(),
      this.selectAllDeals()
    ]).pipe(map(([isSalesManager, user, deals]) => {
      if (!isSalesManager) {
        return [];
      } else {
        return deals.filter((deal: DealState) => {
          return deal.managerId === user.employeeId;
        });
      }
    }));
  }

  selectVerifiedForms(): Observable<string[]> {
    return this.store.select(dealSelectors.selectVerifedForms);
  }

  selectDealsLoadingStatus(): Observable<boolean> {
    return this.store.select(appSelectors.selectDealsLoadingStatus);
  }

  selectCustomSalesTaxRateFinance(): Observable<number> {
    return this.store.select(dealSelectors.selectCustomSalesTaxRateFinance);
  }

  selectCustomer(): Observable<Buyer> {
    return this.store.select(dealSelectors.selectCustomer);
  }

  selectCoSigner(): Observable<Buyer> {
    return this.store.select(dealSelectors.selectCoSigner);
  }

  selectVehicleNeeds(): Observable<VehicleNeeds> {
    return this.store.select(dealSelectors.selectVehicleNeeds);
  }

  selectFinanceOptions(): Observable<FinanceOptions> {
    return this.store.select(dealSelectors.selectFinanceOptions);
  }

  selectFinanceOptionsEdits(): Observable<FinanceOptions> {
    return this.store.select(dealSelectors.selectFinanceOptionsEdits);
  }

  selectLeaseOptions(): Observable<LeaseOptions> {
    return this.store.select(dealSelectors.selectLeaseOptions);
  }

  selectTradeIn(): Observable<TradeIn> {
    return this.store.select(dealSelectors.selectTradeIn);
  }

  selectTradeIn2(): Observable<TradeIn> {
    return this.store.select(dealSelectors.selectTradeIn2);
  }

  selectSelectedDealerAccessories(): Observable<string[]> {
    return this.store.select(dealSelectors.selectSelectedDealerAccessories);
  }

  selectAccessories(): Observable<Accessory[]> {
    return this.store.select(dealSelectors.selectAccessories);
  }

  selectIncentives(): Observable<DealIncentive[]> {
    return this.store.select(dealSelectors.selectIncentives);
  }

  selectValuesForWarrantyForm(): Observable<any> {
    return this.store.select(dealSelectors.selectValuesForWarrantyForm);
  }

  selectInsuranceInfo(): Observable<any> {
    return this.store.select(dealSelectors.selectInsuranceInfo);
  }


  // BUSINESS PROCESSES

  includesVerifiedForm$(formName: string): Observable<boolean> {
    return this.selectVerifiedForms()
      .pipe(map(verifiedForms => {
        return (Array.isArray(verifiedForms) ? verifiedForms : []).includes(formName);
      }));
  }

  public submitCreditTier(selectedCreditTier: number) {
    this.dispatchSetFinanceOptions({selectedCreditTier, financeSelected: true, creditTierSelected: true});
  }

  public clearCustomMoneyFactors() {
    this.store.dispatch(dealActions.clearCustomMoneyFactors());
  }

  public setFinancingTerm(selectedFinancingTerm: number) {
    this.store.dispatch(dealActions.setFinancingTerm({selectedFinancingTerm}));
  }

  public setStateTaxesFees(totalStateTaxes: number, totalStateFees: number) {
    //this.store.dispatch(dealActions.setStateTaxesFees(totalStateTaxes, totalStateFees));
    this.dispatchSetFinanceOptionsEdits({
      totalStateTaxes,
      totalStateFees,
      regFee: 0,
      salesTax: 0
    });
    this.dispatchCalcTax();
    this.dispatchCalcFees();
  }

  public salesManagerSetFinancingTerm(selectedFinancingTerm: number) {
    this.store.dispatch(dealActions.salesManagerSetFinancingTerm({selectedFinancingTerm}));
  }

  public setLeasingTerm(selectedLeaseTerm: number) {
    this.store.dispatch(dealActions.setLeasingTerm({selectedLeaseTerm}));
    this.dispatchSetFinanceOptions({
      customSelected: false,
      financeSelected: false,
      cashPurchase: false
    });
  }

  public submitLeasingTerm(selectedLeaseTerm: number, monthlyPayment: number) {
    this.dispatchSetFinanceOptions({
      financeSelected: false,
      cashPurchase: false
    });
    this.dispatchSetLeaseOptions({
      selectedLeaseTerm,
      monthlyPayment,
      leaseSelected: true
    });
  }

  public submitCustomFinanceRate(customerProvidedInterestRate: number) {
    // if customerProvidedInterestRate is 0, undefined, or null, set to false.
    const customSelected = !(customerProvidedInterestRate === 0 || !customerProvidedInterestRate);
    this.dispatchSetCustomFinanceRate({
      customerProvidedInterestRate,
      customSelected,
      activeInterestRate: customerProvidedInterestRate
    });
  }

  public submitDownPayment(downPayment: number, previousDownPayment: number) {
    this.historyService.dispatchDownPaymentChanged(previousDownPayment, downPayment);
    downPayment = Math.round(downPayment);
    this.dispatchSetFinanceOptions({
      downPayment,
      downPaymentZero: downPayment <= 0
    });
  }

  public submitDefaultDownPayment(defaultDownPayment: number) {
    this.dispatchSetFinanceOptions({defaultDownPayment});
  }

  public submitTaxRate(customSalesTaxRate: number) {
    this.dispatchSetFinanceOptions({customSalesTaxRate});
    this.dispatchSetLeaseOptions({customSalesTaxRate});
    this.dispatchCalcTax();
  }

  public submitDocFees(docFees: number) {
    this.dispatchSetFinanceOptions({docFees});
  }

  public submitRollPaymentForm(
    desiredPayment: number,
    downPayment: number,
    numberOfMonthlyPayments: number
  ) {
    this.dispatchSetFinanceOptions({desiredPayment, downPayment, numberOfMonthlyPayments});
  }

  public submitSalesManagerTradeIn(tradeIn: Partial<TradeIn>) {
    this.dispatchSetTradeIn(tradeIn);
  }

  public submitSalesManagerTradeInOwner(owner: Partial<Buyer>) {
    this.dispatchSetTradeInOwner(owner);
  }

  public submitSalesManagerTradeInOwner2(owner: Partial<Buyer>) {
    this.dispatchSetTradeInOwner2(owner);
  }

  public submitSalesManagerTradeIn2(tradeIn: Partial<TradeIn>) {
    this.dispatchSetTradeIn2(tradeIn);
  }

  public submitSalesManagerTradeIn2Owner(owner: Partial<Buyer>) {
    this.dispatchSetTradeIn2Owner(owner);
  }

  public submitSalesManagerTradeIn2Owner2(owner: Partial<Buyer>) {
    this.dispatchSetTradeIn2Owner2(owner);
  }

  public submitDocFeesForm(formData) {
    const {docFees} = formData;
    this.dispatchSetFinanceOptions({docFees});
  }

  public updateVehicle(vehicle, changeDeal: boolean) {
    const {stockNumber, make, model, year, trim} = vehicle;
    this.selectDeal().pipe(take(1)).subscribe((deal: DealState) => {
      deal.stockNumber = stockNumber;
      deal.vehicle = `${year} ${make} ${model} ${trim}`;
      if (changeDeal) {
        this.dispatchChangeDeal(deal);
      }
    });
    return vehicle;
  }

  public submitAccessory(accessory: Partial<Accessory>) {
    this.selectAccessories()
      .pipe(take(1))
      .subscribe((accessories: Partial<Accessory>[]) => {
        const accessoriesCopy: Partial<Accessory>[] = accessories || [];
        const {name, price} = accessory;
        let addAccessory = true;

        for (let i = 0; i < accessoriesCopy.length; i++) {
          const nameMatch = accessoriesCopy[i].name === name;
          const priceMatch = true;//accessoriesCopy[ i ].price === price;
          if (nameMatch && priceMatch) {
            if (accessoriesCopy[i].disabled) {
              accessoriesCopy[i].disabled = false;
            } else {
              accessoriesCopy.splice(i, 1);
            }
            addAccessory = false;
            break;
          }
        }

        if (addAccessory) {
          accessoriesCopy.push({name, price});
        }

        this.historyService.dispatchAddEvent({
          shortName: name + ' Changed',
          oldValue: addAccessory ? 'Excluded' : 'Included',
          newValue: addAccessory ? 'Included' : 'Excluded',
        });

        this.dispatchSetAccessories(accessoriesCopy);
      });
  }

  uploadSignatures(signatureData: SignatureData): Observable<UploadTaskSnapshot[]> {
    const uploads: AngularFireUploadTask[] = [];
    if (signatureData.buyer) {
      uploads.push(this.fileUploadService.uploadBuyerSignature(signatureData.buyer));
    }
    if (signatureData.coBuyer) {
      uploads.push(this.fileUploadService.uploadCoBuyerSignature(signatureData.coBuyer));
    }
    return forkJoin(uploads);
  }

  parseDatesForSubmission(insuranceInfo): Insurance {
    let parsedPolicyEffectiveDate: string;
    let parsedPolicyExpirationDate: string;
    if (insuranceInfo.policyEffectiveDate) {
      parsedPolicyEffectiveDate = new Date(
        insuranceInfo.policyEffectiveDate
      ).toISOString();
    } else {
      parsedPolicyEffectiveDate = new Date(0).toISOString();
    }
    if (insuranceInfo.policyExpirationDate) {
      parsedPolicyExpirationDate = new Date(
        insuranceInfo.policyExpirationDate
      ).toISOString();
    } else {
      parsedPolicyExpirationDate = new Date(0).toISOString();
    }
    return {
      ...insuranceInfo,
      policyEffectiveDate: parsedPolicyEffectiveDate,
      policyExpirationDate: parsedPolicyExpirationDate
    };
  }

  applyInitialDefaults() {
    combineLatest([
      this.appService.selectFinancing(),
      this.selectDeal()
    ])
      .pipe(take(1))
      .subscribe((
        [financingSettings, deal]:
        [FinancingSettings, DealState]
      ) => {
        if (deal.dealId && !deal.leaseOptions.acquisitionFee) {
          this.dispatchSetLeaseOptions({acquisitionFee: financingSettings.leaseDefault.acquisitionFee});
        }
      });
  }

  getIncentiveRate(term: number, creditTier: number) {
    let rate;
    const deal = this.latestDealState();
    if (deal.incentives) {
      const inc = deal.incentives.find(el => !!(el.financeOffer && el.financeOffer.financeRates));
      if (inc) {
        const rates = inc.financeOffer.financeRates.find(el => el.term === term);
        if (rates) {
          rate = rates.tieredRates[creditTier];
        }
      }
    }
    return rate;
  }

  getSalesManagerAlertIndices$(): Observable<number[]> {
    return this.selectSalesManagerDeals().pipe(map(deals => {
      const alertIndices = [];
      deals.forEach((deal: DealState, i: number) => {
        if (deal.status === DEAL_STATUSES.Submitted) {
          alertIndices.push(i);
        }
      });
      return alertIndices;
    }));
  }

  getSalesPersonAlertIndices$(): Observable<number[]> {
    const alertIndices = [];
    return this.selectAllDeals().pipe(map(deals => {
      deals.forEach((deal: DealState, i: number) => {
        if (deal.status === DEAL_STATUSES.Approved ||
          deal.status === DEAL_STATUSES.Updated) {
          alertIndices.push(i);
        }
      });
      return alertIndices;
    }));
  }

  // DISPATCH - GENERAL

  dispatchGetByDealId(dealId: string) {
    this.store.dispatch(dealActions.getDealByDealId({dealId}));
    this.historyService.dispatchGetHistory(dealId);
  }

  dispatchGetDeals() {
    this.store.dispatch(appActions.getDeals());
  }

  dispatchIncentivesSet(set) {
    this.store.dispatch(dealActions.incentivesSet({incentivesSet: set}));
  }

  dispatchApplyInitialInsuranceProducts() {
    this.store.dispatch(dealActions.applyInitialInsuranceProducts());
  }

  dispatchSetDealType(type: string) {
    const ref = {
      finance: DealType.Finance,
      lease: DealType.Lease,
      cash: DealType.Cash
    };
    this.store.dispatch(dealActions.setDealType({dealType: ref[type]}));
  }

  dispatchPushVerifiedForm(formName: string) {
    this.store.dispatch(dealActions.pushVerifiedForm({formName}));
  }

  dispatchRemoveVerifiedForm(formName: string) {
    this.store.dispatch(dealActions.removeVerifiedForm({formName}));
  }

  dispatchCalcFees() {
    this.store.dispatch(dealActions.calcFees());
  }

  dispatchCalcTax() {
    this.store.dispatch(dealActions.calcTax());
  }

  dispatchSetAccessories(accessories: Partial<Accessory>[]) {
    this.store.dispatch(dealActions.setAccessories({accessories}));
  }

  dispatchSetCoSigner(coSigner: Partial<Buyer>) {
    this.store.dispatch(dealActions.setCoSigner({coSigner}));
  }

  dispatchSetCustomer(customer: Partial<Buyer>) {
    this.store.dispatch(dealActions.setCustomer({customer}));
  }

  dispatchSetFinanceOptions(financeOptions: Partial<FinanceOptions>) {
    this.store.dispatch(dealActions.setFinanceOptions({financeOptions}));
  }

  dispatchSetCustomFinanceRate(financeOptions: Partial<FinanceOptions>) {
    this.store.dispatch(dealActions.setCustomFinanceRate({
      customerProvidedInterestRate: financeOptions.customerProvidedInterestRate,
      customSelected: financeOptions.customSelected,
      activeInterestRate: financeOptions.activeInterestRate
    }));
  }

  dispatchSetFinanceOptionsEdits(financeOptions: Partial<FinanceOptions>) {
    this.store.dispatch(dealActions.setFinanceOptionsEdits({financeOptionsEdits: financeOptions}));
  }

  dispatchSetDmvRemarks(dmvRemarks: string) {
    this.store.dispatch(dealActions.setDmvRemarks({dmvRemarks}));
  }

  dispatchSetLienHolder(lienHolder: Partial<Lender>) {
    this.store.dispatch(dealActions.setLienHolder({lienHolder}));
  }

  dispatchGetPrintDeals() {
    this.store.dispatch(dealActions.getPrintDeals());
  }

  dispatchSetLeaseOptions(leaseOptions: Partial<LeaseOptions>) {
    this.store.dispatch(dealActions.setLeaseOptions({leaseOptions}));
  }

  dispatchSetSalesManager(salesManager: User) {
    this.store.dispatch(dealActions.setSalesManager({salesManager}));
  }

  dispatchSetSalesPerson(salesPerson: User) {
    this.store.dispatch(dealActions.setSalesPerson({salesPerson}));
  }

  dispatchSetTradeIn(tradeIn: Partial<TradeIn>) {
    this.store.dispatch(dealActions.setTradeIn({tradeIn}));
  }

  dispatchSetTradeInOwner(owner: Partial<Buyer>) {
    this.store.dispatch(dealActions.setTradeInOwner({tradeInOwner: owner}));
  }

  dispatchSetTradeInOwner2(owner: Partial<Buyer>) {
    this.store.dispatch(dealActions.setTradeInOwner2({tradeInOwner2: owner}));
  }

  dispatchSetTradeIn2(tradeIn: Partial<TradeIn>) {
    this.store.dispatch(dealActions.setTradeIn2({tradeIn2: tradeIn}));
  }

  dispatchSetTradeIn2Owner(owner: Partial<Buyer>) {
    this.store.dispatch(dealActions.setTradeIn2Owner({tradeIn2Owner: owner}));
  }

  dispatchSetTradeIn2Owner2(owner: Partial<Buyer>) {
    this.store.dispatch(dealActions.setTradeIn2Owner2({tradeIn2Owner2: owner}));
  }

  dispatchSetVehicleNeeds(vehicleNeeds: Partial<VehicleNeeds>) {
    this.store.dispatch(dealActions.setVehicleNeeds({vehicleNeeds}));
  }

  dispatchSetIncentives(incentives: DealIncentive[]) {
    this.store.dispatch(dealActions.setIncentives({incentives}));
  }

  dispatchSetDisplayTerms(displayTerms: any) {
    this.store.dispatch(dealActions.setDisplayTerms({displayTerms}));
  }

  dispatchNotificationsClicked() {
    this.store.dispatch(dealActions.notificationsClicked());
  }

  dispatchSetPlate({plateExpires, plateNumber}) {
    this.store.dispatch(dealActions.setPlate({plateExpires, plateNumber}));
  }

  dispatchSetInitialized(initialized: boolean) {
    this.store.dispatch(dealActions.setInitialized({initialized}));
  }

  dispatchSetInsuranceInfo(insuranceInfo: Partial<Insurance>) {
    this.store.dispatch(dealActions.setInsuranceInfo({insuranceInfo}));
  }

  dispatchSetPlateTransfer(plateTransfer) {
    this.store.dispatch(dealActions.setPlateTransfer({plateTransfer}));
  }

  dispatchAddEvent(event: Partial<HistoryEvent>) {
    this.historyService.dispatchAddEvent(event);
  }

  submitDealSubmitModalForm({
                              signatureData,
                              signatureUrls,
                              customer,
                              coSigner,
                              tradeIn,
                              tradeInOwner,
                              tradeInOwner2,
                              tradeIn2,
                              tradeIn2Owner,
                              tradeIn2Owner2,
                              insuranceInfo,
                              comments
                            }) {
    return this.store.dispatch(dealActions.submitDealSubmitModalForm({
      signatureData,
      signatureUrls,
      customer,
      coSigner,
      tradeIn,
      tradeInOwner,
      tradeInOwner2,
      tradeIn2,
      tradeIn2Owner,
      tradeIn2Owner2,
      insuranceInfo,
      comments
    }));
  }

  // DISPATCH - DEAL LIFECYCLE

  /**
   * @usageNotes Clear Deal -
   * Sets the deal back to initialDealState.
   */
  dispatchClearDeal() {
    this.store.dispatch(dealActions.clearDeal());
  }

  /**
   * @usageNotes Create Deal -
   * New deal created by anyone.
   * @dealStatus Sets to "created"
   */
  dispatchCreateDeal(deal: NewDealRequest) {
    this.store.dispatch(dealActions.createDeal({deal}));
  }

  /**
   * @usageNotes Create Deal -
   * New deal created by sales manager.
   * @dealStatus Sets to "created"
   * side effect is different than "createDeal"
   */
  dispatchSalesManagerCreateDeal(deal: NewDealRequest) {
    this.store.dispatch(dealActions.salesManagerCreateDeal({deal}));
  }

  /**
   * @usageNotes Change Deal -
   * Deal saved by Sales Person / Customer.
   * @dealStatus Sets to "changed"
   */
  dispatchChangeDeal(deal?: DealState) {
    if (!deal) {
      deal = this.latestDealState();
    }
    return this.store.dispatch(dealActions.changeDeal({deal}));
  }

  /**
   * @usageNotes Submit Deal -
   * Deal saved by Sales Person when ready for Sales Manager approval.
   * @dealStatus Sets to "submitted"
   */
  dispatchSubmitDeal(deal?: DealState) {
    if (!deal) {
      deal = this.latestDealState();
    }
    this.store.dispatch(dealActions.submitDeal({deal}));
  }

  /**
   * @usageNotes Retract Deal -
   * Retract deal submit status. Status change only.
   * @dealStatus Sets to "changed"
   */
  dispatchRetractDeal(dealId: string) {
    this.store.dispatch(dealActions.retractDeal({dealId}));
  }

  /**
   * @usageNotes Update Deal -
   * Deal saved by Sales Manager. Requires Sales Person resubmit.
   * @dealStatus Sets to "updated"
   */
  dispatchUpdateDeal(deal?: DealState) {
    if (!deal) {
      deal = this.latestDealState();
    }
    this.store.dispatch(dealActions.updateDeal({deal}));
  }

  /**
   * @usageNotes Save Deal -
   * Deal saved by anyone without affecting deal lifecycle.
   * @dealStatus No change
   */
  dispatchSaveDeal(deal?: DealState) {
    if (!deal) {
      deal = this.latestDealState();
    }
    this.store.dispatch(dealActions.saveDeal({deal}));
  }

  /**
   * @usageNotes Approve Deal -
   * Deal reviewed and approved by Sales Manager. Status change only.
   * @dealStatus Sets to "approved"
   * @param dealId
   * @param criticalMemo
   */
  dispatchApproveDeal(dealId: string, criticalMemo: string = "") {
    this.store.dispatch(dealActions.approveDeal({dealId, criticalMemo}));
  }

  /**
   * @usageNotes Print Deal -
   * Initiate deal printing. Status change only.
   * @dealStatus Sets to "printing"
   */
  dispatchPrintDeal(dealId: string) {
    this.store.dispatch(dealActions.printDeal({dealId}));
  }

  /**
   * @usageNotes Decline Deal -
   * Sales Manager, Sales Person, or Customer declines deal. Status change only.
   * @dealStatus Sets to "declined"
   */
  dispatchDeclineDeal(dealId: string) {
    this.store.dispatch(dealActions.declineDeal({dealId}));
  }

  /**
   * @usageNotes Complete Deal -
   * Deal saved by Sales Manager when finalized and sold.
   * @dealStatus Sets to "completed"
   */
  dispatchCompleteDeal(deal?: DealState) {
    if (!deal) {
      deal = this.latestDealState();
    }
    this.store.dispatch(dealActions.completeDeal({deal}));
  }

  /**
   * @usageNotes set Deal Odometer -
   * set's the odometer on the "deal" state
   */
  setDealOdometer(odometer: number) {
    this.store.dispatch(dealActions.setDealOdometer({odometer}));
  }

  dispatchRecalculate() {
    this.store.dispatch(dealActions.recalculate());
  }

  private latestDealState(): DealState {
    let deal: DealState;
    this.selectDeal().pipe(take(1)).subscribe((data: DealState) => {
      deal = data;
    });
    return deal;
  }

  // HTTP - DEAL GETTERS

  list(): Observable<any> {
    return this.http.get(routes.listSummary);
  }

  // listSummary(): Observable<any> {
  //   return this.http.get(routes.listSummary);
  // }

  getByStockNumber(stockNumber: string): Observable<any> {
    return this.http.get(routes.getByStock(stockNumber));
  }

  getPrintDeals(): Observable<any> {
    return this.http.get(routes.readPrintDeals);
  }

  getByDealId(dealId: string): Observable<any> {
    return this.http.get(routes.getByDealId(dealId));
  }

  // HTTP - DEAL LIFECYCLE

  create(deal: NewDealRequest): Observable<any> {
    return this.http.post(routes.create, deal);
  }

  change(deal: DealState): Observable<any> {
    deal = {
      ...deal,
      insuranceInfo: {
        ...deal.insuranceInfo,
        policyEffectiveDate: this.dealInsuranceService.parseDateToISO(deal.insuranceInfo.policyEffectiveDate),
        policyExpirationDate: this.dealInsuranceService.parseDateToISO(deal.insuranceInfo.policyExpirationDate),
      }
    };
    return this.http.post(routes.change, deal);
  }

  submit(deal: DealState): Observable<any> {
    return this.http.post(routes.submit, deal);
  }

  retract(dealId: string): Observable<any> {
    return this.http.post(routes.retract(dealId), null);
  }

  update(deal: DealState): Observable<any> {
    return this.http.post(routes.update, deal);
  }

  save(deal: DealState): Observable<any> {
    return this.http.post(routes.save, deal);
  }

  approve(dealId: string, criticalMemo: string): Observable<any> {
    return this.http.post(routes.approve(dealId), {criticalMemo});
  }

  print(dealId: string): Observable<any> {
    return this.http.post(routes.print(dealId), null);
  }

  decline(dealId: string): Observable<any> {
    return this.http.post(routes.decline(dealId), null);
  }

  complete(deal: DealState): Observable<any> {
    return this.http.post(routes.complete, deal);
  }

  getVituTaxes(deal: DealState, vehicle: Vehicle, sellingPrice: number): Observable<any> {
    return this.http.post(routes.getVituTaxes, {deal, vehicle, sellingPrice});
  }

  reportBug(deal: DealState, vehicle: Vehicle, form: any, attachments: any[]): Observable<any> {
    const formData = new FormData();
    formData.append('deal', JSON.stringify(deal));
    formData.append('vehicle', JSON.stringify(vehicle));
    formData.append('form', JSON.stringify(form));
    Array.from(attachments).forEach((attachment: any, i: number) => formData.append(`attachment_${i}`, attachment));
    return this.http.post(routes.reportBug, formData);
  }
}
