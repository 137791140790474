import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AlertService } from "../../../shared-module/services";
import { UserService } from "../../services";
import { User } from "../../models";
import { FORM_INPUT_REQS } from "src/app/app.config";
import { formatPhoneNumber } from "../../../util/angularUtils";

@Component({
  selector: "app-user-admin",
  templateUrl: "./user-admin.component.html",
  styleUrls: ["./user-admin.component.scss"]
})
export class UserAdminComponent implements OnInit {
  public formatPhoneNumber = formatPhoneNumber;

  public REQS = FORM_INPUT_REQS;
  uiState = {
    waitOn: {init: true, action: false},
    editTarget: null,
    searchInput: "",
    view: "defaultView"
  };

  views = {
    defaultView: "defaultView",
    createUserForm: "createUserForm",
    editUserForm: "editUserForm"
  };

  securityGroups: Array<string> = [];
  securityGroupFilterOptions: Array<{ name: string, selected: boolean }> = [];
  users: Array<User> = [];
  searchForm: UntypedFormGroup = this.formBuilder.group({
    name: ["", Validators.required]
  });

  constructor(
    private userService: UserService,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.getAllUsers();
    this.getSecurityGroups();
  }

  // INITIALIZATION

  getAllUsers() {
    this.userService.getAll().subscribe({
      next: users => {
        this.users = users;
        (this.users || []).sort((a, b) => {
          const nameA = a.firstName.toLowerCase() + a.lastName.toLowerCase();
          const nameB = b.firstName.toLowerCase() + b.lastName.toLowerCase();
          return nameA > nameB ? 1 : nameA < nameB ? -1 : 0;
        });
        this.uiState.waitOn.init = false;
      },
      error: error => {
        this.uiState.waitOn.init = false;
        this.alertService.error(error);
      }
    });
  }

  getSecurityGroups() {
    this.userService.getSecurityGroups().subscribe({
      next: securityGroups => {
        this.securityGroups = securityGroups;
        this.securityGroupFilterOptions = securityGroups.map(group => {
          return {name: group, selected: true};
        });
      },
      error: error => {
        this.alertService.error(error);
      }
    });
  }

  // ACTIONS

  createUser(event) {
    if (confirm("Create User?") === false) {
      return;
    }
    const userData: User = event.userData;

    this.uiState.waitOn.action = true;
    this.userService.create(userData).subscribe({
      next: (user: User) => {
        this.users.unshift(user);
        this.alertService.success(`User \"${user.firstName}  ${user.lastName}\" Created.`);
        this.uiState.waitOn.action = false;
        this.onSelectView("defaultView");
      },
      error: error => {
        this.alertService.error(error);
        this.uiState.waitOn.action = false;
      }
    });
  }

  editUser(event) {
    if (confirm("Edit User?") === false) {
      return;
    }
    const userData: User = Object.assign({}, this.uiState.editTarget, event.userData);

    this.uiState.waitOn.action = true;
    this.userService.update(userData).subscribe({
      next: (user: User) => {
        Object.assign(this.uiState.editTarget, user);
        this.alertService.success(`User \"${user.firstName}  ${user.lastName}\" Updated.`);
        this.uiState.waitOn.action = false;
        this.onSelectView("defaultView");
      },
      error: error => {
        this.alertService.error(error);
        this.uiState.waitOn.action = false;
      }
    });
  }

  deleteUser(user: User) {
    if (confirm(`Delete User?\n${user.firstName + " " + user.lastName}`) === false) {
      return;
    }

    this.uiState.waitOn.action = true;
    this.userService.delete(user).subscribe({
      next: () => {
        let existingUserName: string;
        for (let i = 0; i < this.users.length; i++) {
          const existingUser = this.users[ i ];
          if (existingUser.id === user.id) {
            existingUserName = `${existingUser.firstName} ${existingUser.lastName}`;
            this.users.splice(i, 1);
            break;
          }
        }
        this.alertService.success(`User \"${existingUserName}\" Deleted.`);
        this.uiState.waitOn.action = false;
      },
      error: error => {
        this.alertService.error(error);
        this.uiState.waitOn.action = false;
      }
    });
  }

  toggleSelectSecurityGroup(index: number) {
    this.securityGroupFilterOptions = this.securityGroupFilterOptions.map((group, i) => {
      if (i === index) { group.selected = !group.selected; }
      return group;
    });
  }

  // UI CONTROL & RENDERING

  get waitOnInit(): boolean {
    return this.uiState.waitOn.init;
  }

  get waitOnAction(): boolean {
    return this.uiState.waitOn.action;
  }

  onSelectView(viewType: string) {
    this.uiState.view = this.views[ viewType ];
  }

  onSelectEditTarget(user: User) {
    this.uiState.editTarget = user;
    this.onSelectView("editUserForm");
  }

  onCancelEditTarget() {
    this.onSelectView("defaultView");
    this.uiState.editTarget = null;
  }

  get currentEditTarget(): User {
    return this.uiState.editTarget || null;
  }

  get noSecurityGroups(): boolean {
    return this.securityGroups.length === 0;
  }

  get filteredSecurityGroups(): string[] {
    return this.securityGroupFilterOptions.filter(group => group.selected).map(group => group.name);
  }

  onSearch(formValues) {
    const sanitizedInput = formValues.name.trim().toLowerCase();
    this.uiState.searchInput = sanitizedInput;
  }

  onClearSearch() {
    this.searchForm.patchValue({name: ""});
    this.uiState.searchInput = "";
  }

  searchMatch(user: User): boolean {
    const {searchInput} = this.uiState;
    if (searchInput === "") {
      return true;
    }

    const name = `${user.firstName} ${user.lastName}`.toLowerCase();
    return name.includes(searchInput);
  }

  searchActive(): boolean {
    return this.uiState.searchInput !== "";
  }

  currentView(viewType: string): boolean {
    return this.uiState.view === this.views[ viewType ];
  }


}
