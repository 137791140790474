import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { WARRANTY_SETTINGS } from "src/app/clearpath-module/components/warranty/warranty-config";
import { WarrantySettingOption } from "src/app/clearpath-module/models";

@Component({
  selector: "app-vehicle-needs-form",
  templateUrl: "./vehicle-needs-form.component.html",
  styleUrls: ["./vehicle-needs-form.component.scss"]
})
export class VehicleNeedsFormComponent implements OnInit {
  @Input() parentForm: UntypedFormGroup;
  @Input() change;

  constructor() { }

  ngOnInit() {
  }

  get milesPerYearOptions(): WarrantySettingOption[] {
    return WARRANTY_SETTINGS.milesDrivenPerYear;
  }

  get ownershipYearsOptions(): WarrantySettingOption[] {
    return WARRANTY_SETTINGS.plannedLengthOfOwnership;
  }
}
