import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Accessory } from "src/app/clearpath-module/models";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FORM_INPUT_REQS } from "src/app/app.config";

@Component({
  selector: "app-accessory-form",
  templateUrl: "./accessory-form.component.html",
  styleUrls: ["./accessory-form.component.scss"]
})
export class AccessoryFormComponent implements OnInit, OnDestroy {

  public REQS = FORM_INPUT_REQS;
  private unsubscribe$ = new Subject();
  @Input() accessory: Accessory;
  @Input() index: number;
  @Output() formChanges = new EventEmitter();
  accessoryForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    if (!this.accessory.details) {
      this.accessory.details = [];
    }
    this.initForm();
    this.watchForm();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initForm() {
    this.accessoryForm = this.formBuilder.group({
      name: [this.accessory.name, Validators.required],
      tagLine: [this.accessory.tagLine, Validators.required],
      price: [this.accessory.price, Validators.required],
      details: this.formBuilder.array(this.accessory.details, {updateOn: "blur"}),
      pbsName: [this.accessory.pbsName],
      pbsType: ["Protection"],
    });
  }

  watchForm() {
    this.accessoryForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(accessoryForm => {
        this.formChanges.emit(accessoryForm);
      });
  }

  createDetail() {
    this.accessory.details.push("");
    this.formChanges.emit(this.accessory);
    this.detailsAsFormArray.push(this.formBuilder.control(""));
  }

  removeDetail(index) {
    this.accessory.details.splice(index, 1);
    this.formChanges.emit(this.accessory);
    this.detailsAsFormArray.removeAt(index);
  }

  get detailsAsFormArray() {
    return this.accessoryForm.get("details") as UntypedFormArray;
  }

  get details(): UntypedFormArray {
    return this.accessoryForm.get("details").value;
  }


}
