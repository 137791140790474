import { Injectable } from "@angular/core";
import { FinanceRate, VehicleIncentive } from "src/app/settings-module/models";
import { DealIncentive } from "src/app/settings-module/models/incentives";
import { DealState } from "../../store/state";
import { DealService } from "./deal.service";
import { VehicleService } from "../vehicle.service";
import { combineLatest, Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { clone, pathOr } from "ramda";
import { FinanceOptions, LeaseOptions, Vehicle } from "../../models";

@Injectable({
  providedIn: "root"
})
export class DealIncentivesService {

  constructor(
    private dealService: DealService,
    private vehicleService: VehicleService
  ) { }

  getMatchingDealIncentive(vehicleIncentive: VehicleIncentive, deal: DealState): DealIncentive {
    const dealIncentives = deal.incentives || [];
    const incentive = dealIncentives.find(dealIncentive => (
      dealIncentive.incentiveGroupName === vehicleIncentive.incentiveGroupName
    ));
    return incentive || null;
  }

  onUpdateDealIncentive(updatedDealIncentive: DealIncentive, dealIncentives: DealIncentive[]) {
    dealIncentives = (dealIncentives || []);
    const index = dealIncentives.findIndex(dealIncentive => (
      dealIncentive.incentiveGroupName === updatedDealIncentive.incentiveGroupName
    ));
    if (index === -1) {
      dealIncentives.push(updatedDealIncentive);
    } else {
      if (this.incentiveIsEmpty(updatedDealIncentive)) {
        dealIncentives.splice(index, 1);
      } else {
        dealIncentives.splice(index, 1, updatedDealIncentive);
      }
    }
    return this.dealService.dispatchSetIncentives(dealIncentives);
  }

  incentiveIsEmpty(incentive: DealIncentive): boolean {
    return Object.keys(incentive).length <= 1;
  }

  assignIncentives(type: string) {
    return combineLatest([
      this.dealService.selectDeal(),
      this.vehicleService.selectVehicle()
    ])
      .pipe(take(1))
      .subscribe(([deal, vehicle]) => {

        vehicle.incentives.forEach((incentive: VehicleIncentive) => {
          let dealIncentive: DealIncentive;
          dealIncentive = {
            incentiveGroupName: incentive.incentiveGroupName,
            leaseOffer: {
              leaseOfferTerms: []
            },
            financeOffer: {
              financeRates: [],
              aprSubventionTiers: []
            },
            customerCashDisabled: false,
            bonusCashDisabled: false,
            leaseCashDisabled: true,
            financeCashDisabled: true,
            leaseOfferDisabled: false,
            financeOfferDisabled: false,
            bonusCash: 0,
            customerCash: 0,
            leaseCash: 0,
            financeCash: 0,
          };
          const {cashDetails, financeOffer, leaseOffer} = incentive.incentive;
          if (type === "leaseOffer") {
            dealIncentive.leaseOffer.leaseOfferTerms = leaseOffer.leaseOfferTerms;
          }
          if (type === "financeOffer") {
            dealIncentive.financeOffer = financeOffer;
          }
          if (type === "cashDetails") {
            dealIncentive.customerCash = cashDetails.customerCash;
            dealIncentive.bonusCash = cashDetails.bonusCash;
            dealIncentive.leaseCash = cashDetails.leaseCash;
            dealIncentive.financeCash = cashDetails.financeCash;
          }
          this.onUpdateDealIncentive(dealIncentive, deal.incentives);
        });

      });
  }

  checkIneligibleIncentives(incentive: DealIncentive, term: number) {
    const financeIncentiveRatesApplied = pathOr(
      false,
      ["financeOffer", "financeRates", length],
      incentive
    );
    if (financeIncentiveRatesApplied) {
      if (incentive) {
        const hasRateForTerm = incentive.financeOffer.financeRates
          .filter((rate: FinanceRate) => rate.term === term).length > 0;
        if (!hasRateForTerm) {
          incentive.financeOffer = {
            aprSubventionTiers: [],
            financeRates: []
          };
          incentive.customerCashDisabled = false;
        }
      }
    }
    return incentive;
  }

  assignDefaultIncentives({forceRefresh}: { forceRefresh?: boolean } = {}) {
    return combineLatest([
      this.dealService.selectDeal(),
      this.vehicleService.selectVehicle()
    ])
      .pipe(take(1))
      .subscribe(([deal, vehicle]) => {
        // set to default incentives is both vehicle and
        // deal incentives do not exist (fixes #1884)
        if (vehicle.incentives?.length === 0 && deal.incentives?.length === 0) {
          // console.log("Neither vehicle nor deal incentives exist, setting to default incentives");
          this.dealService.dispatchSetIncentives(null);
          this.dealService.dispatchIncentivesSet(false);
          return;
        }
        if (!deal.incentivesSet || forceRefresh) {
          // console.log("Deal incentives not set, setting to default incentives");
          if (this.shouldAssignIncentives(deal, vehicle)) {
            // console.log("Should assign incentives");
            if (vehicle.incentives.length === 0) {
              // console.log("Vehicle incentives are empty, setting to default incentives");
              this.dealService.dispatchSetIncentives(null);
              this.dealService.dispatchIncentivesSet(false);
              return;
            }
            // console.log("Vehicle incentives are not empty, setting to default incentives");
            vehicle.incentives.forEach((incentive: VehicleIncentive) => {
              let dealIncentive: DealIncentive;
              dealIncentive = {
                incentiveGroupName: incentive.incentiveGroupName,
                leaseOffer: {
                  leaseOfferTerms: []
                },
                financeOffer: {
                  financeRates: [],
                  aprSubventionTiers: [],
                },
                customerCash: 0,
                customerCashDisabled: false,
                bonusCash: 0,
                leaseCash: 0,
                financeCash: 0,
                bonusCashDisabled: false,
                leaseCashDisabled: true,
                financeCashDisabled: true,
                leaseOfferDisabled: false,
                financeOfferDisabled: false,
              };
              const {cashDetails, financeOffer, leaseOffer} = incentive.incentive;
              // dealIncentive.cashDetails = {
              //   customerCash: 0
              // };
              if (cashDetails.customerCash) {
                dealIncentive.customerCash = cashDetails.customerCash;
              }
              if (cashDetails.bonusCash) {
                dealIncentive.bonusCash = cashDetails.bonusCash;
              }
              if (cashDetails.leaseCash) {
                dealIncentive.leaseCash = cashDetails.leaseCash;
              }
              if (cashDetails.financeCash) {
                dealIncentive.financeCash = cashDetails.financeCash;
              }
              // #2137 - Used vehicles should not have any cash offers assigned to new deals, so let's disable them
              if (vehicle.isUsed) {
                dealIncentive.customerCashDisabled = true;
                dealIncentive.bonusCashDisabled = true;
                dealIncentive.leaseCashDisabled = true;
                dealIncentive.financeCashDisabled = true;
              }

              // if (financeOffer) {
              //   dealIncentive.financeOffer = financeOffer;
              // }
              dealIncentive.leaseOffer = incentive.incentive.leaseOffer;
              this.onUpdateDealIncentive(dealIncentive, deal.incentives);
              this.dealService.dispatchIncentivesSet(true);
            });
          }
        }
      });
  }

  shouldAssignIncentives(deal: DealState, vehicle: Vehicle) {
    if (!deal.incentives || !deal.incentives.length) {
      return true;
    }
    return vehicle.incentives || !vehicle.incentives.length ||
      vehicle.incentives.filter((incentive: DealIncentive) => {
        return incentive.financeOffer.aprSubventionTiers.length ||
          incentive.financeOffer.financeRates.length ||
          incentive.leaseOffer.leaseOfferTerms.length;
      }).length;
  }

  noDealTypeSelected(deal: DealState) {
    const dealTypeSelected = deal.financeOptions.financeSelected ||
      deal.financeOptions.cashPurchase ||
      deal.leaseOptions.leaseSelected;
    return !dealTypeSelected;
  }

  // used for visual display of incentives
  selectedCashIncentives$(showLease?: boolean): Observable<{ type: string, value: number }[]> {
    return combineLatest([
      this.dealService.selectDeal(),
      this.vehicleService.selectVehicle()
    ]).pipe(
      map(([deal, vehicle]) => {
          const offers = [];
          pathOr([], ["incentives"], deal).forEach(el => {
            // console.log("el", el)
            if (el.militaryRebate) { offers.push({type: "Military Rebate", value: el.militaryRebate}); }
            if (el.collegeRebate) { offers.push({type: "College Rebate", value: el.collegeRebate}); }

            if (vehicle.incentives.length > 0) {

              if (el.otherRebate) {
                offers.push({type: `${el.incentiveGroupName} Rebate`, value: el.otherRebate});
              }
              if (el.customerCash && !el.customerCashDisabled) {
                offers.push({type: "Customer Cash", value: el.customerCash});
              }

            }


            if (el.customerLoyalty500) {
              offers.push({type: "Finance Loyalty", value: el.customerLoyalty500});
            }

            if (el.customerLoyalty1000) {
              offers.push({type: "Lease Loyalty", value: el.customerLoyalty1000});
            }

            if (el.uberRebate) {
              offers.push({type: "Uber Rebate", value: el.uberRebate});
            }


            if (!showLease && !deal.leaseOptions.leaseSelected) {

              if (
                el.bonusCash &&
                !el.bonusCashDisabled &&
                !deal.financeOptions.incentiveSelected
              ) { offers.push({type: "Bonus Cash", value: el.bonusCash}); }

              if (el.financeCash && !el.financeCashDisabled) {
                offers.push({type: "Finance Cash", value: el.financeCash});
              }

              // Finance
              if ((deal.financeOptions.financeSelected ||
                this.noDealTypeSelected(deal)) && el.financeOffer) {
                if ((el.financeOffer.aprSubventionTiers || []).length) {
                  /*if (el.customerCash) {
                    const index = offers.findIndex(el => el.type === "Customer Cash");
                    if (index >= 0) {
                      offers.splice(index, 1);
                    }
                  }*/
                  if (!deal.financeOptions.aprSubventionDisabled)
                    offers.push({
                      type: "APR Subvention",
                      value: el.financeOffer.aprSubventionTiers[ 0 ]
                    });
                }
              }
            }

            //console.log("TT", el?.leaseOffer?.leaseOfferTerms?.length, deal.leaseOptions.selectedLeaseTerm)

            // Lease
            if (showLease || deal.leaseOptions.leaseSelected) {

              if (el.leaseCash && !el.leaseCashDisabled) {
                offers.push({type: "Lease Cash", value: el.leaseCash});
              }

              //console.log("LC", el?.leaseOffer, deal.leaseOptions.selectedLeaseTerm)

              if (!deal.leaseOptions.subventionCashDisabled) {
                if (el?.leaseOffer?.leaseOfferTerms?.length) {
                  if (deal.leaseOptions.selectedLeaseTerm) {
                    const selectedLeaseOfferTermIndex = el.leaseOffer.leaseOfferTerms
                      .findIndex(lot => lot.term === deal.leaseOptions.selectedLeaseTerm);
                    if (selectedLeaseOfferTermIndex >= 0 && !this.containsLeaseSubvention(offers)) {
                      offers.push({
                        type: "Lease Subvention",
                        value: deal.leaseOptions.subventionCashOverride ||
                          el.leaseOffer.leaseOfferTerms[ selectedLeaseOfferTermIndex ].leaseSubvention
                      });
                    }
                  }
                } else if (deal.leaseOptions.subventionCashOverride > 0 && !this.containsLeaseSubvention(offers)) {
                  offers.push({
                    type: "Lease Subvention",
                    value: deal.leaseOptions.subventionCashOverride
                  });
                }
              }
            }
          });

          return offers;
        }
      )
    );
  }

  containsLeaseSubvention = offers => {
    if (!offers) return false;
    let contains = false;
    offers.forEach(offer => {
      if (offer.type === "Lease Subvention") {
        contains = true;
      }
    });
    return contains;
  };

  cashIncentivesTotal$(
    {lease, term}: {
      lease?: boolean,
      term?: number
    } = {}) {
    return combineLatest([
      this.dealService.selectIncentives(),
      this.dealService.selectFinanceOptions(),
      this.dealService.selectLeaseOptions()
    ])
      .pipe(
        map(([incentives, financeOptions, leaseOptions]) => {
          const {adjustedPrice, incentivesApplied} = this.applyCashIncentives({
            price: 0,
            incentives,
            leaseOptions,
            leaseSelected: lease,
            financeOptions,
            term
          });
          // if (lease) {
          //     (incentives || []).forEach(incentive => {
          //       if (incentive.customerCash) {
          //         adjustedPrice -= incentive.customerCash;
          //       }
          //     });
          //   }
          return Math.abs(adjustedPrice);
        }));
  }

  // use 0 price to get an amount for total cash incentives
  applyCashIncentives({
                        price,
                        incentives,
                        leaseOptions,
                        leaseSelected,
                        financeOptions,
                        forceCashIncentive,
                        term
                      }: {
                        price: number,
                        incentives: DealIncentive[],
                        leaseOptions: LeaseOptions,
                        leaseSelected: boolean,
                        financeOptions: FinanceOptions,
                        forceCashIncentive?: boolean,
                        term?: number
                      }
  ) {
    const incentivesApplied = [];
    (incentives || []).forEach((incentive: DealIncentive) => {

      const leaseOfferTerms = pathOr(
        [],
        ["leaseOffer", "leaseOfferTerms"],
        incentive
      );
      const financeSubventionCash = pathOr(
        null,
        ["financeOffer", "aprSubventionTiers", 0],
        incentive
      );

      if (term) {
        incentive = clone(incentive);
        incentive = this.checkIneligibleIncentives(incentive, term);
      }

      if (
        financeOptions.financeSelected &&
        incentive.financeCash &&
        !this.incentiveApplied(["financeCash"], incentivesApplied) &&
        !incentive.financeCashDisabled
      ) {
        price -= incentive.financeCash;
        incentivesApplied.push("financeCash");
      }

      let leaseSubvention = 0;
      if (leaseSelected) {

        if (incentive.customerLoyalty1000 &&
          !this.incentiveApplied(["customerLoyalty1000"], incentivesApplied)) {
          price -= incentive.customerLoyalty1000;
          incentivesApplied.push("customerLoyalty1000");
        }

        if (
          incentive.leaseCash &&
          !this.incentiveApplied(["leaseCash"], incentivesApplied) &&
          !incentive.leaseCashDisabled
        ) {
          price -= incentive.leaseCash;
          incentivesApplied.push("leaseCash");
        }


        if (!leaseOptions.subventionCashDisabled) {
          if (leaseOptions.subventionCashOverride > 0) {
            price -= leaseOptions.subventionCashOverride;
            incentivesApplied.push("leaseSubvention");
          } else if (leaseOfferTerms?.length && (leaseOptions?.selectedLeaseTerm > 0 || term > 0)) {
            const leaseOffer = leaseOfferTerms.find(t => t.term === (term ? term : leaseOptions?.selectedLeaseTerm));
            if (leaseOffer && leaseOffer.leaseSubvention) {
              leaseSubvention = leaseOffer.leaseSubvention;
              price -= leaseSubvention;
            }
            incentivesApplied.push("leaseSubvention");
          }
        }
      }

      if (!leaseSelected || !leaseOptions.leaseSelected) {

        if (incentive.customerLoyalty500 &&
          !this.incentiveApplied(["customerLoyalty500"], incentivesApplied)) {
          price -= incentive.customerLoyalty500;
          incentivesApplied.push("customerLoyalty500");
        }

        if (financeSubventionCash && !financeOptions.cashPurchase && !financeOptions.aprSubventionDisabled) {
          price -= financeSubventionCash;
          incentivesApplied.push("financeSubvention");
        }
      }

      if (
        ((
            (!financeOptions.incentiveSelected || forceCashIncentive) &&
            !incentive.bonusCashDisabled
          ) &&
          !this.incentiveApplied(["bonusCash"], incentivesApplied))
      ) {
        price -= incentive.bonusCash || 0;
        incentivesApplied.push("bonusCash");
      }

      if (
        (incentive.customerCash &&
          !incentive.customerCashDisabled &&
          !this.incentiveApplied(["customerCash"], incentivesApplied))) {
        price -= incentive.customerCash || 0;
        incentivesApplied.push("customerCash");
      }

      if (
        incentive.militaryRebate &&
        !this.incentiveApplied(["militaryRebate", "collegeRebate"], incentivesApplied)
      ) {
        price -= incentive.militaryRebate;
        incentivesApplied.push("militaryRebate");
      }

      if (
        incentive.collegeRebate &&
        !this.incentiveApplied(["militaryRebate", "collegeRebate"], incentivesApplied)
      ) {
        price -= incentive.collegeRebate;
        incentivesApplied.push("collegeRebate");
      }

      if (
        incentive.otherRebate &&
        !this.incentiveApplied(["otherRebate"], incentivesApplied)
      ) {
        price -= incentive.otherRebate;
        incentivesApplied.push("otherRebate");
      }

      if (incentive.uberRebate &&
        !this.incentiveApplied(["uberRebate"], incentivesApplied)) {
        price -= incentive.uberRebate;
        incentivesApplied.push("uberRebate");
      }

    });
    return {
      adjustedPrice: price,
      incentivesApplied
    };
  }

  incentiveApplied(types: string[], incentivesApplied: string[]) {
    return types.filter(type => incentivesApplied.includes(type)).length > 1;
  }

  // getLeaseIncentive(incentives: VehicleIncentive[], deal: DealState) {
  //   incentives.forEach((incentive: VehicleIncentive) => {
  //     const leaseOfferIndex = incentive.incentive.leaseOffer.leaseOfferTerms.findIndex((leaseOfferTerm: LeaseOfferTerm) => {
  //       return leaseOfferTerm.term === deal.leaseOptions.selectedLeaseTerm;
  //     });
  //     if (leaseOfferIndex >= 0) {
  //       const leaseOffer = incentive.incentive.leaseOffer.leaseOfferTerms[leaseOfferIndex];
  //     }
  //   });
  // }

}
