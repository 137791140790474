<!-- Bootstrap Req: Navbar Class for Dropdown -->
<nav class="navbar dropdown inset-input-group">

  <div class="form-control form-control-sm rounded-pill app-bg-white">
    {{ selectedManager.title }}
  </div>

  <button class="app-btn-success rounded-circle inset-btn"
          type="button"
          data-toggle="dropdown">
    <i class="material-icons">arrow_drop_down</i>
  </button>

  <div class="dropdown-menu">
    <a [routerLink]="[]" class="dropdown-item" (click)="clearManager()">
      All Teams
    </a>

    <article *ngFor="let manager of managers">
      <div class="dropdown-divider"></div>
      <a [routerLink]="[]" class="dropdown-item" (click)="onSelectManager(manager)">
        {{ manager.title }}
      </a>
    </article>
  </div>

</nav>