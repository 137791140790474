import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { combineLatest, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CalculationService, DealService, VehicleService } from "src/app/clearpath-module/services";
import { Accessory, InsuranceProduct, InsuranceProductKeys, TermCost } from "src/app/clearpath-module/models";
import { DealDefaults } from "src/app/clearpath-module/services/deal/deal-defaults.service";
import { clone, pathOr } from "ramda";

const leaseFormName = "sales_manager_lease_insurance_products";
const financeFormName = "sales_manager_finance_insurance_products";

@Component({
  selector: "app-smw-insurance-products-form",
  templateUrl: "./smw-insurance-products-form.component.html",
  styleUrls: ["./smw-insurance-products-form.component.scss"]
})
export class SmwInsuranceProductsFormComponent implements OnDestroy, OnChanges, OnInit {
  @Input() dealDefaults: DealDefaults;
  @Input() type: string;
  @Input() selectedDealTerm: number;
  @Output() flagUnsavedChanges = new EventEmitter<boolean>();
  private dealInsuranceProducts: InsuranceProduct[];
  private verifiedInsuranceProducts: InsuranceProductKeys[] = [];
  private unVerifiedInsuranceProducts: InsuranceProductKeys[] = [];
  private unsubscribe$ = new Subject();
  private uiState = {
    formsDisabled: false,
    noAvailableProducts: true,
    vehInsuranceProducts: {},
  };
  totalInsuranceProductsPrice = 0;
  disabled: boolean;
  insuranceForm = this.fb.group({});
  verified: boolean;
  unSelectedProducts: InsuranceProduct[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private dealService: DealService,
    private vehicleService: VehicleService,
    private calculationService: CalculationService
  ) { }

  ngOnInit() {
    this.insuranceForm = this.fb.group(this.initInsuranceform());
    this.subToProductsAndOptions();
    this.subToVerifiedForms();
  }

  ngOnChanges(changes): void {
    this.insuranceForm = this.fb.group(this.initInsuranceform());
    this.subToProductsAndOptions();
    this.subToVerifiedForms();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // INITIALIZATION

  private subToProductsAndOptions() {

    combineLatest([
      this.dealService.selectFinanceOptions(),
      this.dealService.selectLeaseOptions()
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([financeOptions, leaseOptions]) => {

        if (this.type === "finance" && !financeOptions.financeSelected) {
          return this.disabled = true;
        }
        if (this.type === "lease" && !leaseOptions.leaseSelected) {
          return this.disabled = true;
        }
        if (this.type === "cash" && !financeOptions.cashPurchase) {
          return this.disabled = true;
        }
        return this.disabled = false;
      });

    combineLatest([
      this.vehicleService.selectInsuranceProducts(),
      this.dealService.selectDeal()
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([vehicleList, deal]) => {
        if (vehicleList) {
          this.processDealInsuranceProducts(deal.insuranceProducts, deal.accessories);
          this.syncProductAvailability(
            (vehicleList || [])
            // .map(this.insuranceService.sortProductTerms)
          );
        }
      });
  }

  productPrice(productId) {
    const product = this.product(productId);
    const {selectedTerm} = this.insuranceForm.get(product.productKey).value;
    const termCost: TermCost = pathOr(0, ["termCosts", selectedTerm], product);
    return termCost.priceOverride == null ? termCost.price : termCost.priceOverride;
  }

  private processDealInsuranceProducts(dealList: InsuranceProduct[], accessories: Accessory[]) {
    if (dealList && dealList.length) {
      this.dealInsuranceProducts = (dealList || []);
      this.syncProductFormData(this.dealInsuranceProducts);
      let totalInsuranceProductsPrice = 0;
      this.calculationService.filterProductsByType(this.dealInsuranceProducts, this.type)
        .forEach((product: InsuranceProduct) => {
          const price = pathOr(0, ["termCosts", product.selectedTerm, "priceOverride"], product) ||
            pathOr(0, ["termCosts", product.selectedTerm, "price"], product);
          totalInsuranceProductsPrice += price;
        });
      accessories.forEach((accessory: Accessory) => {
        if (accessory.name === "Clear Care Elite Appearance") {
          totalInsuranceProductsPrice += accessory.price;
        }
      });
      this.totalInsuranceProductsPrice = totalInsuranceProductsPrice;
    } else {
      this.totalInsuranceProductsPrice = 0;
    }
  }

  private subToVerifiedForms() {
    this.dealService.selectVerifiedForms()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((verifiedForms: string[]) => {
        this.verified = (Array.isArray(verifiedForms) ? verifiedForms : []).includes(this.formName);
      });
  }

  private syncProductAvailability(vehInsuranceProducts: InsuranceProduct[]) {
    this.uiState.noAvailableProducts = true;
    if (!vehInsuranceProducts) { return; }

    const productKeys = Object.keys(this.insuranceForm.value);

    // Check Form Against Products on Vehicle
    productKeys.forEach(productKey => {
      const productFormGroup = this.insuranceForm.get(productKey);
      const product = vehInsuranceProducts.find(ref => productKey === ref.productKey);

      if (!this.uiState.vehInsuranceProducts[ productKey ]) {
        productFormGroup.patchValue({
          accept: false,
          selectedTerm: 0,
          priceOverride: null
        });
      }

      if (product) { this.uiState.noAvailableProducts = false; }

      // Set Local Registry of Product Data
      this.uiState.vehInsuranceProducts[ productKey ] = product || false;
    });
  }

  private syncProductFormData(selectedProducts: InsuranceProduct[]) {

    if (!selectedProducts) { return; }

    const productKeys = Object.keys(this.insuranceForm.value);

    // Check Form Against Products Added to Deal
    productKeys.forEach(productKey => {
      const productAvailable = this.uiState.vehInsuranceProducts[ productKey ];
      if (!productAvailable) { return; }

      const productFormGroup = this.insuranceForm.get(productKey);
      const {selectedTerm: currentTerm} = productFormGroup.value;
      const selectedProduct = selectedProducts.find(ref => productKey === ref.productKey);
      const unSelectedProduct = this.unSelectedProducts.find(ref => productKey === ref.productKey);
      let selTerm: number;
      if (unSelectedProduct) {
        selTerm = unSelectedProduct.selectedTerm;
      } else {
        selTerm = selectedProduct ? selectedProduct.selectedTerm : parseInt(currentTerm, 10);
      }
      // selectedProduct ?
      //     selectedProduct.termCosts[selectedProduct.selectedTerm].priceOverride :
      //     null
      productFormGroup.patchValue({
        accept: selectedProduct ? true : false,
        selectedTerm: selTerm,
        priceOverride: selectedProduct && selectedProduct.termCosts[ selTerm ]?.priceOverride ?
          selectedProduct.termCosts[ selTerm ].priceOverride :
          null
      });
    });
  }

  private syncFormDisabledState(bool: boolean) {
    if (this.insuranceForm) {
      bool ? this.insuranceForm.disable() : this.insuranceForm.enable();
      this.uiState.formsDisabled = bool;
    }
  }

  initInsuranceform() {
    const formGroup = {
      [ InsuranceProductKeys.PPM ]: this.fb.group({
        accept: false,
        selectedTerm: [0],
        priceOverride: null
      }),
      [ InsuranceProductKeys.SELECT ]: this.fb.group({
        accept: false,
        selectedTerm: [0],
        priceOverride: null
      })
    };
    formGroup[ InsuranceProductKeys.GAP ] = this.fb.group({
      accept: false,
      selectedTerm: [0],
      priceOverride: null
    });
    formGroup[ InsuranceProductKeys.VSA ] = this.fb.group({
      accept: false,
      selectedTerm: [0],
      priceOverride: null
    });
    formGroup[ InsuranceProductKeys.EWU ] = this.fb.group({
      accept: false,
      selectedTerm: [0],
      priceOverride: null
    });
    return formGroup;
  }

  // UI CONTORL & RENDERING

  // selectedTerm(productId: string): number {
  //   const productKey = this.productKey(productId);
  //   const productIndex = this.dealInsuranceProducts.findIndex(p => {
  //     return p.productKey === productKey;
  //   });

  //   if (productIndex >= 0) {
  //     const closestTerm = this.dealService.Insurance.findClosestTerm(
  //       this.dealInsuranceProducts[productIndex], this.selectedDealTerm
  //     );
  //     const product = pathOr(null, ["dealInsuranceProducts", productIndex], this);
  //     if (product) {
  //       const termCostIndex = product
  //         .termCosts.findIndex((termCost: TermCost) => {
  //           return closestTerm.term === termCost.term;
  //         });
  //       return termCostIndex;
  //     }
  //     return pathOr(0, ["dealInsuranceProducts", product, "selectedTerm"], this);
  //   } else { return 0; }
  // }

  showVSA() {
    return this.type === "finance" || this.type === "cash";
  }

  productKey(productId: string): string {
    return InsuranceProductKeys[ productId ];
  }

  product(productId: string): InsuranceProduct {
    const productKey = this.productKey(productId);
    const vehicleInsuranceProduct: InsuranceProduct = clone(this.uiState.vehInsuranceProducts[ productKey ] || null);
    if (vehicleInsuranceProduct) {
      const foundIndex = (this.dealInsuranceProducts || []).findIndex(dip => {
        return dip.productKey === vehicleInsuranceProduct.productKey;
      });
      if (foundIndex > -1) {
        // if (this.type === "lease") {
        //   const closestTerm = this.dealService.Insurance.findClosestTerm(
        //     this.dealInsuranceProducts[foundIndex],
        //     this.selectedDealTerm
        //   );
        //   const termCostIndex = this.dealInsuranceProducts[foundIndex].termCosts.findIndex((termCost: TermCost) => {
        //     return closestTerm.term === termCost.term;
        //   });
        //   vehicleInsuranceProduct.selectedTerm = termCostIndex;
        // }
        vehicleInsuranceProduct.selectedTerm = this.dealInsuranceProducts[ foundIndex ].selectedTerm;
      }
    }
    return vehicleInsuranceProduct;
  }

  productName(productId: string): string {
    const product = this.product(productId);
    return product ? product.name : "No Product";
  }

  termCosts(productId: string): TermCost[] {
    const product = this.product(productId);
    return product ? product.termCosts : [];
  }

  checkBoxColor(productId: string): string {
    const productKey = this.productKey(productId);
    const {accept} = this.insuranceForm.get(productKey).value;
    if (accept) {
      return this.formsDisabled ? "app-bg-neutral" : "app-bg-success";
    }
    return "";
  }

  isUnlimitedMiles(miles: number) {
    return miles >= 999999;
  }

  get noAvailableProducts(): boolean {
    return this.uiState.noAvailableProducts;
  }

  get formsDisabled(): boolean {
    return this.uiState.formsDisabled;
  }

  get formName() {
    return this.type === "lease" ? leaseFormName : financeFormName;
  }

  // FORM ACTIONS & HELPERS

  pushUnverifiedProduct(productKey) {
    const unVerifiedIndex = this.unVerifiedInsuranceProducts
      .findIndex(key => productKey === key);
    if (unVerifiedIndex >= 0) {
      return;
    } else {
      if (productKey) {
        this.unVerifiedInsuranceProducts.push(productKey);
      }
    }
  }

  isVerified(product: InsuranceProduct) {
    return this.verifiedInsuranceProducts.includes(product.productKey as InsuranceProductKeys);
  }

  isUnverified(product: InsuranceProduct) {
    return this.unVerifiedInsuranceProducts.includes(product.productKey as InsuranceProductKeys);
  }

  checkSalesManagerVerified() {
    if (this.unVerifiedInsuranceProducts.length === 0) {
      this.dealService.dispatchPushVerifiedForm(this.formName);
    } else {
      this.dealService.dispatchRemoveVerifiedForm(this.formName);
    }
  }

  verifyInsuranceProduct = (product: InsuranceProduct) => {
    const productKey = product.productKey as InsuranceProductKeys;
    const unVerifiedIndex = (this.unVerifiedInsuranceProducts || []).findIndex(key => productKey === key);
    const verifiedIndex = this.verifiedInsuranceProducts ? this.verifiedInsuranceProducts.findIndex(key => productKey === key) : -1;
    if (!this.isVerified(product) && unVerifiedIndex >= 0) {
      this.verifiedInsuranceProducts.push(productKey);
      this.unVerifiedInsuranceProducts.splice(unVerifiedIndex, 1);
    } else {
      if (unVerifiedIndex === -1) {
        this.verifiedInsuranceProducts.push(productKey);
      } else {
        this.pushUnverifiedProduct(productKey);
        this.verifiedInsuranceProducts.splice(verifiedIndex, 1);
      }
    }
    this.checkSalesManagerVerified();
  }

  findProductIndex(productKey) {
    return this.dealDefaults.insuranceProducts ? this.dealDefaults.insuranceProducts.findIndex(defaultInsuranceProduct => {
      return defaultInsuranceProduct.productKey === productKey;
    }) : -1;
  }

  isDefault(product: Partial<InsuranceProduct>): boolean {
    if (this.dealDefaults) {
      const isDefault = (this.dealDefaults.insuranceProducts ? this.dealDefaults.insuranceProducts.findIndex(defaultInsuranceProduct => {
        if (defaultInsuranceProduct.productKey === product.productKey) {
          if (product.selectedTerm === defaultInsuranceProduct.selectedTerm) {
            return true;
          }
        }
      }) : -1) >= 0;
      return isDefault;
    }
  }

  flagChange = () => {
    this.flagUnsavedChanges.emit(true);
  }

  submitInsuranceProductsForm = () => {
    const productKeys = Object.keys(this.insuranceForm.value);
    const selectedProducts: InsuranceProduct[] = [];

    productKeys.forEach(productKey => {
      const formGroup = this.insuranceForm.get(productKey).value;
      const {accept, selectedTerm, priceOverride} = formGroup;
      if (accept) {
        const product: InsuranceProduct = this.uiState.vehInsuranceProducts[ productKey ];
        if (priceOverride) {
          const selectedTermInt: number = parseInt(selectedTerm, 10);
          product.termCosts[ selectedTermInt ].priceOverride = priceOverride;
          this.insuranceForm.get(productKey).get("priceOverride").patchValue(null);
        }
        const index = this.unSelectedProducts.findIndex(prod => {
          return prod.productKey === productKey;
        });
        let unSelectedProduct: InsuranceProduct;
        if (index >= 0) {
          unSelectedProduct = this.unSelectedProducts[ index ];
          this.unSelectedProducts.splice(index, 1);
        }
        if (unSelectedProduct) {
          selectedProducts.push(unSelectedProduct);
        } else {
          const selectedTermInt: number = parseInt(selectedTerm, 10);
          selectedProducts.push({...product, selectedTerm: selectedTermInt});
        }
      } else {
        const product = this.uiState.vehInsuranceProducts[ productKey ];
        const index = this.unSelectedProducts.findIndex(prod => {
          return prod.productKey === productKey;
        });
        if (index === -1) {
          const selectedTermInt: number = parseInt(selectedTerm, 10);
          this.unSelectedProducts.push({...product, selectedTerm: selectedTermInt});
        }
      }
    });
    this.flagChange();
    this.dealService.dealInsuranceService.dispatchSetInsuranceProducts(selectedProducts);
    this.insuranceForm.markAsPristine();
  }

}
