import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../services";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"]
})
export class ResetPasswordComponent implements OnInit {
  loading = false;

  resetPasswordForm: UntypedFormGroup = this.formBuilder.group({
    username: [""],
    password: ["", Validators.required],
    passwordConfirmation: ["", Validators.required],
    token: [""]
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private activeRoute: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.getActiveRouteToken();
  }

  getActiveRouteToken() {
    this.activeRoute.queryParams.subscribe(params => {
      this.resetPasswordForm.patchValue({username: params.email, token: params.token});
    });
  }

  async onSubmit() {
    this.loading = true;
    const {username, password, passwordConfirmation, token} = this.resetPasswordForm.value;
    await this.authService.resetPassword(username,password, passwordConfirmation, token);
    this.resetPasswordForm.patchValue({
      password: "",
      passwordConfirmation: ""
    });
    this.loading = false;
  }

  // FORM VALIDATION & HELPERS

  touchedInvalid(controlName: string): boolean {
    const touched = this.resetPasswordForm.controls[ controlName ].touched;
    const valid = this.resetPasswordForm.controls[ controlName ].valid;
    return touched && !valid;
  }
}
