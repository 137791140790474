import { Injectable } from "@angular/core";
import { InsuranceProduct, InsuranceProductKeys, Vehicle, VehicleNeeds } from "../../models";
import { combineLatest, Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { VehicleService } from "../vehicle.service";
import { clone, differenceWith, pathOr } from "ramda";
import * as dealActions from "../../store/actions/deal.actions";
import * as fromRoot from "../../store/state";
import * as dealSelectors from "../../store/selectors/deal";
import { Store } from "@ngrx/store";
import { TermCost } from "src/app/settings-module/models";
import { initialVehicleNeedsState } from "../../store/state/deal/vehicle-needs";
import { INSURANCE_PRODUCT_ORDER, InsuranceDefaultTerms } from "src/app/app.config";
import { CalculationUtilityService } from "../calculations/calculation-utility.service";

@Injectable({
  providedIn: "root"
})
export class DealInsuranceService {

  constructor(
    private vehicleService: VehicleService,
    private store: Store<fromRoot.DealState>,
    private calculationHelperService: CalculationUtilityService
  ) { }

  // DISPATCH

  dispatchSetInsuranceProducts(insuranceProducts: InsuranceProduct[]) {
    this.store.dispatch(dealActions.setInsuranceProducts({insuranceProducts}));
  }

  dispatchUpdateInsuranceProduct(insuranceProduct: InsuranceProduct) {
    this.store.dispatch(dealActions.updateInsuranceProduct({insuranceProduct}));
  }

  dispatchInsuranceProductsSet(set) {
    this.store.dispatch(dealActions.insuranceProductsSet({insuranceProductsSet: set}));
  }

  // SELECTORS

  selectInsuranceProducts(): Observable<InsuranceProduct[]> {
    return this.store.select(dealSelectors.selectInsuranceProducts);
  }

  selectDisabledInsuranceProducts(): Observable<InsuranceProduct[]> {
    return this.store.select(dealSelectors.selectDisabledInsuranceProducts);
  }

  selectFinanceInsuranceProducts(): Observable<InsuranceProduct[]> {
    return this.store.select(dealSelectors.selectInsuranceProducts).pipe(map(insuranceProducts => {
      return this.calculationHelperService.filterProductsByType(insuranceProducts, "finance");
    }));
  }

  selectLeaseInsuranceProducts(): Observable<InsuranceProduct[]> {
    return this.store.select(dealSelectors.selectInsuranceProducts).pipe(map(insuranceProducts => {
      return this.calculationHelperService.filterProductsByType(insuranceProducts, "lease");
    }));
  }

  selectCashInsuranceProducts(): Observable<InsuranceProduct[]> {
    return this.store.select(dealSelectors.selectInsuranceProducts).pipe(map(insuranceProducts => {
      return this.calculationHelperService.filterProductsByType(insuranceProducts, "cash");
    }));
  }

  selectVehicleNeeds(): Observable<VehicleNeeds> {
    return this.store.select(dealSelectors.selectVehicleNeeds);
  }


  // Business Processes

  configInitialProductSelections(
    products: InsuranceProduct[]
  ): InsuranceProduct[] {
    return this.configProductSelections(products, InsuranceDefaultTerms, initialVehicleNeedsState, true);
  }

  configProductSelections(
    products: InsuranceProduct[],
    setTerms: Partial<{ [prop in InsuranceProductKeys]: Partial<TermCost> }>,
    vehicleNeeds: VehicleNeeds,
    initialSelection: boolean
  ): InsuranceProduct[] {
    if (!products) { return; }
    return clone(products).map(product => {
      // product = this.sortProductTerms(product);
      let termCostIndex: number = null;

      const defaultTerm = pathOr(null, [product.productKey], setTerms);
      const prod = Object.assign({}, product);
      const closestTermCost = this.findClosestTerm(
        prod,
        vehicleNeeds.plannedLengthOfOwnership * 12,
        vehicleNeeds.milesDrivenPerYear * vehicleNeeds.plannedLengthOfOwnership
      );
      termCostIndex = product.termCosts ? product.termCosts.findIndex((termCost: TermCost) => {
        return closestTermCost.term === termCost.term && closestTermCost.miles === termCost.miles;
      }) : -1;
      product.selectedTerm = termCostIndex;
      if (initialSelection) {
        if (defaultTerm) {
          let foundTermCostIndex = product.termCosts ? product.termCosts.findIndex((tc: TermCost) => {
            return tc.term === defaultTerm.term && tc.miles === defaultTerm.miles;
          }) : -1;
          if (foundTermCostIndex === -1) {
            foundTermCostIndex = product.termCosts ? product.termCosts.findIndex((tc: TermCost) => {
              return tc.term === defaultTerm.term || tc.miles === defaultTerm.miles;
            }) : -1;
          }
          if (foundTermCostIndex >= 0) {
            termCostIndex = foundTermCostIndex;
          }
        }
        product.selectedTerm = termCostIndex;
      }
      return product;
    });
  }

  createInsuranceProductTerms(insuranceProducts: InsuranceProduct[], vehicleNeeds: VehicleNeeds):
    Partial<{ [prop in InsuranceProductKeys]: Partial<TermCost> }> {
    const setTerms: Partial<{ [prop in InsuranceProductKeys]: Partial<TermCost> }> = {};
    insuranceProducts.forEach((product: InsuranceProduct) => {
      setTerms[ product.productKey ] = {
        miles: vehicleNeeds.milesDrivenPerYear * vehicleNeeds.plannedLengthOfOwnership,
        term: vehicleNeeds.plannedLengthOfOwnership * 12
      };
    });
    return setTerms;
  }

  public enableUninitializedProduct(
    vehicleInsuranceProducts: InsuranceProduct[],
    product: InsuranceProduct,
    term?: number
  ) {
    const foundProduct = vehicleInsuranceProducts.find((vehInsuranceProduct: InsuranceProduct) =>
      product.productKey === vehInsuranceProduct.productKey);
    if (foundProduct) {
      if (!term) {
        this.submitInsuranceProduct(foundProduct, 0);
      } else {
        const foundProd = Object.assign({}, foundProduct);
        const closestTerm = this.findClosestTerm(
          foundProd,
          term
        );
        foundProduct.selectedTerm = foundProduct.termCosts.findIndex(
          termCost => termCost.term === closestTerm.term
        );
        this.submitInsuranceProduct(foundProduct, foundProduct.selectedTerm);
      }
    }
  }

  setGapToFinanceTerm$(term) {
    return this.selectInsuranceProducts()
      .pipe(
        take(1),
        map((insuranceProducts: InsuranceProduct[]) => {
          let edited = false;
          const editedInsuranceProducts = clone((insuranceProducts || []))
            .map((product: InsuranceProduct) => {
              return product;
              /*if (product.productKey.toLowerCase() === "gap") {
                const selectedTermIndex = product.termCosts.findIndex((termCost: TermCost) => termCost.term === term);
                if (selectedTermIndex >= 0) {
                  product.selectedTerm = selectedTermIndex;
                  edited = true;
                  return product;
                } else {
                  const prod = Object.assign({}, product);
                  const closestTerm = this.findClosestTerm(prod, term);
                  const selectedTermIndex = product.termCosts.findIndex((termCost: TermCost) => {
                    return closestTerm.term === termCost.term;
                  });
                  product.selectedTerm = selectedTermIndex;
                  return product;
                }
              } else {
                return product;
              }*/
            });
          if (edited) {
            return editedInsuranceProducts;
          } else {
            return insuranceProducts;
          }
        })
      );
  }

  orderInsuranceProducts(products: InsuranceProduct[]) {
    products.sort((a: InsuranceProduct, b: InsuranceProduct) => {
      if (
        INSURANCE_PRODUCT_ORDER.indexOf(a.productKey) >
        INSURANCE_PRODUCT_ORDER.indexOf(b.productKey)
      ) {
        return 1;
      } else {
        return -1;
      }

    });

    return products;
  }

  public submitInsuranceProduct(product: InsuranceProduct, termCostIndex: number) {
    this.selectInsuranceProducts()
      .pipe(take(1))
      .subscribe((data: InsuranceProduct[]) => {
        const selectedProducts: InsuranceProduct[] = clone(data) || [];
        const existingProduct = selectedProducts.find(
          existing => existing.productKey === product.productKey
        );
        const existingProductIndex = selectedProducts.findIndex(
          existing => existing.productKey === product.productKey
        );

        if (existingProduct) {
          selectedProducts[ existingProductIndex ] = {
            ...existingProduct, selectedTerm: termCostIndex
          };
        }

        if (!existingProduct) {
          const newProduct = {...product};
          newProduct.selectedTerm = termCostIndex;
          selectedProducts.push(newProduct);
        }

        this.dispatchSetInsuranceProducts(selectedProducts);
      });
  }

  public filterDealInsuranceProductsForStockSwitch(): Observable<InsuranceProduct[]> {
    return combineLatest([
      this.vehicleService.selectVehicle(),
      this.selectVehicleNeeds()
    ]).pipe(
      map(
        ([vehicle, vehicleNeeds]:
           [Vehicle, VehicleNeeds]) => {
          const vehicleInsuranceProducts = vehicle.insuranceProducts;
          return vehicleInsuranceProducts ? vehicleInsuranceProducts.map((product: InsuranceProduct) => {
            const closestTerm = this.findClosestTerm(
              product,
              vehicleNeeds.plannedLengthOfOwnership * 12,
              vehicleNeeds.milesDrivenPerYear * vehicleNeeds.plannedLengthOfOwnership
            );
            const selectedTerm = product.termCosts.findIndex(
              termCost => {
                return pathOr(null, ["term"], termCost) === closestTerm.term;
              }
            );
            product.selectedTerm = selectedTerm > -1 ? selectedTerm : 0;
            return product;
          }) : [];
        })
    );
  }

  public removeInsuranceProduct(product: InsuranceProduct) {
    this.selectInsuranceProducts()
      .pipe(take(1))
      .subscribe((data: InsuranceProduct[]) => {
        const selectedProducts: InsuranceProduct[] = clone(data) || [];
        const {productKey} = product;

        for (let i = 0; i < selectedProducts.length; i++) {
          const productExists = productKey === selectedProducts[ i ].productKey;

          if (productExists) {
            selectedProducts.splice(i, 1);
            break;
          }
        }

        this.dispatchSetInsuranceProducts(selectedProducts);
      });
  }

  selectTerm(product: InsuranceProduct, term: number) {
    const prod = Object.assign({}, product);
    const closestTerm = this.findClosestTerm(
      prod,
      term
    );
    const selectedTerm = prod.termCosts.findIndex(
      termCost => {
        return pathOr(null, ["term"], termCost) === closestTerm.term;
      }
    );
    prod.selectedTerm = selectedTerm > -1 ? selectedTerm : 0;
    return prod;
  }

  findClosestTerm(
    product: InsuranceProduct,
    leaseTerm: number,
    mileage?: number
  ): TermCost {
    product = clone(product);
    const matchingTermResults = product.termCosts.filter(termCost => {
      if (leaseTerm) {
        if (termCost.term >= leaseTerm) {
          return true;
        }
      }
    }).sort((a, b) => a.term - b.term);
    if (matchingTermResults.length === 0) {
      const sortedTermCosts = product.termCosts.sort((a, b) => a.term - b.term);
      return sortedTermCosts[ sortedTermCosts.length - 1 ];
    } else {
      if (product.productKey === "GAP") {
        return matchingTermResults[ 0 ];
      }
    }
    if (!matchingTermResults[ 0 ]) {
      return product.termCosts[ product.termCosts.length - 1 ];
    } else {
      if (mileage) {
        if (matchingTermResults.length === 0) {
          return product.termCosts[ product.termCosts.length - 1 ];
        } else {
          const matchingMileageResult: TermCost = matchingTermResults.find((termCost: TermCost) => {
            return termCost.miles === mileage;
          });
          if (matchingMileageResult) {
            return matchingMileageResult;
          } else {
            const mileageAboveTerms = matchingTermResults.filter((termCost: TermCost) => {
              return termCost.miles >= mileage;
            });
            if (mileageAboveTerms.length) {
              return mileageAboveTerms[ 0 ];
            } else {
              return matchingTermResults[ matchingTermResults.length - 1 ];
            }
          }
        }
      } else {
        return matchingTermResults[ 0 ];
      }
    }
  }

  sortProductTerms(product: InsuranceProduct): InsuranceProduct {
    product.termCosts = product.termCosts.sort((a, b) => a.term - b.term);
    return product;
  }

  setHardcodedTerms(insuranceProducts, term) {
    return insuranceProducts ? insuranceProducts.map((insuranceProduct: InsuranceProduct) =>
      this.setHardcodedTerm(insuranceProduct, term)) : [];
  }

  // setClosestTerms(insuranceProducts, term): InsuranceProduct[] {
  //   return insuranceProducts.map((insuranceProduct: InsuranceProduct) => {
  //     const closestTerm = this.findClosestTerm(insuranceProduct, term);
  //     insuranceProduct.selectedTerm = insuranceProduct.termCosts.findIndex(
  //       termCost => termCost.term === closestTerm.term
  //     );
  //     return insuranceProduct;
  //   });
  // }
  setHardcodedTerm(insuranceProduct: InsuranceProduct, term: number) {
    /*const product = Object.assign({}, insuranceProduct);
    if (product.productKey.toLowerCase() === "gap") {
      const prod = Object.assign({}, product);
      const closestTerm = this.findClosestTerm(
        prod,
        term
      );
      const selectedTerm = product.termCosts.findIndex((termCost: TermCost) => {
        return closestTerm.term === termCost.term;
      });
      product.selectedTerm = selectedTerm > -1 ? selectedTerm : 0;
      return product;
    }
    return product;*/
    return Object.assign({}, insuranceProduct);
  }

  declinedProducts$(): Observable<InsuranceProduct[]> {
    return combineLatest([
      this.selectInsuranceProducts(),
      this.vehicleService.selectInsuranceProducts()
    ]).pipe(
      map(([
             dealInsuranceProducts,
             vehicleInsuranceProducts
           ]) => {
        return differenceWith(
          (vehicleInsuranceProduct, dealInsuranceProduct) => {
            return vehicleInsuranceProduct.productKey === dealInsuranceProduct.productKey;
          },
          vehicleInsuranceProducts,
          dealInsuranceProducts
        );
      })
    );
  }

  parseDateToISO(date) {
    const dateParsed = new Date(Date.parse(date));
    if (dateParsed.toISOString() === date) {
      return date;
    } else {
      return new Date().toISOString();
    }
  }

}
