import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from "@angular/forms";
import { pathOr } from "ramda";

@Component({
  selector: 'app-simple-input-dropdown',
  templateUrl: './simple-input-dropdown.component.html',
  styleUrls: ['./simple-input-dropdown.component.scss']
})
export class SimpleInputDropdownComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild("main", {static: false}) main: ElementRef;
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();

  @ViewChild("input", {static: false}) set content(input: ElementRef) {
    if (input) { // initially setter gets called with undefined
      this.inputPlaceHolder = input;
      if (this.waitingForFocus) {
        setTimeout(() => {
          this.inputPlaceHolder.nativeElement.focus();
          //this.inputPlaceHolder.nativeElement.select();
        }, 100);
      }
    }
  }

   onChange($event) {
    //console.log($event)
    this.valueChanged.emit($event)
}

  private inputPlaceHolder: ElementRef;

  constructor() { }

  @Input() parentForm: UntypedFormGroup;
  @Input() forceUpdate: any;
  @Input() formCtrlName: string;
  @Input() fieldValue;
  @Input() name: string;
  @Input() type: string;
  @Input() pipe: string;
  @Input() maxLength: number;
  @Input() minLength: number;
  @Input() required: boolean;
  @Input() additionalFieldValue;
  @Input() disabled: boolean;
  @Input() readOnly: boolean;
  @Input() isBold = false;
  @Input() dropdownValues = []

  waitingForFocus = false;
  editing = false;
  value;
  @Input() blur = () => { };
  @Input() keydown = () => { };

  ngOnInit() {
  }

  ngAfterViewInit() {
    this._init();
  }

  ngOnChanges() {
    this._init();
  }

  _init() {
    const value = pathOr(null, ["parentForm", "value", this.formCtrlName], this);
    if (value !== null) {
      this.fieldValue = value;
    }
    if (this.fieldValue === null) {
      this.fieldValue = "";
    }
    if (this.fieldValue === undefined) {
      if (this.type === "text") {
        this.fieldValue = "";
      }
      if (this.type === "number") {
        this.fieldValue = 0;
      }
    }
    if (this.type === "number" && isNaN(parseFloat(this.fieldValue))) {
      this.fieldValue = 0;
    }
  }

  get isDisabled() {
    if (this.disabled) { return true; }
    if (!this.parentForm) { return true; }
    const control = this.parentForm.get(this.formCtrlName);
    if (control) {
      return control.disabled;
    }
  }

  editValue() {
    this.editing = true;
    if (this.inputPlaceHolder?.nativeElement) {
      setTimeout(() => {
        this.inputPlaceHolder.nativeElement.focus();
        //this.inputPlaceHolder.nativeElement.select();
      }, 100);
    } else {
      this.waitingForFocus = true;
    }
  }

  focusAction() {
    if (this.inputPlaceHolder?.nativeElement) {
      this.inputPlaceHolder.nativeElement.focus();
      //this.inputPlaceHolder.nativeElement.select();
    }
    this.editing = true;
  }

  blurAction() {
    this.editing = false;
    this._init();
    this.blur();
  }

  touchedInvalid(): boolean {
    if (!this.parentForm) { return; }
    const control = this.parentForm.get(this.formCtrlName);
    if (control) {
      return control.touched && control.invalid;
    }
  }

  showForm() {
    // if (this.formCtrlName === "acquisitionFee") {
    //   console.log("type:");
    // }
    let show = false;
    const value = pathOr(null, ["parentForm", "value", this.formCtrlName], this) || this.fieldValue;
    if (this.parentForm) {
      if (this.editing) {
        show = true;
      }
      if (this.type === "text" && (value === "" || value === null)) {
        show = true;
      }
      if (this.type === "date" && (value === "" || value === null)) {
        show = true;
      }
      if (this.type === "number" && value === null || value === undefined) {
        show = true;
      }
      if (this.type === "string" && (value === "" || value === null)) {
        show = true;
      }
    }
    return show;
  }

  hasAdditionalFieldValue() {
    return this.additionalFieldValue !== undefined;
  }

  onMainFocus() {
    this.editValue();
  }

  onMainFocusOut() {
    this.editing = false;
  }

  onInputFocusOut() {
    this.editing = false;
  }

  isDefaultDate = (d: any): boolean => {
    if (d === "" || d === undefined || d === null || d === "0001-01-01T00:00:00Z") {return true; }
    if(!(d instanceof Date))
      d = new Date(d);
    if (!(typeof d.getFullYear === "function") || d.getTime() === 0) {return true; }
    return d.getFullYear()===0;
  }

  formatDateString(d: any): string {
    if (this.isDefaultDate(d)) {
      return "MM/DD/YYYY";
    } else if (d instanceof Date) {
      const [year, month, day] = d.toISOString().substr(0,10).split("-");
      return [month, day, year].join("/");
    }
    const d2 = new Date(d.substr(0, 10));
    const [year, month, day] = d2.toISOString().substr(0, 10).split("-");
    return [month, day, year].join("/");
  }
}
