import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { InsuranceProduct, TermCost } from "../../../models";
import { FORM_INPUT_REQS } from "src/app/app.config";
import { MarkupMatrixItem } from "src/app/settings-module/models/insurance";

@Component({
  selector: "app-insurance-product-form",
  templateUrl: "./insurance-product-form.component.html",
  styleUrls: ["./insurance-product-form.component.scss"]
})
export class InsuranceProductFormComponent {
  @Input() set product(product: InsuranceProduct) { this.initializeProductForm(product); }

  @Input() isNewProduct = false;
  @Input() waitOnAction = false;
  @Output() submitProduct = new EventEmitter<InsuranceProduct>();
  @Output() removeProduct = new EventEmitter();

  public REQS = FORM_INPUT_REQS;
  productForm: UntypedFormGroup = this.formBuilder.group({
    name: ["", Validators.required],
    provider: ["", Validators.required],
    providerName: ["", Validators.required],
    linkUrl: [""],
    linkIconUrl: [""],
    selectedTerm: [0],
    linkDescription: [""],
    iconUrl: [""],
    productKey: ["", Validators.required],
    productTerms: [""],
    minYear: [null, [
      Validators.required,
      Validators.min(1900),
      Validators.max(2050)
    ]],
    maxOdometer: [null, [
      Validators.max(2000000)
    ]],
    guidelines: [""],
    tagLine: [""],
    preLoadNew: [false],
    preLoadUsed: [false],
    preLoadCertUsed: [false],
    preLoadCar: [false],
    preLoadTruck: [false],
    preLoadSuv: [false],
    preLoadVan: [false],
    preLoadOther: [false],
    sellWithNew: [false],
    sellWithUsed: [false],
    sellWithCertUsed: [false],
    details: new UntypedFormArray([]),
    excludedMakes: new UntypedFormArray([]),
    termCosts: new UntypedFormArray([]),
    markupMatrix: new UntypedFormArray([]),
    flatMarkup: [0],
    flatPrice: [0],
    pbsName: [""],
    pbsType: [""]
  });

  constructor(private formBuilder: UntypedFormBuilder) { }

  // INITIALIZATION

  private initializeProductForm(product: InsuranceProduct) {
    this.productForm.patchValue({
      name: product.name,
      provider: product.provider,
      providerName: product.providerName,
      linkUrl: product.link.linkUrl,
      linkIconUrl: product.link.iconUrl,
      linkDescription: product.link.description,
      iconUrl: product.iconUrl,
      productKey: product.productKey,
      productTerms: product.productTerms,
      minYear: product.minYear,
      maxOdometer: product.maxOdometer,
      guidelines: product.guidelines,
      tagLine: product.tagLine,
      preLoadNew: product.preLoadNew || false,
      preLoadUsed: product.preLoadUsed || false,
      preLoadCertUsed: product.preLoadCertUsed || false,
      preLoadCar: product.preLoadCar || false,
      preLoadTruck: product.preLoadTruck || false,
      preLoadSuv: product.preLoadSuv || false,
      preLoadVan: product.preLoadVan || false,
      preLoadOther: product.preLoadVan || false,
      sellWithNew: product.sellWithNew,
      sellWithUsed: product.sellWithUsed,
      sellWithCertUsed: product.sellWithCertUsed,
      pbsName: product.pbsName,
      pbsType: product.pbsType,
      flatMarkup: product.flatMarkup,
      flatPrice: product.flatPrice
    });
    this.unpackProductDetails(product.details);
    this.unpackExcludedMakes(product.excludedMakes || []);
    this.unpackProductTermCosts(product.termCosts);
    this.unpackProductMarkup(product.markupMatrix);
    this.productForm.markAsPristine();
  }

  private unpackProductDetails(details: string[]) {
    const formArray = this.productForm.get("details") as UntypedFormArray;
    formArray.clear();
    details.forEach(detail => { this.addDetailControl(detail); });
  }

  private unpackExcludedMakes(excludedMakes: string[]) {
    const formArray = this.productForm.get("excludedMakes") as UntypedFormArray;
    formArray.clear();
    excludedMakes.forEach(detail => { this.addExcludedMakeControl(detail); });
  }

  private unpackProductTermCosts(termCosts: TermCost[]) {
    const formArray = this.productForm.get("termCosts") as UntypedFormArray;
    formArray.clear();
    termCosts.forEach(termCost => { this.addTermCostControl(termCost); });
  }

  private unpackProductMarkup(markupMatrix: MarkupMatrixItem[]) {
    const formArray = this.productForm.get("markupMatrix") as UntypedFormArray;
    formArray.clear();
    (markupMatrix || []).forEach((item: MarkupMatrixItem) => { this.addProductMarkupControl(item); });
  }

  // ACTIONS

  onRemoveProduct() {
    if (confirm("Remove Product?")) { this.removeProduct.emit(); }
  }

  onSubmitProductForm() {
    const formData = this.productForm.value;

    // Extract Form Array Values
    const details: string[] = this.productForm.get("details").value;
    const excludedMakes: string[] = this.productForm.get("excludedMakes").value;
    const termCosts: TermCost[] = this.productForm.get("termCosts").value;
    const markupMatrix: MarkupMatrixItem[] = this.productForm.get("markupMatrix").value;

    const product: InsuranceProduct = {
      name: formData.name,
      provider: formData.provider,
      providerName: formData.providerName,
      link: {
        iconUrl: formData.linkIconUrl,
        linkUrl: formData.linkUrl,
        description: formData.linkDescription
      },
      selectedTerm: formData.selectedTerm,
      iconUrl: formData.iconUrl,
      productKey: formData.productKey,
      productTerms: formData.productTerms,
      minYear: formData.minYear,
      maxOdometer: formData.maxOdometer,
      guidelines: formData.guidelines,
      tagLine: formData.tagLine,
      preLoadNew: formData.preLoadNew,
      preLoadUsed: formData.preLoadUsed,
      preLoadCertUsed: formData.preLoadCertUsed,
      preLoadCar: formData.preLoadCar,
      preLoadTruck: formData.preLoadTruck,
      preLoadSuv: formData.preLoadSuv,
      preLoadVan: formData.preLoadVan,
      preLoadOther: formData.preLoadOther,
      sellWithNew: formData.sellWithNew,
      sellWithUsed: formData.sellWithUsed,
      sellWithCertUsed: formData.sellWithCertUsed,
      details,
      excludedMakes,
      termCosts,
      markupMatrix,
      pbsName: formData.pbsName,
      pbsType: formData.pbsType,
      flatMarkup: formData.flatMarkup,
      flatPrice: formData.flatPrice
    };

    this.submitProduct.emit(product);
  }

  // UI CONTROL

  onAddExcludedMake() {
    this.addExcludedMakeControl();
    this.productForm.markAsDirty();
  }

  onAddDetail() {
    this.addDetailControl();
    this.productForm.markAsDirty();
  }

  onAddTermCost() {
    this.addTermCostControl();
    this.productForm.markAsDirty();
  }

  onAddMarkupMatrixItem() {
    this.addProductMarkupControl();
    this.productForm.markAsDirty();
  }

  onRemoveDetail(index: number) {
    const formArray = this.productForm.get("details") as UntypedFormArray;
    formArray.removeAt(index);
    this.productForm.markAsDirty();
  }

  onRemoveExcludedMake(index: number) {
    const formArray = this.productForm.get("excludedMakes") as UntypedFormArray;
    formArray.removeAt(index);
    this.productForm.markAsDirty();
  }

  onRemoveTermCost(index: number) {
    const formArray = this.productForm.get("termCosts") as UntypedFormArray;
    formArray.removeAt(index);
    this.productForm.markAsDirty();
  }

  onRemoveMarkupMatrixItem(index: number) {
    const formArray = this.productForm.get("markupMatrix") as UntypedFormArray;
    formArray.removeAt(index);
    this.productForm.markAsDirty();
  }

  // FORM VALIDATION AND HELPERS

  get detailControls() {
    const formArray = this.productForm.get("details") as UntypedFormArray;
    return formArray.controls;
  }

  get excludedMakeControls() {
    const formArray = this.productForm.get("excludedMakes") as UntypedFormArray;
    return formArray.controls;
  }

  get termCostControls() {
    const formArray = this.productForm.get("termCosts") as UntypedFormArray;
    return formArray.controls;
  }

  get markupMatrixControls() {
    const formArray = this.productForm.get("markupMatrix") as UntypedFormArray;
    return formArray.controls;
  }

  private addDetailControl(value?: string) {
    const formArray = this.productForm.get("details") as UntypedFormArray;
    const detail = value || "";
    const control = new UntypedFormControl(detail, Validators.required);
    formArray.push(control);
  }

  private addExcludedMakeControl(value?: string) {
    const formArray = this.productForm.get("excludedMakes") as UntypedFormArray;
    const excludedMake = value || "";
    const control = new UntypedFormControl(excludedMake, Validators.required);
    formArray.push(control);
  }

  private addTermCostControl(termCost?: TermCost) {
    const formArray = this.productForm.get("termCosts") as UntypedFormArray;
    const formGroup = this.formBuilder.group({
      term: [0, [Validators.required, Validators.min(0)]],
      miles: [0, [Validators.required, Validators.min(0)]],
      price: [0, [Validators.required, Validators.min(0)]],
      cost: [0, [Validators.required, Validators.min(0)]],
      providerId: ["", Validators.required],
    });

    if (termCost) {
      const {term, miles, price, cost, providerId} = termCost;
      formGroup.patchValue({term, miles, price, cost, providerId});
    }

    formArray.push(formGroup);
  }

  private addProductMarkupControl(markupMatrixItem?: MarkupMatrixItem) {
    const formArray = this.productForm.get("markupMatrix") as UntypedFormArray;
    const formGroup = this.formBuilder.group({
      termMin: [0, [Validators.required, Validators.min(0)]],
      termMax: [0, [Validators.required, Validators.min(0)]],
      newMarkup: [0, [Validators.required, Validators.min(0)]],
      usedMarkup: [0, [Validators.required, Validators.min(0)]],
      certMarkup: [0, [Validators.required, Validators.min(0)]]
    });

    if (markupMatrixItem) {
      const {termMin, termMax, newMarkup, usedMarkup, certMarkup} = markupMatrixItem;
      formGroup.patchValue({termMin, termMax, newMarkup, usedMarkup, certMarkup});
    }

    formArray.push(formGroup);
  }

  isEthos() {
    return String(this.productForm.value.provider).toLowerCase() === "ethos";
  }

  touchedInvalid(controlName: string): boolean {
    const control = this.productForm.get(controlName);
    return control.touched && control.invalid;
  }

  touchedInvalidExcludedMake(makeIndex: number): boolean {
    const control = this.productForm.get(["excludedMakes", makeIndex]);
    return control.touched && control.invalid;
  }

  touchedInvalidDetail(detailIndex: number): boolean {
    const control = this.productForm.get(["details", detailIndex]);
    return control.touched && control.invalid;
  }

  touchedInvalidTermCost(termCostIndex: number, controlName: string): boolean {
    const control = this.productForm.get(["termCosts", termCostIndex, controlName]);
    return control.touched && control.invalid;
  }

  touchedInvalidMarkupMatrix(index: number, controlName: string): boolean {
    const control = this.productForm.get(["markupMatrix", index, controlName]);
    return control.touched && control.invalid;
  }

}
