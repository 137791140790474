import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Buyer } from "src/app/clearpath-module/models";

@Component({
  selector: "app-trade-owner",
  templateUrl: "./trade-owner.component.html",
  styleUrls: ["./trade-owner.component.scss"]
})
export class TradeOwnerComponent implements OnInit, OnChanges, OnDestroy {
  uiState = {birthdate: null};
  @Output() flagUnsavedChanges = new EventEmitter<boolean>();
  @Output() submitSalesManagerTradeInOwner = new EventEmitter<Partial<Buyer>>();
  forceChildFormUpdate = 0;
  tradeOwnerForm: UntypedFormGroup = this.formBuilder.group({
    firstName: [""],
    middleName: [""],
    lastName: [""],
    street: [""],
    city: [""],
    county: [""],
    state: [""],
    zip: [""],
    phone: [""],
    email: [""],
    birthdate: [""],
  });

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnDestroy() {
    // this.unsubscribe$.next();
    // this.unsubscribe$.complete();
  }

  ngOnInit() {
    // this.subToDeal();
  }

  ngOnChanges() {
    // this.subToDeal();
  }

  @Input()
  set tradeInOwner(tradeInOwner: Buyer) {
    if (!tradeInOwner) { return; }
    const birthdate = (tradeInOwner.birthdate) ? new Date(tradeInOwner.birthdate) : "";
    this.uiState.birthdate = birthdate === "" ? "" : (birthdate.getMonth() + 1) + "/" + birthdate.getDate() + "/" + birthdate.getFullYear();
    this.tradeOwnerForm.patchValue({
      firstName: tradeInOwner.firstName,
      middleName: tradeInOwner.middleName,
      lastName: tradeInOwner.lastName,
      street: tradeInOwner.street,
      city: tradeInOwner.city,
      county: tradeInOwner.county,
      state: tradeInOwner.state,
      zip: tradeInOwner.zip,
      phone: tradeInOwner.phone,
      email: tradeInOwner.email,
      birthdate: tradeInOwner.birthdate,
    });
    this.forceChildFormUpdate = Math.random();
  }

  autoSubmitForm = () => {
    const {pristine, invalid} = this.tradeOwnerForm;
    if (pristine || invalid) { return; }

    const owner: Partial<Buyer> = {
      firstName: this.tradeOwnerForm.value.firstName,
      middleName: this.tradeOwnerForm.value.middleName,
      lastName: this.tradeOwnerForm.value.lastName,
      street: this.tradeOwnerForm.value.street,
      city: this.tradeOwnerForm.value.city,
      county: this.tradeOwnerForm.value.county,
      state: this.tradeOwnerForm.value.state,
      zip: this.tradeOwnerForm.value.zip,
      phone: this.tradeOwnerForm.value.phone,
      email: this.tradeOwnerForm.value.email,
      birthdate: this.tradeOwnerForm.value.birthdate,
    };

    this.submitSalesManagerTradeInOwner.emit(owner);
    this.tradeOwnerForm.markAsUntouched();
    this.tradeOwnerForm.markAsPristine();
    this.flagChange();
  }

  flagChange = () => {
    this.flagUnsavedChanges.emit(true);
  }

}
