import { Component, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { FORM_INPUT_REQS } from "../../../../../../app.config";

@Component({
  selector: "app-trade-in-form",
  templateUrl: "./trade-in-form.component.html",
  styleUrls: ["./trade-in-form.component.scss"]
})
export class TradeInFormComponent {
  @Input() formLabel = "Add Trade-in Vehicle";
  @Input() formGroup: UntypedFormGroup;
  public REQS = FORM_INPUT_REQS;
}
