import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from "@angular/router";
import { AuthService } from "./auth.service";
import { User } from "src/app/user-admin-module/models";
import { USER_ROLES } from "src/app/app.config";
import { combineLatest, Observable } from "rxjs";
import { first, map } from "rxjs/operators";
import { AuthService as Auth0Service } from '@auth0/auth0-angular';

@Injectable({providedIn: "root"})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private auth0: Auth0Service
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> {
    return combineLatest([this.authService.selectUser(), this.auth0.isAuthenticated$]).pipe(
      first(),
      map(([currentUser, isAuthenticated]) => {
        // Check for token in query params
        if (!isAuthenticated) {
          //console.log("Authguard---> user is NOT Authenticated");
          this.authService.logout();
          return false;
        } else {
          //console.log("Authguard---> user is Authenticated");
        }
        const token = route.queryParams.token || this.authService.token;
        if (route.queryParams.token) {
          if (route.queryParams.token !== this.authService.token) {
            this.authService.token = route.queryParams.token;
            if (!currentUser) {
              this.authService.loginWithToken(token);
            }
            return true;
          }
        }
        if (token && !currentUser) {
          this.authService.loginWithToken(token);
          return true;
        }

        if (!currentUser || !token) {
          return false;
        }

        const userRoles = User.unpackSecurityGroups(currentUser.securityGroups);

        // Security Group Check
        return this.checkSecurityGroups(route.data, userRoles);
      })
    );
  }

  checkSecurityGroups(routeData, userRoles) {
    const expectedRoles = routeData ? routeData.expectedRoles : false;
    if (!expectedRoles) {
      return true;
    } else {
      return expectedRoles.filter(expectedRole =>
        userRoles.includes(expectedRole) || userRoles.includes(USER_ROLES.sys_admin)
      ).length > 0;
    }
  }
}
