<div *ngIf="showVehicleNeedsForm">

  <form class="auto-fit-layout mb-3"
        [formGroup]="vehicleNeedsForm">
    <div>
      <label>Planned Length of Ownership</label>
      <select class="form-control"
              formControlName="plannedLengthOfOwnership"
              (change)="autoSubmitVehicleNeedsForm('plannedLengthOfOwnership')">
        <option *ngFor="let option of yearsOptions"
                [value]="option.years">
          {{ option.title }}
        </option>
      </select>
    </div>

    <div>
      <label class="d-flex align-items-center justify-content-between">
        Miles Driven Per Year
        <a [routerLink]="[]"
           class="small"
           (click)="onToggleCalculator()">
          Calculate
        </a>
      </label>
      <select class="form-control"
              formControlName="milesDrivenPerYear"
              (change)="autoSubmitVehicleNeedsForm('milesDrivenPerYear')">
        <option *ngFor="let option of milesOptions"
                [value]="option.miles">
          {{ option.title }}
        </option>
      </select>
    </div>
  </form>

  <form *ngIf="showInsuranceProductsForm"
        [formGroup]="insuranceProductsForm"
        class="auto-fit-layout mb-3">
    <div *ngFor="let product of insuranceProducts">
      <label>{{ product.name }}</label>
      <select class="form-control"
              formControlName="{{ product.productKey }}"
              (change)="autoSubmitInsuranceProductsForm(product)">
        <option *ngFor="let option of product.termCosts; let i = index"
                [value]="i">
          {{ option.term / 12 }} Years / {{ option.miles >= 999999 ? 'Unlimited' : (option.miles | number) }} Miles
        </option>
      </select>
    </div>
  </form>

</div>

<div *ngIf="showCalculator"
     class="mb-3">
  <app-miles-per-year-calculator></app-miles-per-year-calculator>
</div>
