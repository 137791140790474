import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { User } from "src/app/user-admin-module/models";
import { Subject } from "rxjs";
import { Actions, ofType } from "@ngrx/effects";
import * as dealActions from "../../store/actions/deal.actions";
import { AlertService } from "src/app/shared-module/services";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { AuthService } from "src/app/auth-module/services";
import { DealService, VehicleService } from "../../services";
import { takeUntil } from "rxjs/operators";
import { NewDealRequest, Vehicle } from "../../models";
import { FORM_INPUT_REQS, USER_ROLES } from "src/app/app.config";
import { CnaService } from "../../services/cna.service";

@Component({
  selector: "app-new-deal",
  templateUrl: "./new-deal.component.html",
  styleUrls: ["./new-deal.component.scss"]
})
export class NewDealComponent implements OnInit, AfterViewInit, OnDestroy {
  // google places
  @Input() addressType: string = 'address';
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addressText') addressText: any;
  @ViewChild('phoneInput') phoneInput: any;

  private unsubscribe$ = new Subject();
  private user: User;
  uiState = {busy: false, formInitialized: false};

  public isSalesFloor: boolean;
  public isDealerTrade: boolean;
  public REQS = FORM_INPUT_REQS;
  newDealForm: UntypedFormGroup = this.formBuilder.group({
    customer: this.formBuilder.group({
      companyName: [""],
      firstName: ["",],
      middleName: [""],
      lastName: [""],
      street: [""],
      city: [""],
      county: [""],
      state: [""],
      zip: ["", [
        Validators.minLength(5),
        Validators.maxLength(5),
        Validators.pattern("[0-9]*")
      ]],
      phone: ["", [
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern("[0-9]*")
      ]],
      email: ["",],
      driverId: [""]
    }),
    vehicle: this.formBuilder.group({
      stockNumber: ["", [
        Validators.pattern("[0-9]*")
      ]],
      tradeCondition: [""],
      year: [""],
      modelNumber: [""],
      make: [""],
      model: [""],
      trim: [""],
      vin: [""],
      msr: [""],
      retail: [""],
      salesId: [""], // READONLY
      odometer: [null, Validators.min(0)],
      plateNumber: [""],
      plateExpires: [""],
    })
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private actions$: Actions,
    private router: Router,
    private vehicleService: VehicleService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private dealService: DealService,
    public cnaService: CnaService,
  ) { }

  ngOnInit() {
    this.vehicleService.dispatchClearVehicle();
    this.subToActionFailure();
    this.subToUser();
    this.subToVehicle();

    this.cnaService.firstName$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      firstName => {
        //console.log('firstName changed:', firstName);
        this.newDealForm.controls.customer.patchValue({firstName});
      }
    );

    this.cnaService.lastName$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      lastName => {
        //console.log('lastName changed:', lastName);
        this.newDealForm.controls.customer.patchValue({lastName});
      }
    );
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addressText.nativeElement, {
      componentRestrictions: {country: 'US'},
      types: [this.addressType]  // 'establishment' / 'address' / 'geocode'
    });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.invokeEvent(place);
    });
  }

  invokeEvent(place: any) {
    //console.log("Assigned auto complete address:", place)
    if (place?.address_components?.length > 0) {
      const partByName: Map<string, string> = new Map([]);
      place.address_components.forEach((part: any) => {
        part.types.forEach((type: string) => {
          partByName.set(
            type,
            type === 'administrative_area_level_1' ?
              part.short_name :
              part.long_name);
        });
      });

      const street = ((partByName.get('street_number') || '') + " " + (partByName.get('route') || '') + " " + (partByName.get('subpremise') || ''))
        .trim()
        .replace(/southwest/gi, "SW")
        .replace(/southeast/gi, "SE")
        .replace(/northwest/gi, "NW")
        .replace(/northeast/gi, "NE");

      this.newDealForm.controls.customer.patchValue({
        street,
        city: partByName.get('locality') || '',
        state: partByName.get('administrative_area_level_1') || '',
        county: partByName.get('administrative_area_level_2') || '',
        zip: partByName.get('postal_code') || '',
      });
      this.phoneInput.nativeElement.focus();
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // INITIALIZATION

  private subToActionFailure() {
    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(dealActions.dealApiFailure)
    ).subscribe(action => {
      this.alertService.error(action.error);
      this.uiState.busy = false;
    });
  }

  private subToVehicle() {
    this.vehicleService.selectVehicle()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((vehicle: Vehicle) => {
        //console.log("Step 2",vehicle)
        this.newDealForm.patchValue({
          vehicle: {
            stockNumber: vehicle.stockNumber,
            modelNumber: vehicle.modelNumber,
            year: vehicle.year,
            make: vehicle.make,
            model: vehicle.model,
            trim: vehicle.trim
          }
        });
      });
  }

  subToUser() {
    this.authService.selectUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: User) => {
        if (user) {
          this.user = user;
          const userRoles = User.unpackSecurityGroups(user.securityGroups);
          if (userRoles.includes(USER_ROLES.sales_floor)) {
            this.isSalesFloor = true;
          }
          if (!this.uiState.formInitialized) {
            this.uiState.formInitialized = true;
            this.initializeForm();
          }
        }
      });
  }

  initializeForm() {
    const params = this.route.snapshot.queryParams;
    const stockNumber: string = this.route.snapshot.paramMap.get("stockNumber") || "";
    const salesId = this.user.employeeId || "";
    this.newDealForm.controls.vehicle.patchValue({salesId, stockNumber});
    this.newDealForm.controls.customer.patchValue({
      companyName: params.CompanyName ? params.CompanyName : "",
      firstName: params.FirstName ? params.FirstName : "",
      middleName: params.MiddleName ? params.MiddleName : "",
      lastName: params.LastName ? params.LastName : "",
      street: params.Address1 ? this.fullAddress(params.Address1, params.Address2) : "",
      city: params.City ? params.City : "",
      state: params.IssuedBy ? params.IssuedBy : "",
      zip: params.PostalCode ? params.PostalCode : "",
      driverId: params.LicenseNumber ? params.LicenseNumber : null
    });

    if (this.cnaService?.firstName) {
      this.newDealForm.controls.customer.patchValue({firstName: this.cnaService?.firstName});
    }
    if (this.cnaService?.lastName) {
      this.newDealForm.controls.customer.patchValue({lastName: this.cnaService?.lastName});
    }
    if (this.cnaService?.vehicle) {
      this.vehicleService.dispatchSetVehicleByLookup(this.cnaService?.vehicle);
      this.dealService.updateVehicle(this.cnaService?.vehicle, false);
    }
  }

  // ACTIONS

  onSelectModelNumberLookup() {
    this.router.navigate([{
      outlets: {
        modal: ["model-lookup"]
      },
    }]);
  }

  toggleDealerTrade() {
    this.isDealerTrade = !this.isDealerTrade;
    if (this.isDealerTrade) {
      this.setDealerTradeFormValidators();
    } else {
      this.clearDealerTradeFormValidators();
    }
    this.isDealerTrade ? this.newDealForm.get("vehicle")
        .get("stockNumber")
        .disable() :
      this.newDealForm.get("vehicle")
        .get("stockNumber")
        .enable();
  }

  setDealerTradeFormValidators() {
    this.newDealForm.get("vehicle").get("stockNumber").reset();
    this.newDealForm.get("vehicle").get("make").setValidators(Validators.required);
    this.newDealForm.get("vehicle").get("model").setValidators(Validators.required);
    this.newDealForm.get("vehicle").get("trim").setValidators(Validators.required);
    this.newDealForm.get("vehicle").get("vin").setValidators(Validators.required);
    this.newDealForm.get("vehicle").get("msr").setValidators(Validators.required);
    this.newDealForm.get("vehicle").get("retail").setValidators(Validators.required);
    this.newDealForm.get("vehicle").get("tradeCondition").setValidators(Validators.required);
    this.newDealForm.get("vehicle").get("year").setValidators(Validators.required);
  }

  clearDealerTradeFormValidators() {
    this.newDealForm.get("vehicle").get("make").clearValidators();
    this.newDealForm.get("vehicle").get("year").clearValidators();
    this.newDealForm.get("vehicle").get("model").clearValidators();
    this.newDealForm.get("vehicle").get("trim").clearValidators();
    this.newDealForm.get("vehicle").get("vin").clearValidators();
    this.newDealForm.get("vehicle").get("msr").clearValidators();
    this.newDealForm.get("vehicle").get("retail").clearValidators();
    this.newDealForm.get("vehicle").get("make").updateValueAndValidity();
    this.newDealForm.get("vehicle").get("model").updateValueAndValidity();
    this.newDealForm.get("vehicle").get("trim").updateValueAndValidity();
    this.newDealForm.get("vehicle").get("vin").updateValueAndValidity();
    this.newDealForm.get("vehicle").get("msr").updateValueAndValidity();
    this.newDealForm.get("vehicle").get("retail").updateValueAndValidity();
    this.newDealForm.get("vehicle").get("make").reset();
    this.newDealForm.get("vehicle").get("model").reset();
    this.newDealForm.get("vehicle").get("trim").reset();
    this.newDealForm.get("vehicle").get("vin").reset();
    this.newDealForm.get("vehicle").get("msr").reset();
    this.newDealForm.get("vehicle").get("retail").reset();
  }

  openVehicleLookup() {
    const navigationExtras: NavigationExtras = {
      queryParams: {changeDealOnSelectVehicle: false},
    };
    this.router.navigate([{
      outlets: {
        modal: ["vehicle-lookup"]
      },
    }], navigationExtras);
  }

  onSubmitNewDealForm() {
    const {customer} = this.newDealForm.value;
    // const salesPerson = `${this.user.firstName} ${this.user.lastName}`;
    // let managerId = this.user.managerId || "";
    // let salesManager = "";
    // if (this.authService.isSalesManager(this.user)) {
    //   salesManager = `${this.user.firstName} ${this.user.lastName}`;
    //   managerId = this.user.employeeId || "";
    // }
    const {
      salesId,
      stockNumber,
      odometer,
      plateNumber,
      plateExpires,
      year,
      modelNumber,
      make,
      model,
      trim,
      vin,
      msr,
      retail,
      tradeCondition
    } = this.newDealForm.value.vehicle;

    const deal: NewDealRequest = {
      customer,
      salesId,
      managerId: this.user.managerId || "",
      salesPerson: "",
      stockNumber: stockNumber || "",
      odometer,
      year: year.toString(),
      modelNumber,
      make,
      model,
      trim,
      vin,
      msr,
      retail,
      plateNumber,
      plateExpires,
      dealerTrade: this.isDealerTrade,
      tradeCondition
    };

    //console.log("Form: ", deal);

    this.uiState.busy = true;
    this.dealService.dispatchCreateDeal(deal);
  }

  // UI RENDERING

  get waitOnAction(): boolean {
    return this.uiState.busy;
  }

  fullAddress(address1: string, address2: string): string {
    let address = address1;
    if (address2) { address = address + " " + address2; }
    return address;
  }

  // FORM VALIDATION & HELPERS

  touchedInvalid(formGroupName: string, controlName: string): boolean {
    const formGroup = this.newDealForm.get(formGroupName);
    const control = formGroup.get(controlName);
    return control.touched && control.invalid;
  }

  trimInput(formGroupName: string, controlName: string, capFirstLetter = false) {
    const formGroup = this.newDealForm.get(formGroupName);
    let value = formGroup.value[ controlName ].trim();
    if (capFirstLetter) {
      value = this.capitalizeFirstChar(value);
    }
    formGroup.patchValue({[ controlName ]: value});
  }

  capitalizeFirstChar(str: string) {
    if (str.length === 0) {
      return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
