import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap, take, withLatestFrom } from "rxjs/operators";
import * as vehicleActions from "../actions/vehicle.actions";
import * as dealActions from "../actions/deal.actions";
import { DealIncentivesService, DealService } from "../../services";
import { VehicleService } from "../../services/";
import { InsuranceProduct, Vehicle } from "../../models";

@Injectable()
export class VehicleEffects {


  constructor(
    private actions$: Actions,
    private dealService: DealService,
    private vehicleService: VehicleService,
    private incentivesService: DealIncentivesService
  ) { }

  getVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vehicleActions.getVehicle),
      switchMap((data) => {
          return this.vehicleService.get(data.stockNumber).pipe(
            map(vehicle => {
              if (!vehicle.stockPhoto) {
                vehicle.customFields.forEach(field => {
                  if (field.key === "StockPhoto" && field.value)
                    vehicle.stockPhoto = field.value;
                });
              }
              return vehicleActions.getVehicleSuccess({vehicle});
            }),
            catchError(error => of(vehicleActions.getVehicleFailure({error})))
          );
        }
      )
    )
  );

  getCurrentVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vehicleActions.getCurrentVehicle),
      withLatestFrom(this.vehicleService.selectVehicle()),
      switchMap(([action, veh]) => {
          return this.vehicleService.get(veh.stockNumber).pipe(
            map(vehicle => vehicleActions.getVehicleSuccess({vehicle})),
            catchError(error => of(vehicleActions.getVehicleFailure({error})))
          );
        }
      )
    )
  );

  updateVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vehicleActions.updateVehicle),
      switchMap(({vehicle}) => {
          return this.vehicleService.update(vehicle).pipe(
            map(veh => {
              return vehicleActions.updateVehicleSuccess({vehicle: veh});
            }),
            catchError(error => of(vehicleActions.updateVehicleFailure({error})))
          );
        }
      )
    )
  );

  getAllVehicles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vehicleActions.getAllVehicles),
      switchMap((data) => {
          return this.vehicleService.getAll().pipe(
            map(vehicles => vehicleActions.getAllVehiclesSuccess({vehicles})),
            catchError(error => of(vehicleActions.getAllVehiclesFailure({error})))
          );
        }
      )
    )
  );

  changeStockNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vehicleActions.changeStockNumber),
      switchMap((data) => {
          return this.vehicleService.get(data.stockNumber).pipe(
            switchMap(vehicle => {
              const vehicleUpdate: Vehicle = data.odometer !== null ?
                {...vehicle, odometer: data.odometer} :
                vehicle;
              return this.vehicleService.update(vehicleUpdate);
            }),
            map((vehicle) => {
              this.dealService.updateVehicle(vehicle, true);
              this.dealService.dispatchSetLeaseOptions({
                subventionCashDisabled: true
              });
              this.vehicleService.dispatchSetVehicleByLookup(vehicle);
              this.dealService.dealInsuranceService.filterDealInsuranceProductsForStockSwitch().pipe(take(1)).subscribe(
                (dealInsuranceProducts: InsuranceProduct[]) => {
                  this.incentivesService.assignDefaultIncentives({forceRefresh: true});
                  return dealActions.setInsuranceProducts({insuranceProducts: dealInsuranceProducts});
                }
              );
              return vehicleActions.changeStockNumberSuccess({
                vehicle
              });
              /*this.incentivesService.assignDefaultIncentives({forceRefresh: true})
                return dealActions.setInsuranceProducts(dealInsuranceProducts);
                return vehicleActions.changeStockNumberSuccess({
                 vehicle
               });*/
            }),
            catchError(error => of(vehicleActions.changeStockNumberFailure({error})))
          );
        }
      )
    )
  );

  /*
    changeStockNumberSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(vehicleActions.changeStockNumberSuccess),
        map(({vehicle}) => {
          this.dealService.updateVehicle(vehicle, true);
        }),
        switchMap(() => this.dealService.dealInsuranceService.filterDealInsuranceProductsForStockSwitch()),
        map((dealInsuranceProducts: InsuranceProduct[]) => {
          this.incentivesService.assignDefaultIncentives({forceRefresh: true});
          return dealActions.setInsuranceProducts(dealInsuranceProducts);
        })
      )
    );
  */

  applyInitialInsuranceProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dealActions.applyInitialInsuranceProducts),
      withLatestFrom(
        this.vehicleService.selectInsuranceProducts(),
        this.dealService.selectDeal()
      ),
      switchMap(([action, insuranceProducts, deal]) => {
        if (deal.dealId && deal.insuranceProductsSet === false && (deal.insuranceProducts === null || !deal.insuranceProducts.length) && !deal.ID) {
          insuranceProducts = this.dealService.dealInsuranceService.configInitialProductSelections(insuranceProducts);
          return [
            dealActions.setInsuranceProducts({insuranceProducts}),
            dealActions.insuranceProductsSet({insuranceProductsSet: insuranceProducts}),
            dealActions.setInitialized({initialized: true})
          ];
        }
        return of(dealActions.noOp());
      })
    ),
  );

  setVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vehicleActions.setVehicle),
      map(({vehicle}) => {
        this.incentivesService.assignDefaultIncentives({forceRefresh: true});
        return dealActions.applyInitialInsuranceProducts();
      }),
    )
  );

  setVehicleByLookup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vehicleActions.setVehicleByLookup),
      switchMap(({vehicle}) => {
        return [
          vehicleActions.setVehicle({vehicle}),
          dealActions.clearFinanceOptionsEdits()
        ];
      })
    )
  );

}

