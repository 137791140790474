import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { CalculationService, DealService, LeaseCalculationService, VehicleService } from "src/app/clearpath-module/services";
import { DealState, DealStatus } from "src/app/clearpath-module/store/state";
import { User } from "src/app/user-admin-module/models";
import { UserService } from "src/app/user-admin-module/services";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { CreditTier, FinanceOptions, Vehicle, VehicleNeeds, WarrantySettingOption } from "src/app/clearpath-module/models";
import { CREDIT_TIERS } from "src/app/app.config";
import { WARRANTY_SETTINGS } from "src/app/clearpath-module/components/warranty/warranty-config";

@Component({
  selector: "app-sales-manager-writeup-header",
  templateUrl: "./sales-manager-writeup-header.component.html",
  styleUrls: ["./sales-manager-writeup-header.component.scss"]
})
export class SalesManagerWriteupHeaderComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();

  @Input() set deal(deal: DealState) { this.syncDealData(deal); }

  @Input() set financeOptions(options: FinanceOptions) { this.syncFinanceOptionsForm(options); }

  @Input() vehicle: Vehicle;

  @Input() set vehicleNeeds(needs: VehicleNeeds) { this.syncVehicleNeedsForm(needs); }

  @Output() submitSalesPerson = new EventEmitter<User>();
  @Output() submitSalesManager = new EventEmitter<User>();
  @Output() flagUnsavedChanges = new EventEmitter<boolean>();

  private uiState = {
    deal: null,
    showSalesTeamForm: false,
    formOptions: {
      salesPeople: null,
      salesManagers: null,
    }
  };

  salesTeamForm = this.formBuilder.group({
    salesPersonEmployeeId: ["", Validators.required],
    salesManagerEmployeeId: ["", Validators.required]
  });

  financeOptionsForm: UntypedFormGroup = this.formBuilder.group({
    // Select Values Return As String
    selectedCreditTier: ["0"]
  });

  vehicleNeedsForm: UntypedFormGroup = this.formBuilder.group({
    // Select Values Return As String
    milesDrivenPerYear: [this.milesPerYearOptions[ 0 ].miles]
  });


  // Calculation Observables
  totalFinanceMonthlyPayment$ = this.calcService.calculateTotalVehicleFinanceMonthlyPayment$();
  totalLeaseMonthlyPayment$ = this.leaseCalcService.calcTotalMonthlyLeasePayment$();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private calcService: CalculationService,
    private vehicleService: VehicleService,
    private dealService: DealService,
    private leaseCalcService: LeaseCalculationService
  ) { }

  ngOnInit() {
    this.getSalesPeople();
    this.getSalesManagers();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // INITIALIZATION

  private getSalesPeople() {
    this.userService.getSalesPeople()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (salesPeople: User[]) => {
          this.uiState.formOptions.salesPeople = salesPeople || [];
        }
      });
  }

  private getSalesManagers() {
    this.userService.getSalesManagers()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (salesManagers: User[]) => {
          this.uiState.formOptions.salesManagers = salesManagers || [];
        }
      });
  }

  private syncFinanceOptionsForm(financeOptions: FinanceOptions) {
    if (!financeOptions) { return; }

    this.financeOptionsForm.patchValue({
      selectedCreditTier: financeOptions.selectedCreditTier
    });
  }

  private syncVehicleNeedsForm(vehicleNeeds: VehicleNeeds) {
    if (!vehicleNeeds) { return; }

    const miles = vehicleNeeds.milesDrivenPerYear;
    const milesOptionExists = this.milesPerYearOptions.find(item => miles === item.miles);
    if (milesOptionExists) {
      this.vehicleNeedsForm.patchValue({milesDrivenPerYear: miles});
    }
  }


  private syncDealData(deal: DealState) {
    this.uiState.deal = deal;

    this.salesTeamForm.patchValue({
      salesPersonEmployeeId: deal.salesId,
      salesManagerEmployeeId: deal.managerId
    });

    const completedDeal = deal.status === DealStatus.Completed;
    if (completedDeal) { this.salesTeamForm.disable(); }
  }

  // UI CONTROL & RENDERING

  onToggleSalesTeamForm() {
    this.uiState.showSalesTeamForm = !this.uiState.showSalesTeamForm;
  }

  get creditTiers(): CreditTier[] {
    return CREDIT_TIERS;
  }

  get milesPerYearOptions(): WarrantySettingOption[] {
    return WARRANTY_SETTINGS.milesDrivenPerYear;
  }

  get showSalesTeamForm(): boolean {
    return this.uiState.showSalesTeamForm;
  }

  get salesPeople(): User[] {
    return this.uiState.formOptions.salesPeople || [];
  }

  get salesManagers(): User[] {
    return this.uiState.formOptions.salesManagers || [];
  }

  get accessoriesTotal(): number {
    const accessories = this.uiState.deal.accessories || [];
    return this.calcService.calcAccessoriesTotal(accessories);
  }

  get customerName(): string {
    const {firstName, lastName} = this.uiState.deal.customer;
    return firstName + " " + lastName;
  }

  get dealStatus(): string {
    return this.uiState.deal.status;
  }

  get dealVehicle(): string {
    return this.uiState.deal.vehicle;
  }

  get vehicleDescription(): string {
    return this.vehicleService.vehicleCondition(this.vehicle) + " " + this.vehicle.model.toLowerCase();
  }

  get downPayment(): number {
    return this.uiState.deal.financeOptions.downPayment;
  }

  get financeSelected(): boolean {
    return this.uiState.deal.financeOptions.financeSelected;
  }

  get validFinanceTerm(): boolean {
    return this.uiState.deal.financeOptions.selectedFinancingTerm > 0;
  }

  get leaseSelected(): boolean {
    return this.uiState.deal.leaseOptions.leaseSelected;
  }

  get validLeaseTerm(): boolean {
    return this.uiState.deal.leaseOptions.selectedLeaseTerm > 0;
  }

  get salesPerson(): string {
    const {salesId, salesPerson} = this.uiState.deal;
    return salesId ? salesPerson : "---";
  }

  get salesManager(): string {
    const {managerId, salesManager} = this.uiState.deal;
    const managerObject = (this.salesManagers || []).find(mgr => {
      return mgr.employeeId === managerId;
    });
    return managerId && managerObject ?
      `${managerObject.firstName} ${managerObject.lastName}` :
      "---";
  }

  // FORM HELPER

  autoSubmitFinanceOptionsForm() {
    const {pristine} = this.financeOptionsForm;
    if (pristine) { return; }

    const selectedCreditTier = +this.financeOptionsForm.value.selectedCreditTier;
    const financeOptions: Partial<FinanceOptions> = {
      selectedCreditTier,
      creditTierSelected: true
    };

    this.dealService.dispatchSetFinanceOptions(financeOptions);
    this.financeOptionsForm.markAsPristine();
    this.flagChange();
  }

  autoSubmitVehicleNeedsForm() {
    const {pristine} = this.vehicleNeedsForm;
    if (pristine) { return; }

    const milesDrivenPerYear = +this.vehicleNeedsForm.value.milesDrivenPerYear;
    const vehicleNeeds: Partial<VehicleNeeds> = {milesDrivenPerYear};

    this.dealService.dispatchSetVehicleNeeds(vehicleNeeds);
    this.vehicleNeedsForm.markAsPristine();
    this.flagChange();
  }

  autoSubmitSalesPerson() {
    const {salesPersonEmployeeId: employeeId} = this.salesTeamForm.value;
    if (!employeeId) { return alert("Sales Person has no employee ID."); }
    const salesPerson = this.salesPeople.find(employee => employeeId === employee.employeeId);
    this.submitSalesPerson.emit(salesPerson);
  }

  autoSubmitSalesManager() {
    const {salesManagerEmployeeId: employeeId} = this.salesTeamForm.value;
    if (!employeeId) { return alert("Sales Manager has no employee ID."); }
    const salesManager = this.salesManagers.find(employee => employeeId === employee.employeeId);
    this.submitSalesManager.emit(salesManager);
  }

  touchedInvalid(controlName: string): boolean {
    const control = this.salesTeamForm.get(controlName);
    return control.touched && control.invalid;
  }

  flagChange() {
    this.flagUnsavedChanges.emit(true);
  }

}
