import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import * as fromRoot from "../store/state/app";
import * as appSelectors from "../store/selectors/clearpath";
import * as appActions from "../store/actions/app.actions";
import { Observable } from "rxjs";
import { CreditTier, FinancingSettings, GlobalSettings, Links, MenuItem } from "../models";
import { ConfigDealer, ConfigFinance } from "src/app/settings-module/models";
import { ZipTable } from "src/app/settings-module/models/zip-table";

const api = "/v1/config";
const routes = {
  getFinance: `${api}/getfinance`,
  setFinance: `${api}/setfinance`,
  getNewResiduals: `${api}/getnewresiduals`,
  getCertUsedResiduals: `${api}/getcertusedresiduals`,
  zipTaxTable: `${api}/zipTaxTable`
};

@Injectable({
  providedIn: "root"
})
export class AppService {
  originalUrl: string = "";
  returnTo: string = "";

  constructor(
    private http: HttpClient,
    private store: Store<fromRoot.AppState>
  ) { }

  getAppSettingsData() {
    this.dispatchGetZipTaxTable();
    this.dispatchGetConfigFinance();
    this.dispatchGetNewResiduals();
    this.dispatchGetCertUsedResiduals();
    this.dispatchGetLinks();
    this.dispatchGetDealer();
  }

  selectClearpathApp(): Observable<fromRoot.AppState> {
    return this.store.select(appSelectors.selectClearpathApp);
  }

  selectCreditTiers(): Observable<CreditTier[]> {
    return this.store.select(appSelectors.selectCreditTiers);
  }

  selectLinks(): Observable<Links> {
    return this.store.select(appSelectors.selectLinks);
  }

  selectMenuItems(): Observable<MenuItem[]> {
    return this.store.select(appSelectors.selectMenuItems);
  }

  selectSearchString(): Observable<string> {
    return this.store.select(appSelectors.selectSearchString);
  }

  selectWriteupListLength(): Observable<number> {
    return this.store.select(appSelectors.selectWriteupListLength);
  }

  selectSelectedManagerId(): Observable<string> {
    return this.store.select(appSelectors.selectSelectedManagerId);
  }

  selectFinancing(): Observable<FinancingSettings> {
    return this.store.select(appSelectors.selectFinancing);
  }

  selectGlobalSettings(): Observable<GlobalSettings> {
    return this.store.select(appSelectors.selectGlobalSettings);
  }

  selectNewResiduals(): Observable<any> {
    return this.store.select(appSelectors.selectNewResiduals);
  }

  selectCertUsedResiduals(): Observable<any> {
    return this.store.select(appSelectors.selectCertUsedResiduals);
  }

  selectDealer(): Observable<ConfigDealer> {
    return this.store.select(appSelectors.selectDealer);
  }

  selectDefaultDealerVehiclePhotoUrl(): Observable<string> {
    return this.store.select(appSelectors.selectDefaultDealerVehiclePhotoUrl);
  }

  selectZipTaxTable(): Observable<ZipTable> {
    return this.store.select(appSelectors.selectZipTaxTable);
  }

  selectFormServer(): Observable<any> {
    return this.store.select(appSelectors.selectFormServer);
  }

  selectPrintDeals(): Observable<any> {
    return this.store.select(appSelectors.selectPrintDeals);
  }

  dispatchLocalStorageSync() {
    this.store.dispatch(appActions.localStorageSync());
  }

  dispatchGetLinks() {
    this.store.dispatch(appActions.getLinks());
  }

  dispatchSetLinks(links: Links) {
    this.store.dispatch(appActions.setLinks({links}));
  }

  dispatchGetDealer() {
    this.store.dispatch(appActions.getDealer());
  }

  dispatchGetConfigFinance() {
    this.store.dispatch(appActions.getConfigFinance());
  }

  dispatchGetFormServer() {
    this.store.dispatch(appActions.getFormServer());
  }

  dispatchGetZipTaxTable() {
    this.store.dispatch(appActions.getZipTaxTable());
  }

  dispatchSetConfigFinance(configData: ConfigFinance) {
    this.store.dispatch(appActions.setConfigFinance({configData}));
  }

  dispatchGetNewResiduals() {
    this.store.dispatch(appActions.getNewResiduals());
  }

  dispatchGetCertUsedResiduals() {
    this.store.dispatch(appActions.getCertUsedResiduals());
  }

  setSearch(searchString: string) {
    this.store.dispatch(appActions.setSearch({searchString}));
  }

  dispatchSetWriteupListLength(writeupListLength: number) {
    this.store.dispatch(appActions.setWriteupListLength({writeupListLength}));
  }

  setSelectedManagerId(selectedManagerId: string) {
    this.store.dispatch(appActions.setSelectedManagerId({selectedManagerId}));
  }

  getConfigFinance(): Observable<any> {
    return this.http.get(routes.getFinance);
  }

  setConfigFinance(configFinance: ConfigFinance): Observable<any> {
    return this.http.post(routes.setFinance, configFinance);
  }

  getNewResiduals(): Observable<any> {
    return this.http.get(routes.getNewResiduals);
  }

  getCertUsedResiduals(): Observable<any> {
    return this.http.get(routes.getCertUsedResiduals);
  }

  getZipTaxTable(): Observable<any> {
    return this.http.get(routes.zipTaxTable);
  }
}
