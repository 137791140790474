// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  name: "production",
  qa: false,
  production: true,
  apiUrl: "https://northstar-prod.herokuapp.com",
  dealerImagePath: "/static/images/svg/Beaverton-Toyota-Logo.svg",
  dealershipTitle: "Beaverton Toyota",
  pubNubPubKey: "pub-c-c27c0462-80cf-4801-8076-1993458cc365",
  pubNubSubKey: "sub-c-bd88c8a7-2654-450d-9817-62ad7e2fbcff",
  pubNubUserId: "usr-c-a9c5cea4-fb58-4de5-829b-adf3060a3008",
  salesQueueChannel: "salesQueueChannel",
  rayGunApiKey: "",
  msgChannel: "messageChannel",
  useHash: false,
  AUTH0_DOMAIN: "northstar-prod.us.auth0.com",
  AUTH0_CLIENT_ID: "c2OHhALtGWL7jbTXiCPyumtVxF6uVzS3",
  AUTH0_M2M_CLIENT_ID: "35EfMI1NCzVM5egRDZTdJIVEyCCeXy0Q",
  AUTH0_M2M_CLIENT_SECRET: "rvhkBeCKIBrt-0uiQo7ZffNrZGUH_rVKAFr1dJgNGJE9cla7cGOSwYswSlmxgpjH",
  firebase: {
    apiKey: "AIzaSyDpFFYynX14u0Oy1BxOIkf-Vzt_-rn8H8k",
    authDomain: "clearpath-cloud.firebaseapp.com",
    projectId: "clearpath-cloud",
    storageBucket: "clearpath-cloud.appspot.com",
    signatureStorage: "production/deal_submit_signatures"
  }
};
