<input
  #addFileSelector
  (change)="addFiles($event);addFileSelector.value=''"
  class="file-selector"
  type="file"
  multiple/>
<app-modal-view modalTitle="Report Bug" [showFooter]=true [size]="'medium'">

  <section modal-body class="report-bug-modal">
    <p>Have you encountered a problem with calculations or other issues in the application? If so, you can report it
      here, and our development team will receive an email about your issue. Please describe the problem you encountered
      in as much detail as possible. Include any unique aspects of this deal that might be related to the issue. You can
      also attach related files, such as scanned forms.</p>
    <form [formGroup]="reportBugForm">
      <div class="two-col">
        <mat-form-field appearance="fill">
          <mat-label>Your Name</mat-label>
          <input matInput formControlName="reporterName">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Your Email Address</mat-label>
          <input matInput formControlName="reporterEmail">
        </mat-form-field>
      </div>
      <mat-form-field appearance="fill">
        <mat-label>Describe the Problem in Detail Here</mat-label>
        <textarea matInput formControlName="description" rows="4"></textarea>
      </mat-form-field>
    </form>

    <div [ngStyle]="{display:tableData.dataSource.data.length>0?'block':'none'}">
      <h3>Selected Files</h3>

      <mat-table
        [dataSource]="tableData.dataSource"
        matSortActive="name"
        matSortDirection="asc"
        role="treegrid"
        matSort>
        <ng-container matColumnDef="deleteSampleFile">
          <mat-header-cell *matHeaderCellDef class="icon-cell"></mat-header-cell>
          <mat-cell *matCellDef="let element" class="icon-cell">
            <button class="icon-button" (click)="removeFile(element)" mat-icon-button matTooltip="Remove File"
                    matTooltipPosition="before">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>File Name</mat-header-cell>
          <mat-cell *matCellDef="let element" class="cursor-default">
            <div class="text-cell">{{ element.name }}</div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="size">
          <mat-header-cell *matHeaderCellDef class="cell-width-15" mat-sort-header>Size</mat-header-cell>
          <mat-cell *matCellDef="let element" class="cell-width-15">
            <div class="text-cell">
              {{ getSizeFormatted(element?.size) }}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="lastModifiedDate">
          <mat-header-cell *matHeaderCellDef class="cell-width-15" mat-sort-header>Date Modified</mat-header-cell>
          <mat-cell *matCellDef="let element" class="cell-width-15">
            <div class="text-cell">
              {{ element.lastModifiedDate|date:'shortDate' }}
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="tableData.displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: tableData.displayedColumns;"></mat-row>
      </mat-table>
    </div>
  </section>

  <section modal-footer class="footer">
    <ng-container>
      <button class="btn btn-sm rounded-pill mr-1 mx-1" (click)="closeModal()">Cancel</button>
      <button class="btn btn-sm rounded-pill app-btn-success mr-1 mx-1 form-button" (click)="addFileSelector.click()">
        <mat-icon style="height:14px;width:14px;font-size:14px;margin-right:3px;margin-top:1px;">add</mat-icon>
        Attach File(s)
      </button>
      <button class="btn btn-sm rounded-pill app-btn-success mr-1 mx-1 form-button" (click)="submitBugReport()">Submit
        Bug Report
      </button>
    </ng-container>
  </section>

</app-modal-view>
