import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { CalculationService, CalculationUtilityService } from "../../../../services";
import { takeUntil } from "rxjs/operators";
import Big from "big.js";

/**
 * Finance vs Cash Calculator Component
 * @class
 * @implements {AfterViewInit}
 * @implements {OnDestroy}
 * @export
 * @description This component is used to calculate the finance vs cash comparison
 */
@Component({
  selector: 'app-finance-vs-cash-calculator',
  templateUrl: './finance-vs-cash-calculator.component.html',
  styleUrls: ['./finance-vs-cash-calculator.component.scss']
})
export class FinanceVsCashCalculatorComponent implements OnInit, AfterViewInit, OnDestroy {

  /**
   * The years array
   */
  years: number[] = []

  /**
   * The fuel savings form
   */
  fuelSavingsForm: UntypedFormGroup = this.formBuilder.group({

    financeRate: [4.99, [Validators.required, Validators.min(1)]],
    numPayments: [72, [Validators.required, Validators.min(1)]],
    amountFinanced: [0, [Validators.required, Validators.min(1)]],
    payment: [null, [Validators.required, Validators.min(1)]],
    sumOfPayments: [null, [Validators.required, Validators.min(1)]],

    investRate: [4.8, [Validators.required, Validators.min(1)]],
    investValue: [null, [Validators.required, Validators.min(1)]],

    investmentGain: [null, [Validators.required, Validators.min(1)]],
    costOfLoan: [null, [Validators.required, Validators.min(1)]],
    savings: [null, [Validators.required, Validators.min(1)]],

    currentVehicleMPG: [15, [Validators.required, Validators.min(1)]],
    newVehicleMPG: [25, [Validators.required, Validators.min(1)]],
    milesDrivenPerMonth: [1000, [Validators.required, Validators.min(0)]],
    fuelCostPerGallon: [0, [Validators.required, Validators.min(0)]]
  });

  /**
   * Unsubscribe from the subject
   * @private
   */
  private unsubscribe$ = new Subject();

  /**
   * Constructor
   * @param formBuilder
   * @param calculationService
   * @param calcHelpers
   * @param changeDetectorRef
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    private calculationService: CalculationService,
    private calcHelpers: CalculationUtilityService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    // Subscribe to the form changes
    this.fuelSavingsForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.updateCalculations());
  }

  /**
   * After the view is initialized, subscribe to the totalVehicleFinancePrice$ subject and set the amountFinanced value
   */
  ngAfterViewInit() {
    this.calculationService.totalVehicleFinancePrice$(
      {withoutDaysToFirstPay: true})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(baseFinanceAmount => {
        //baseFinanceAmount = 35000
        this.fuelSavingsForm.get('amountFinanced').setValue(baseFinanceAmount);
        this.updateFinanceRate();
      })
  }

  /**
   * Unsubscribe from the subject
   */
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Update the calculations when the form changes
   */
  updateCalculations() {
    const amtFinanced = this.fuelSavingsForm.get('amountFinanced').value;
    const financeRate = this.fuelSavingsForm.get('financeRate').value;
    const numPayments = this.fuelSavingsForm.get('numPayments').value;
    const investRate = this.fuelSavingsForm.get('investRate').value;
    //console.log("updating calculations...", {amtFinanced, financeRate, numPayments, investRate})

    // calculate payment
    // Payment = (((amtFinanced * ((financeRate / 100) / 12)) / (1 - (1 + ((financeRate / 100) / 12)) ^ -numPaymentsNeg)) * 1)
    let monthlyInterestRate = Big(financeRate).div(100).div(12).toNumber();
    let tau = Big(monthlyInterestRate).add("1").toNumber();
    let tauToTheN = Math.pow(tau, numPayments);
    let magicNumber = tauToTheN * monthlyInterestRate / (tauToTheN - 1);
    let result = Big(amtFinanced).times(magicNumber);
    let payment = Big(result).round(2).toNumber();
    this.fuelSavingsForm.get('payment').setValue(payment);

    // calculate sumOfPayments
    const sumOfPayments = Big(payment * numPayments).round(2).toNumber();
    this.fuelSavingsForm.get('sumOfPayments').setValue(sumOfPayments);

    // calculate investmentGain
    let investmentGain = this.calculateInvestmentGain(amtFinanced, investRate, numPayments)
    this.fuelSavingsForm.get('investmentGain').setValue(investmentGain);

    // calculate costOfLoan
    const costOfLoan = this.calculateCostOfLoan(amtFinanced, financeRate, numPayments);
    this.fuelSavingsForm.get('costOfLoan').setValue(costOfLoan);

    // calculate investValue
    const investValue = Big(amtFinanced + investmentGain).round(2).toNumber();
    this.fuelSavingsForm.get('investValue').setValue(investValue);

    // calculate savings
    const savings = this.calculateSavings(amtFinanced, financeRate, investRate, numPayments)
    this.fuelSavingsForm.get('savings').setValue(savings);

    this.years = this.getYears(numPayments)

    this.changeDetectorRef.markForCheck();
  }

  /**
   * Update the finance rate based on the number of payments
   */
  updateFinanceRate = () => {
    //console.log("updateFinanceRate. term:", this.fuelSavingsForm.get('numPayments').value)
    this.calculationService.findInterestRate$(
      {term: parseInt(this.fuelSavingsForm.get('numPayments').value), excludeIncentives: false})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(financeRate => {
        //console.log("updateFinanceRate result:", financeRate)
        this.fuelSavingsForm.get('financeRate').setValue(Math.round(financeRate * 10000) / 100 || '4.99');
        this.updateCalculations();
      });
  }

  /**
   * Check if the control is touched and invalid (used for error messages)
   * @param controlName
   */
  touchedInvalid(controlName: string): boolean {
    const control = this.fuelSavingsForm.get(controlName);
    return control.touched && control.invalid;
  }

  /**
   * Truncate the decimal to 0 places
   * @param controlName
   */
  integerTrim(controlName: string) {
    const value = this.fuelSavingsForm.value[ controlName ];
    const integerValue = Math.floor(value);
    this.fuelSavingsForm.patchValue({[ controlName ]: integerValue});
  }

  /**
   * Truncate the decimal to 2 places
   * @param controlName
   */
  decimalTrim(controlName: string) {
    const value = this.fuelSavingsForm.value[ controlName ];
    const decimalValue = this.calcHelpers.truncateDecimal(value, 2);
    this.fuelSavingsForm.patchValue({[ controlName ]: decimalValue});
  }

  /**
   * Calculate the investment gain based on the amount financed, investment rate, and number of payments
   * Investment Gain = amtFinanced * ((1 + ((investRate / 100) / 12)) ^ numPayments) - amtFinanced
   * @param amtFinanced
   * @param investRate
   * @param numPayments
   */
  calculateInvestmentGain = (amtFinanced: any, investRate: any, numPayments: any) => {
    const monthlyInvestRate = Big(investRate).div(100).div(12).toNumber();
    const tau = Big(monthlyInvestRate).add("1").toNumber();
    const tauToTheN = Math.pow(tau, numPayments);
    return Big((amtFinanced * tauToTheN) - amtFinanced).round(2).toNumber();
  }

  /**
   * Calculate the cost of the loan based on the amount financed, finance rate, and number of payments
   * Cost of Loan = (((txtPrinciple * ((txtRate / 100) / 12)) / (1 - (1 + ((txtRate / 100) / 12)) ^ -txtTerm)) * txtTerm) - txtPrinciple
   * @param amtFinanced
   * @param financeRate
   * @param numPayments
   */
  calculateCostOfLoan = (amtFinanced: any, financeRate: any, numPayments: any) => {
    const txtPrinciple = amtFinanced;
    const txtRate = financeRate;
    const txtTerm = numPayments
    const u = Big(txtPrinciple).times(Big(txtRate).div(100).div(12))
    const v1 = Big(1).plus(Big(txtRate).div(100).div(12)).toNumber()
    const v2 = Big(1).minus(Math.pow(v1, (0 - txtTerm)))
    const w = Big(u).div(v2)
    const x = Big(w).times(txtTerm)
    const y = Big(x).minus(txtPrinciple)
    return Big(y).round(2).toNumber();
  }

  /**
   * Calculate the savings based on the amount financed, finance rate, investment rate, and number of payments
   * Savings = Investment Gain - Cost of Loan
   * @param amtFinanced
   * @param financeRate
   * @param investRate
   * @param numPayments
   */
  calculateSavings = (amtFinanced: any, financeRate: any, investRate: any, numPayments: any) => {
    const investmentGain = this.calculateInvestmentGain(amtFinanced, investRate, numPayments)
    const costOfLoan = this.calculateCostOfLoan(amtFinanced, financeRate, numPayments)
    return Big(investmentGain - costOfLoan).round(2).toNumber();
  }

  /**
   * Calculate the years based on the total months
   * @param totalMonths
   * @returns {number[]}
   */
  getYears = (totalMonths: number): number[] => {
    let yearsUpdated = []
    let currentYear = 1
    let monthsRemaining = totalMonths
    do {
      yearsUpdated.push(currentYear * 12 <= totalMonths ? currentYear : (currentYear - 1) + (monthsRemaining / 12))
      currentYear++
      monthsRemaining -= 12
    } while (monthsRemaining > 0)
    return yearsUpdated
  }
}
