import { createAction, props } from "@ngrx/store";
import { Links } from "../../models";

const prefix = "[Clearpath]";

const LOCAL_STORAGE_SYNC = `${prefix} LOCAL STORAGE SYNC`;
const FLUTTER_MESSAGE = `${prefix} FLUTTER MESSAGE`;
const SET_SEARCH = `${prefix} SET SEARCH`;
const SET_WRITEUP_LIST_LENGTH = `${prefix} SET WRITEUP LIST LENGTH`;
const SET_SELECTED_MANAGER_ID = `${prefix} SET SELECTED MANAGER ID`;
const GET_LINKS = `${prefix} GET LINKS`;
const SET_LINKS = `${prefix} SET LINKS`;
const LINKS_API_SUCCESS = `${prefix} LINKS API SUCCESS`;
const LINKS_API_FAILURE = `${prefix} LINKS API FAILURE`;
const GET_DEALS = `${prefix} GET DEALS`;
const GET_DEALS_SUCCESS = `${prefix} GET DEALS SUCCESS`;
const GET_DEALS_FAILURE = `${prefix} GET_DEALS FAILURE`;
const GET_CONFIG_FINANCE = `${prefix} GET CONFIG FINANCE`;
const GET_FORM_SERVER = `${prefix} GET FORM SERVER`;
const SET_CONFIG_FINANCE = `${prefix} SET CONFIG FINANCE`;
const CONFIG_FINANCE_API_SUCCESS = `${prefix} CONFIG FINANCE API SUCCESS`;
const CONFIG_FINANCE_API_FAILURE = `${prefix} CONFIG FINANCE API FAILURE`;
const FORM_SERVER_API_SUCCESS = `${prefix} FORM SERVER API SUCCESS`;
const FORM_SERVER_API_FAILURE = `${prefix} FORM SERVER API FAILURE`;
const GET_NEW_RESIDUALS = `${prefix} GET NEW RESIDUALS`;
const GET_NEW_RESIDUALS_SUCCESS = `${prefix} GET NEW RESIDUALS SUCCESS`;
const GET_NEW_RESIDUALS_FAILURE = `${prefix} GET NEW RESIDUALS FAILURE`;
const GET_CERT_USED_RESIDUALS = `${prefix} GET CERT USED RESIDUALS`;
const GET_CERT_USED_RESIDUALS_SUCCESS = `${prefix} GET CERT USED RESIDUALS SUCCESS`;
const GET_CERT_USED_RESIDUALS_FAILURE = `${prefix} GET CERT USED RESIDUALS FAILURE`;
const GET_DEALER = `${prefix} GET DEALER`;
const GET_DEALER_SUCCESS = `${prefix} GET DEALER SUCCESS`;
const GET_DEALER_FAILURE = `${prefix} GET DEALER FAILURE`;
const PUBSUB_MESSAGE = `${prefix} PUBSUB MESSAGE`;
const GET_ZIP_TAX_TABLE = `${prefix} GET ZIP TAX TABLE`;
const GET_ZIP_TAX_TABLE_SUCCESS = `${prefix} GET ZIP TAX TABLE SUCCESS`;
const GET_ZIP_TAX_TABLE_FAILURE = `${prefix} GET ZIP TAX TABLE FAILURE`;
const LOG = `${prefix} LOG`;

export const log = createAction(
  LOG,
  props<{ msg: any }>()
);

export const localStorageSync = createAction(LOCAL_STORAGE_SYNC);

export const setSearch = createAction(
  SET_SEARCH,
  props<{ searchString: string }>()
);

export const flutterMessage = createAction(
  FLUTTER_MESSAGE,
  props<{ msg: any }>()
);

export const setWriteupListLength = createAction(
  SET_WRITEUP_LIST_LENGTH,
  props<{ writeupListLength: number }>()
);

export const setSelectedManagerId = createAction(
  SET_SELECTED_MANAGER_ID,
  props<{ selectedManagerId: string }>()
);

export const getLinks = createAction(GET_LINKS);

export const setLinks = createAction(
  SET_LINKS,
  props<{ links: Links }>()
);

export const linksApiSuccess = createAction(
  LINKS_API_SUCCESS,
  props<{ links: Links }>()
);

export const linksApiFailure = createAction(
  LINKS_API_FAILURE,
  props<{ error: any }>()
);

export const getDealer = createAction(GET_DEALER);

export const getDealerSuccess = createAction(
  GET_DEALER_SUCCESS,
  props<{ dealer: any }>()
);

export const getDealerFailure = createAction(
  GET_DEALER_FAILURE,
  props<{ error: any }>()
);

export const getDeals = createAction(GET_DEALS);

export const getDealsSuccess = createAction(
  GET_DEALS_SUCCESS,
  props<{ deals: any[] }>()
);

export const getDealsFailure = createAction(
  GET_DEALS_FAILURE,
  props<{ error: any }>()
);

export const getZipTaxTable = createAction(GET_ZIP_TAX_TABLE);

export const getZipTaxTableSuccess = createAction(
  GET_ZIP_TAX_TABLE_SUCCESS,
  props<{ zipTaxTable: any }>()
);

export const getZipTaxTableFailure = createAction(
  GET_ZIP_TAX_TABLE_FAILURE,
  props<{ error: any }>()
);

export const getConfigFinance = createAction(GET_CONFIG_FINANCE);

export const getFormServer = createAction(GET_FORM_SERVER);

export const setConfigFinance = createAction(
  SET_CONFIG_FINANCE,
  props<{ configData: any }>()
);

export const configFinanceApiSuccess = createAction(
  CONFIG_FINANCE_API_SUCCESS,
  props<{ configData: any }>()
);

export const configFinanceApiFailure = createAction(
  CONFIG_FINANCE_API_FAILURE,
  props<{ error: any }>()
);

export const formServerApiSuccess = createAction(
  FORM_SERVER_API_SUCCESS,
  props<{ configData: any }>()
);

export const formServerApiFailure = createAction(
  FORM_SERVER_API_FAILURE,
  props<{ error: any }>()
);

export const getNewResiduals = createAction(GET_NEW_RESIDUALS);

export const getNewResidualsSuccess = createAction(
  GET_NEW_RESIDUALS_SUCCESS,
  props<{ newResiduals: any }>()
);

export const getNewResidualsFailure = createAction(
  GET_NEW_RESIDUALS_FAILURE,
  props<{ error: any }>()
);

export const getCertUsedResiduals = createAction(GET_CERT_USED_RESIDUALS);

export const getCertUsedResidualsSuccess = createAction(
  GET_CERT_USED_RESIDUALS_SUCCESS,
  props<{ certUsedResiduals: any }>()
);

export const getCertUsedResidualsFailure = createAction(
  GET_CERT_USED_RESIDUALS_FAILURE,
  props<{ error: any }>()
);

export const pubsubMessage = createAction(
  PUBSUB_MESSAGE,
  props<{ messageName: string; dealId: string }>()
);
