import { Component, OnDestroy, OnInit } from "@angular/core";
import { Vehicle } from "../../../models";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { AppService, DealService, VehicleService } from "../../../services";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import * as dealActions from "../../../store/actions/deal.actions";
import { Store } from "@ngrx/store";
import * as fromRoot from "../../../store/state";

@Component({
  selector: "app-vehicle-lookup",
  templateUrl: "./vehicle-lookup.component.html",
  styleUrls: ["./vehicle-lookup.component.scss"]
})
export class VehicleLookupComponent implements OnInit, OnDestroy {

  vehicles: Vehicle[] = [];
  filteredVehicles: Vehicle[] = [];
  searchText: string;
  sortParam = "year";
  filter = false;
  ascending = false;
  changeDealOnSelectVehicle = true;
  dispatchStockNumberOnly = false;
  private unsubscribe$ = new Subject();

  constructor(
    private appService: AppService,
    private dealService: DealService,
    private vehicleService: VehicleService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<fromRoot.DealState>,
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        if (params.changeDealOnSelectVehicle === "false") {
          this.changeDealOnSelectVehicle = false;
        }
        if (params.dispatchStockNumberOnly === "true") {
          this.dispatchStockNumberOnly = true;
        }
      });
    this.subToVehicles();
    this.subToSearchString();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  isActiveSort(type: string): boolean {
    return this.sortParam === type;
  }

  private subToVehicles() {
    this.vehicleService.selectUnsoldVehicles()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((vehicles: Vehicle[]) => {
        setTimeout(() => {
          if ((this.vehicles || []).length === 0) {
            this.vehicleService.dispatchGetAllVehicles();
          }
        }, 5000);

        // blocked vehicles (never show these vehicles.  Issue #1393)
        const blockedVehicles = [26493, 26619, 28151, 28274, 28314, 28318, 28374, 28404, 28405, 28440, 28452, 28531];

        this.vehicles = vehicles
          // filter out blocked stock numbers
          .filter(vehicle => !(blockedVehicles.includes(Number(vehicle.stockNumber))))
          // filter out vehicles that are not ready to be sold yet
          .filter(vehicle => !(vehicle.stockNumber.startsWith('P')));

        //console.log("vehicles", this.vehicles)
      });
  }

  private subToSearchString() {
    this.appService.selectSearchString()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((searchString: string) => {
        this.searchText = searchString;
        if (this.searchText) {
          this.filterVehicles();
          this.filter = true;
        } else {
          this.filter = false;
        }
      });
  }

  filterVehicles() {
    this.filteredVehicles = this.vehicles.filter((vehicle: Vehicle) => {
      return Object.keys(vehicle).filter(key => {
        const prop = vehicle[ key ];
        if (Array.isArray(prop)) {
          return prop.includes(this.searchText);
        } else {
          const stringProp = String(prop);
          return stringProp.toLowerCase().includes(this.searchText.toLowerCase());
        }
      }).length > 0;
    });
  }

  get vehiclesList() {
    return this.filter ? this.filteredVehicles : this.vehicles;
  }

  getCondition(vehicle: Vehicle) {
    const condition = this.vehicleService.vehicleCondition(vehicle);
    return condition ? condition.toUpperCase() : "";
  }

  numberWithCommas(x: any) {
    return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
  }

  truncateString(str: string, maxLength = 70): string {
    if (!str) return "";
    if (str.length <= maxLength) return str;
    return str.substr(0, maxLength);
  }

  // ACTIONS

  onSelectVehicle(vehicle: Vehicle) {
    if (this.dispatchStockNumberOnly) {
      const navigationExtras: NavigationExtras = {queryParams: {stockNumber: vehicle.stockNumber}};
      this.router.navigate([{outlets: {modal: ["change-stock-number"]}}], navigationExtras).then();
    } else {
      this.vehicleService.dispatchSetVehicleByLookup(vehicle);
      this.dealService.updateVehicle(vehicle, this.changeDealOnSelectVehicle);

      // set all dealer accessories for the found vehicle to the deal by default
      const selectedDealerAccessories = [];
      if (vehicle?.customFields?.length > 0) {
        vehicle?.customFields.forEach(field => {
          if (field.key.startsWith("DealerAccDesc")) {
            selectedDealerAccessories.push(field.value);
          }
        });
      }

      this.store.dispatch(dealActions.updateSelectedDealerAccessories({selectedDealerAccessories}));
      this.router.navigate([{outlets: {modal: null}}]).then();
    }
  }

  onSelectSort(type: string) {
    this.sortParam = type;
    this.ascending = !this.ascending;
  }
}
