<div *ngIf="config"
     class="app-page-container">
  <div class="d-flex flex-row justify-content-between">
    <div class="flex-grow-1 mr-1">
      <app-menu-link-list (changes)="onModifyMenuLinks($event)" [menuItems]="config.menuItems"></app-menu-link-list>

      <h3>External Links</h3>
      <app-menu-link-display (changes)="onModifyBlueBookLink($event)" [menuItem]="config.blueBookLink"
                             [title]="'Blue Book Link'">
      </app-menu-link-display>
      <app-menu-link-display (changes)="onModifyAccessoryCatalogLink($event)" [menuItem]="config.accessoryCatalogLink"
                             [title]="'Accessory Catalog Link'">
      </app-menu-link-display>
    </div>

    <div class="flex-grow-1 border-left pl-1">
      <h3>Featured Accessories</h3>
      <!--<ngb-tabset>
        <ngb-tab *ngIf="config.featuredAccessoriesNew" title="New">
          <ng-template ngbTabContent>
            <app-featured-accessories (changes)="onModifyAccessoriesNew($event)"
                                      [accessories]="config.featuredAccessoriesNew">
            </app-featured-accessories>
          </ng-template>
        </ngb-tab>
        <ngb-tab *ngIf="config.featuredAccessoriesUsed" title="Used">
          <ng-template ngbTabContent>
            <app-featured-accessories (changes)="onModifyAccessoriesUsed($event)"
                                      [accessories]="config.featuredAccessoriesUsed">
            </app-featured-accessories>
          </ng-template>
        </ngb-tab>
        <ngb-tab *ngIf="config.featuredAccessoriesCertified" title="Certified">
          <ng-template ngbTabContent>
            <app-featured-accessories (changes)="onModifyAccessoriesCertified($event)"
                                      [accessories]="config.featuredAccessoriesCertified">
            </app-featured-accessories>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>-->
    </div>
  </div>
</div>
