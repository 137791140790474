import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-modal-view",
  exportAs: "modal",
  templateUrl: "./modal-view.component.html",
  styleUrls: ["./modal-view.component.scss"]
})
export class ModalViewComponent {
  @Input() modalTitle = "Untitled Modal View";
  @Input() disableClickOut = false;
  @Input() showFooter = false;
  @Input() size = "full";

  constructor(private router: Router) { }

  clickOutModal($event: any) {
    if (this.disableClickOut) { return; }
    //const elementId = $event.target.id;
    //const isBackDropClick = elementId === "app-modal-container";
    //if (isBackDropClick) { this.closeModal(); }
  }

  closeModal() {
    this.router.navigate([{outlets: {modal: null}}]).then();
  }
}
