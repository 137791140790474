import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { DealService } from "../../services";
import { InsuranceProduct, TermCost } from "../../models";
import { HistoryService } from "../../services/history.service";

@Component({
  selector: "app-insurance-item-elite",
  templateUrl: "./insurance-item-elite.component.html",
  styleUrls: ["./insurance-item-elite.component.scss"]
})
export class InsuranceItemEliteComponent implements OnInit {
  @Input() product: InsuranceProduct;
  @Input() selectedTermIndex = null;
  @Output() closeModal = new EventEmitter();


  eliteForm: UntypedFormGroup = this.formBuilder.group({
    termCostIndex: [0]
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dealService: DealService,
    private historyService: HistoryService,
  ) { }

  ngOnInit() {
    this.syncSelectedPlan();
    this.eliteForm.valueChanges.subscribe(({termCostIndex}) => {
      this.dealService.dealInsuranceService.submitInsuranceProduct(this.product, +termCostIndex);
    });
  }

  // INITIALIZATION

  syncSelectedPlan() {
    const termCostIndex = this.selectedTermIndex || 0;
    this.eliteForm.patchValue({termCostIndex});
  }

  // ACTIONS

  onAcceptPlan() {
    const {termCostIndex} = this.eliteForm.value;
    this.dealService.dealInsuranceService.submitInsuranceProduct(this.product, +termCostIndex);
    this.historyService.dispatchAddEvent({
      shortName: "CCE Plan Accepted",
    })
    this.closeModal.emit();
  }

  onDeclinePlan() {
    this.dealService.dealInsuranceService.removeInsuranceProduct(this.product);
    this.historyService.dispatchAddEvent({
      shortName: "CCE Plan Declined",
    })
    this.closeModal.emit();
  }

  // UI CONTROL & RENDERING

  get termCosts(): TermCost[] {
    const terms = this.product ? this.product.termCosts : false;
    return terms || [];
  }

  planSelected(): boolean {
    const {termCostIndex} = this.eliteForm.value;
    return this.selectedTermIndex === +termCostIndex;
  }

  noPlanSelected(): boolean {
    return this.selectedTermIndex === null;
  }
}
