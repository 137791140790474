<section class="header-layout">
  <h1 class="h2 header-title">
    Pre-Paid Maintenance
  </h1>

  <form [formGroup]="ppmForm"
        class="header-form">
    <select class="header-form-control form-control" formControlName="termCostIndex">
      <option value="" disabled>Select A Plan
      </option>
      <option *ngFor="let option of termCosts; let i = index" [value]="i">
        {{ option.term / 12 }} Years / {{ option.miles || 'Unlimited' }} Miles -
        {{ option.price | currency:'USD':'symbol':'1.0-0' }}
      </option>
    </select>
  </form>
</section>

<app-cna-list-dialog-header dialogName="Pre-Paid Maintenance"></app-cna-list-dialog-header>

<ul class="nav nav-pills mb-3">
  <li *ngFor="let tab of viewTabs" class="nav-item">
    <a [routerLink]="" class="nav-link" [ngClass]="{ active: activeView(tab.id) }" (click)="onSelectView(tab.id)">
      {{ tab.title }}
    </a>
  </li>
</ul>

<section *ngIf="activeView('included')" class="card card-body mb-3">
  <img
    style="width: 100%; height: 100%; max-width: fit-content"
    src="/static/images/insurance/ppm/Northstar_PPM_Slide_1.jpg"
    alt=""/>
</section>

<section *ngIf="activeView('schedule')" class="card card-body mb-3">
  <img
    style="width: 100%; height: 100%; max-width: fit-content"
    src="/static/images/insurance/ppm/Northstar_PPM_Slide_2.jpg"
    alt=""/>
</section>

<div class="btn-layout">
  <button class="btn"
          [ngClass]="planSelected() ? 'app-btn-secondary' : 'app-btn-outline-secondary'"
          type="button"
          (click)="onAcceptPlan()">
    Yes: Include Coverage
  </button>
  <button class="btn"
          [ngClass]="noPlanSelected() ? 'app-btn-dark' : 'app-btn-outline-dark'"
          type="button"
          (click)="onDeclinePlan()">
    Decline
  </button>
</div>
