import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { TradeIn } from "src/app/clearpath-module/models";
import { FORM_INPUT_REQS } from "src/app/app.config";
import { DealStatus } from "src/app/clearpath-module/store/state";

@Component({
  selector: "app-vehicle-trade-in-form",
  templateUrl: "./vehicle-trade-in-form.component.html",
  styleUrls: ["./vehicle-trade-in-form.component.scss"]
})
export class VehicleTradeInFormComponent {
  @Input() set tradeIn(tradeIn: TradeIn) { this.syncFormData(tradeIn); }

  @Input() set dealStatus(status: string) { this.syncFormStatus(status); }

  @Output() submitTradeIn = new EventEmitter<TradeIn>();
  @Output() flagUnsavedChanges = new EventEmitter<boolean>();

  private uiState = {completedDeal: false};

  public REQS = FORM_INPUT_REQS;
  tradeInForm: UntypedFormGroup = this.formBuilder.group({
    year: [null, [Validators.min(1000), Validators.max(9999)]],
    make: [""],
    model: [""],
    trim: [""],
    color: [""],
    vin: [""],
    odometer: [0, Validators.min(0)],
    stockNumber: [""],
    holding: [""],
    tradeEstimate: [0, Validators.min(0)],
    payOff: [0, Validators.min(0)],
  });

  constructor(private formBuilder: UntypedFormBuilder) { }

  syncFormData(tradeIn: TradeIn) {
    this.tradeInForm.patchValue({
      year: tradeIn.year || null,
      make: tradeIn.make,
      model: tradeIn.model,
      trim: tradeIn.trim,
      color: tradeIn.color,
      vin: tradeIn.vin,
      odometer: tradeIn.odometer,
      stockNumber: tradeIn.stockNumber,
      holding: tradeIn.holding,
      tradeEstimate: tradeIn.tradeEstimate,
      payOff: tradeIn.payOff,
    });
  }

  private syncFormStatus(status: string) {
    const completedDeal = status === DealStatus.Completed;
    this.uiState.completedDeal = completedDeal;
    if (completedDeal) { this.tradeInForm.disable(); }
  }

  // FORM ACTIONS & HELPERS

  get completedDeal(): boolean {
    return this.uiState.completedDeal;
  }

  onClearForm() {
    const confirmed = confirm("Clear Form?");
    if (!confirmed) { return; }

    this.tradeInForm.reset();
    this.tradeInForm.markAsDirty();
    this.autoSubmitForm();
  }

  autoSubmitForm() {
    const {pristine, invalid, value} = this.tradeInForm;
    if (pristine || invalid) { return; }

    this.submitTradeIn.emit(value);
    this.tradeInForm.markAsPristine();
  }

  flagChange() {
    this.flagUnsavedChanges.emit(true);
  }

  touchedInvalid(controlName: string): boolean {
    const control = this.tradeInForm.get(controlName);
    return control.touched && control.invalid;
  }

}
