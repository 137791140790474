import { Pipe, PipeTransform } from "@angular/core";
import { User } from "src/app/user-admin-module/models";

@Pipe({
  name: "securityGroupFilter"
})
export class SecurityGroupFilterPipe implements PipeTransform {
  transform(users: any[], securityGroups: string[]): any[] {
    if (!users) { return []; }
    return users.filter(user => {
      const userRoles = User.unpackSecurityGroups(user.securityGroups);
      let match = false;
      securityGroups.forEach(securityGroup => {
        if (userRoles.includes(securityGroup)) {
          return match = true;
        }
      });
      return match;
    });

  }
}
