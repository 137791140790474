import { Component, OnInit } from "@angular/core";
import { take } from "rxjs/operators";
import { AppService } from "src/app/clearpath-module/services";
import { Manager } from "../../models/manager";
import { User } from "src/app/user-admin-module/models";
import { UserService } from "src/app/user-admin-module/services";

@Component({
  selector: "app-manager-select",
  templateUrl: "./manager-select.component.html",
  styleUrls: ["./manager-select.component.scss"]
})
export class ManagerSelectComponent implements OnInit {
  selectedManager: Manager = {employeeId: "", title: ""};
  managers: Manager[] = [];

  constructor(
    private appService: AppService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.getSalesManagers();
  }

  private getSalesManagers() {
    this.clearManager();
    this.userService.getSalesManagers()
      .pipe(take(1))
      .subscribe((data: User[]) => {
        const managers = data || [];
        this.managers = managers.map(employee => ({
          employeeId: employee.employeeId,
          title: `Team ${employee.firstName}`
        }));
      });
  }

  clearManager() {
    this.selectedManager = {employeeId: "", title: "All Teams"};
    this.appService.setSelectedManagerId("");
  }

  onSelectManager(manager: Manager) {
    if (!manager.employeeId) {
      this.clearManager();
      alert("Manager has no employee ID.");
      return;
    }

    this.selectedManager = manager;
    this.appService.setSelectedManagerId(this.selectedManager.employeeId);
  }

}
