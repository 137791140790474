import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { DealService } from "../../services";
import { InsuranceProduct, TermCost } from "../../models";

@Component({
  selector: "app-insurance-item-twp",
  templateUrl: "./insurance-item-twp.component.html",
  styleUrls: ["./insurance-item-twp.component.scss"]
})
export class InsuranceItemTwpComponent implements OnInit {
  @Input() product: InsuranceProduct;
  @Input() selectedTermIndex = null;
  @Output() closeModal = new EventEmitter();
  private uiState = {
    activeView: "damages",
    tabs: [
      {title: "Sample Damages", id: "damages"},
      {title: "Overview", id: "overview"},
    ]
  };

  twpForm: UntypedFormGroup = this.formBuilder.group({
    termCostIndex: [0]
  });

  coverage = [
    {
      benefit: "Replacement of a damaged tire",
      condition: "due to road hazard.",
      value: 150
    },
    {
      benefit: "Replacement of wheel",
      condition: "if it won't hold a seal with your new tire.",
      value: 340
    },
    {
      benefit: "Repair wheel of unsightly cosmetic damage",
      condition: "like scratches or scuffs.",
      value: 340
    },
    {
      benefit: "Towing to nearest Toyota Dealer",
      condition: "up to $100.",
      value: 70
    },
    {
      benefit: "Coverage of associated costs",
      condition: "(labor, wheel weights, valve stems, tire disposal, fees and taxes).",
      value: 35
    },
    {
      benefit: "Paintless Dent Repair.",
      condition: "Up to 4\" in diameter.",
      value: 55
    },
    {
      benefit: "Cracked Windshield Repair.",
      condition: "Chips and cracks up to 6\".",
      value: 75
    }
  ];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dealService: DealService
  ) { }

  ngOnInit() {
    this.syncSelectedPlan();
    this.twpForm.valueChanges.subscribe(({termCostIndex}) => {
      this.dealService.dealInsuranceService.submitInsuranceProduct(this.product, +termCostIndex);
    });
  }

  // INITIALIZATION

  syncSelectedPlan() {
    const termCostIndex = this.selectedTermIndex || 0;
    this.twpForm.patchValue({termCostIndex});
  }

  // ACTIONS

  onAcceptPlan() {
    const {termCostIndex} = this.twpForm.value;
    this.dealService.dealInsuranceService.submitInsuranceProduct(this.product, +termCostIndex);
    this.closeModal.emit();
  }

  onDeclinePlan() {
    this.dealService.dealInsuranceService.removeInsuranceProduct(this.product);
    this.closeModal.emit();
  }

  // UI CONTROL & RENDERING

  onSelectView(type: string) {
    this.uiState.activeView = type;
  }

  activeView(type: string): boolean {
    return this.uiState.activeView === type;
  }

  get viewTabs() {
    return this.uiState.tabs;
  }

  get termCosts(): TermCost[] {
    const terms = this.product ? this.product.termCosts : false;
    return terms || [];
  }

  planSelected(): boolean {
    const {termCostIndex} = this.twpForm.value;
    return this.selectedTermIndex === +termCostIndex;
  }

  noPlanSelected(): boolean {
    return this.selectedTermIndex === null;
  }

}
