import { createAction, props } from "@ngrx/store";
import { DealState } from "../state";
import { NewDealRequest } from "../../models";

const prefix = "[Clearpath]";

const GET_DEAL_BY_DEAL_ID = `${prefix} GET DEAL BY DEAL ID`;
const GET_PRINT_DEALS = `${prefix} GET PRINT DEALS`;
const GET_PRINT_DEALS_API_SUCCESS = `${prefix} GET PRINT DEALS API SUCESS`;
const GET_PRINT_DEALS_API_FAILURE = `${prefix} GET PRINT DEALS API FAILURE`;

/**
 * DEAL SUB ACTIONS
 * Manipulates slices of deal
 */
const SET_APR_SUBVENTION_DISABLED = `${prefix} SET_APR_SUBVENTION_DISABLED`;
const SET_ACCESSORIES = `${prefix} SET ACCESSORIES`;
const SET_COSIGNER = `${prefix} SET CO SIGNER`;
const SET_CUSTOMER = `${prefix} SET CUSTOMER`;
const SET_CUSTOMER_STATE_COUNTY = `${prefix} SET CUSTOMER STATE COUNTY`;
const SET_DEAL_TYPE = `${prefix} SET DEAL TYPE`;
const SET_DEAL_ODOMETER = `${prefix} SET DEAL ODOMETER`;
const SET_FINANCE_OPTIONS = `${prefix} SET FINANCE OPTIONS`;
const SET_CONTRACT_DATE = `${prefix} SET CONTRACT DATE`;
const SET_FINANCE_OPTIONS_EDITS = `${prefix} SET FINANCE OPTIONS EDITS`;
const SET_LIEN_HOLDER = `${prefix} SET LIEN HOLDER`;
const SET_DMV_REMARKS = `${prefix} SET DMV REMARKS`;
const SET_LEASE_OPTIONS = `${prefix} SET LEASE OPTIONS`;
const SET_INSURANCE_PRODUCTS = `${prefix} SET INSURANCE PRODUCTS`;
const UPDATE_INSURANCE_PRODUCT = `${prefix} UPDATE INSURANCE PRODUCT`;
const SET_SALES_MANAGER = `${prefix} SET SALES MANAGER`;
const SET_SALES_PERSON = `${prefix} SET SALES PERSON`;
const SET_TRADE_IN = `${prefix} SET TRADE IN`;
const SET_TRADE_IN_OWNER = `${prefix} SET TRADE IN OWNER`;
const SET_TRADE_IN_OWNER_2 = `${prefix} SET TRADE IN OWNER 2`;
const SET_TRADE_IN_2 = `${prefix} SET TRADE IN 2`;
const SET_TRADE_IN_2_OWNER = `${prefix} SET TRADE IN 2 OWNER`;
const SET_TRADE_IN_2_OWNER_2 = `${prefix} SET TRADE IN 2 OWNER 2`;
const SET_VEHICLE_NEEDS = `${prefix} SET VEHICLE NEEDS`;
const SET_INCENTIVES = `${prefix} SET INCENTIVES`;
const SET_PLATE_TRANSFER = `${prefix} PLATE TRANSFER`;
const SET_SIGNATURES = `${prefix} SET SIGNATURES`;
const SET_SIGNATURES_SUCCESS = `${prefix} SET SIGNATURES SUCCESS`;
const SET_SIGNATURES_FAILURE = `${prefix} SET SIGNATURES FAILURE`;
const SET_PLATE = `${prefix} SET PLATE`;
const INSURANCE_PRODUCTS_SET = `${prefix} INSURANCE PRODUCTS SET`;
const INCENTIVES_SET = `${prefix} INCENTIVES SET`;
const SET_DISPLAY_TERMS = `${prefix} SET DISPLAY TERMS`;
const SET_INITIALIZED = `${prefix} SET INITIALIZED`;
const SET_INSURANCE_INFO = `${prefix} SET INSURANCE INFO`;
const RECALCULATE = `${prefix} RECALCULATE`;
const PUSH_VERIFIED_FORM = `${prefix} PUSH VERIFIED FORM`;
const APPLY_INITIAL_INSURANCE_PRODUCTS = `${prefix} APPLY INITIAL INSURANCE PRODUCTS`;
const REMOVE_VERIFIED_FORM = `${prefix} REMOVE VERIFIED FORM`;
const CLEAR_FINANCE_OPTIONS_EDITS = `${prefix} CLEAR FINANCE OPTIONS EDITS`;
const REASSIGN_CLEAR_CARE_ELITE = `${prefix} REASSIGN CLEAR CARE ELITE`;
const SET_CUSTOM_FINANCE_RATE = `${prefix} SET CUSTOM FINANCE RATE`;
const SET_GAP_TO_FINANCING_TERM = `${prefix} SET GAP TO INANCING TERM`;
const NO_OP = `${prefix} NO OP`;
/**
 * DEAL LIFECYCLE
 * Stages of business sales process.
 */
const CLEAR_DEAL = `${prefix} CLEAR DEAL`;
const CREATE_DEAL = `${prefix} CREATE DEAL`;
const CHANGE_DEAL = `${prefix} CHANGE DEAL`;
const SUBMIT_DEAL = `${prefix} SUBMIT DEAL`;
const RETRACT_DEAL = `${prefix} RETRACT DEAL`;
const UPDATE_DEAL = `${prefix} UPDATE DEAL`;
const SAVE_DEAL = `${prefix} SAVE DEAL`;
const APPROVE_DEAL = `${prefix} APPROVE DEAL`;
const PRINT_DEAL = `${prefix} PRINT DEAL`;
const PRINT_DEAL_SETUP = `${prefix} PRINT DEAL SETUP`;
const PRINT_DEAL_SETUP_FINISHED = `${prefix} PRINT DEAL SETUP FINISHED`;
const DECLINE_DEAL = `${prefix} DECLINE DEAL`;
const COMPLETE_DEAL = `${prefix} COMPLETE DEAL`;
const CANCEL_CHANGE_DEAL = `${prefix} CANCEL CHANGE DEAL`;

/**
 * DEAL API
 * Update store when HTTP request successfuly returns with saved deal data.
 */
const DEAL_API_SUCCESS = `${prefix} DEAL API SUCCESS`;
const DEAL_API_FAILURE = `${prefix} DEAL API FAILURE`;

/**
 * FORM SUBMIT ACTIONS
 * Carry form data to be processed by other actions in deal effects
 */
const SUBMIT_DEAL_SUBMIT_MODAL_FORM = `${prefix} SUBMIT DEAL SUBMIT MODAL FORM`;
const GET_DEAL_AND_SUBMIT = `${prefix} GET DEAL AND SUBMIT`;

/**
 * OTHER ACTIONS
 */
const SALES_MANAGER_CREATE_DEAL = `${prefix} SALES MANAGER CREATE DEAL`;
const SALES_MANAGER_SET_FINANCING_TERM = `${prefix} SALES MANAGER SET FINANCING TERM`;
const SET_FINANCING_TERM = `${prefix} SET FINANCING TERM`;
const SET_STATE_TAXES_FEES = `${prefix} SET_STATE_TAXES_FEES`;
const SET_LEASING_TERM = `${prefix} SET LEASING TERM`;
const NOTIFICATIONS_CLICKED = `${prefix} NOTIFICATIONS CLICKED`;
const CALC_FEES = `${prefix} CALC FEES`;
const CALC_TAX = `${prefix} CALC TAX`;
const CLEAR_MONEY_FACTORS = `${prefix} CLEAR_MONEY_FACTORS`;
const UPDATE_SELECTED_DEALER_ACCESSORIES = `${prefix} UPDATE_SELECTED_DEALER_ACCESSORIES`;

// DEAL SUB-ACTIONS

export const getDealByDealId = createAction(
  GET_DEAL_BY_DEAL_ID,
  props<{ dealId: string }>()
);

export const setAccessories = createAction(
  SET_ACCESSORIES,
  props<{ accessories: any }>()
);

export const setCoSigner = createAction(
  SET_COSIGNER,
  props<{ coSigner: any }>()
);

export const setCustomer = createAction(
  SET_CUSTOMER,
  props<{ customer: any }>()
);

export const setCustomerStateCounty = createAction(
  SET_CUSTOMER_STATE_COUNTY,
  props<{ state: string; county: string }>()
);

export const setDealType = createAction(
  SET_DEAL_TYPE,
  props<{ dealType: string }>()
);

export const setFinanceOptions = createAction(
  SET_FINANCE_OPTIONS,
  props<{ financeOptions: any }>()
);

export const aprSubventionDisabled = createAction(
  SET_APR_SUBVENTION_DISABLED,
  props<{ financeOptions: any }>()
);

export const setContractDate = createAction(
  SET_CONTRACT_DATE,
  props<{ contractDate: string }>()
);

export const setFinanceOptionsEdits = createAction(
  SET_FINANCE_OPTIONS_EDITS,
  props<{ financeOptionsEdits: any }>()
);

export const setCustomFinanceRate = createAction(
  SET_CUSTOM_FINANCE_RATE,
  props<{ customerProvidedInterestRate: number; customSelected: boolean; activeInterestRate: number }>()
);

export const setLienHolder = createAction(
  SET_LIEN_HOLDER,
  props<{ lienHolder: any }>()
);

export const setDmvRemarks = createAction(
  SET_DMV_REMARKS,
  props<{ dmvRemarks: string }>()
);

export const setLeaseOptions = createAction(
  SET_LEASE_OPTIONS,
  props<{ leaseOptions: any }>()
);

export const setInsuranceProducts = createAction(
  SET_INSURANCE_PRODUCTS,
  props<{ insuranceProducts: any }>()
);

export const updateInsuranceProduct = createAction(
  UPDATE_INSURANCE_PRODUCT,
  props<{ insuranceProduct: any }>()
);

export const setGapToFinancingTerm = createAction(
  SET_GAP_TO_FINANCING_TERM,
  props<{ selectedFinancingTerm: number }>()
);

export const applyInitialInsuranceProducts = createAction(APPLY_INITIAL_INSURANCE_PRODUCTS);

export const setSalesManager = createAction(
  SET_SALES_MANAGER,
  props<{ salesManager: any }>()
);

export const setDisplayTerms = createAction(
  SET_DISPLAY_TERMS,
  props<{ displayTerms: any }>()
);

export const setSalesPerson = createAction(
  SET_SALES_PERSON,
  props<{ salesPerson: any }>()
);

export const setTradeIn = createAction(
  SET_TRADE_IN,
  props<{ tradeIn: any }>()
);

export const setTradeInOwner = createAction(
  SET_TRADE_IN_OWNER,
  props<{ tradeInOwner: any }>()
);

export const setTradeInOwner2 = createAction(
  SET_TRADE_IN_OWNER_2,
  props<{ tradeInOwner2: any }>()
);

export const setTradeIn2 = createAction(
  SET_TRADE_IN_2,
  props<{ tradeIn2: any }>()
);

export const setTradeIn2Owner = createAction(
  SET_TRADE_IN_2_OWNER,
  props<{ tradeIn2Owner: any }>()
);

export const setTradeIn2Owner2 = createAction(
  SET_TRADE_IN_2_OWNER_2,
  props<{ tradeIn2Owner2: any }>()
);

export const setVehicleNeeds = createAction(
  SET_VEHICLE_NEEDS,
  props<{ vehicleNeeds: any }>()
);

export const setIncentives = createAction(
  SET_INCENTIVES,
  props<{ incentives: any }>()
);

export const setInsuranceInfo = createAction(
  SET_INSURANCE_INFO,
  props<{ insuranceInfo: any }>()
);

export const setPlateTransfer = createAction(
  SET_PLATE_TRANSFER,
  props<{ plateTransfer: any }>()
);

export const setSignatures = createAction(
  SET_SIGNATURES,
  props<{ signatureData: any }>()
);

export const pushVerifiedForm = createAction(
  PUSH_VERIFIED_FORM,
  props<{ formName: string }>()
);

export const removeVerifiedForm = createAction(
  REMOVE_VERIFIED_FORM,
  props<{ formName: string }>()
);

export const setSignaturesSuccess = createAction(
  SET_SIGNATURES_SUCCESS,
  props<{ buyerSignatureSaved: boolean; coBuyerSignatureSaved: boolean }>()
);

export const setSignaturesFailure = createAction(
  SET_SIGNATURES_FAILURE,
  props<{ error: any }>()
);

export const insuranceProductsSet = createAction(
  INSURANCE_PRODUCTS_SET,
  props<{ insuranceProductsSet: any }>()
);

export const incentivesSet = createAction(
  INCENTIVES_SET,
  props<{ incentivesSet: any }>()
);

export const recalculate = createAction(RECALCULATE);

// DEAL LIFECYCLE / TOP-LEVEL DEAL ACTIONS
export const getDealAndSubmit = createAction(GET_DEAL_AND_SUBMIT);
export const clearDeal = createAction(CLEAR_DEAL);

export const createDeal = createAction(
  CREATE_DEAL,
  props<{ deal: NewDealRequest }>()
);

export const salesManagerCreateDeal = createAction(
  SALES_MANAGER_CREATE_DEAL,
  props<{ deal: NewDealRequest }>()
);

export const changeDeal = createAction(
  CHANGE_DEAL,
  props<{ deal: DealState }>()
);

export const submitDeal = createAction(
  SUBMIT_DEAL,
  props<{ deal: DealState }>()
);

export const retractDeal = createAction(
  RETRACT_DEAL,
  props<{ dealId: string }>()
);

export const updateDeal = createAction(
  UPDATE_DEAL,
  props<{ deal: DealState }>()
);

export const saveDeal = createAction(
  SAVE_DEAL,
  props<{ deal: DealState }>()
);

export const approveDeal = createAction(
  APPROVE_DEAL,
  props<{ dealId: string; criticalMemo: string }>()
);

export const printDeal = createAction(
  PRINT_DEAL,
  props<{ dealId: string }>()
);

export const getPrintDeals = createAction(GET_PRINT_DEALS);

export const getPrintDealsApiSuccess = createAction(
  GET_PRINT_DEALS_API_SUCCESS,
  props<{ deals: DealState[] }>()
);

export const getPrintDealsApiFailure = createAction(
  GET_PRINT_DEALS_API_FAILURE,
  props<{ error: any }>()
);

export const printDealSetup = createAction(PRINT_DEAL_SETUP);
export const printDealSetupFinished = createAction(PRINT_DEAL_SETUP_FINISHED);

export const declineDeal = createAction(
  DECLINE_DEAL,
  props<{ dealId: string }>()
);

export const completeDeal = createAction(
  COMPLETE_DEAL,
  props<{ deal: DealState }>()
);

export const dealApiSuccess = createAction(
  DEAL_API_SUCCESS,
  props<{ deal: DealState; actionType?: string }>()
);

export const dealApiFailure = createAction(
  DEAL_API_FAILURE,
  props<{ error: any }>()
);

export const setDealOdometer = createAction(
  SET_DEAL_ODOMETER,
  props<{ odometer: number }>()
);

export const setPlate = createAction(
  SET_PLATE,
  props<{ plateExpires: string; plateNumber: string }>()
);

export const setInitialized = createAction(
  SET_INITIALIZED,
  props<{ initialized: boolean }>()
);

export const cancelChangeDeal = createAction(CANCEL_CHANGE_DEAL);
export const clearFinanceOptionsEdits = createAction(CLEAR_FINANCE_OPTIONS_EDITS);
export const noOp = createAction(NO_OP);

export const calcFees = createAction(CALC_FEES);
export const calcTax = createAction(CALC_TAX);


// FORM SUBMIT ACTIONS

export const submitDealSubmitModalForm = createAction(
  SUBMIT_DEAL_SUBMIT_MODAL_FORM,
  props<{
    signatureData: any;
    signatureUrls: any;
    customer: any;
    coSigner: any;
    tradeIn: any;
    tradeInOwner: any;
    tradeInOwner2: any;
    tradeIn2: any;
    tradeIn2Owner: any;
    tradeIn2Owner2: any;
    insuranceInfo: any;
    comments: string;
  }>()
);

// OTHER ACTIONS

export const setFinancingTerm = createAction(
  SET_FINANCING_TERM,
  props<{ selectedFinancingTerm: number }>()
);

export const clearCustomMoneyFactors = createAction(CLEAR_MONEY_FACTORS);

export const setStateTaxesFees = createAction(
  SET_STATE_TAXES_FEES,
  props<{ totalStateTaxes: number; totalStateFees: number }>()
);

export const salesManagerSetFinancingTerm = createAction(
  SALES_MANAGER_SET_FINANCING_TERM,
  props<{ selectedFinancingTerm: number }>()
);

export const setLeasingTerm = createAction(
  SET_LEASING_TERM,
  props<{ selectedLeaseTerm: number }>()
);

export const updateSelectedDealerAccessories = createAction(
  UPDATE_SELECTED_DEALER_ACCESSORIES,
  props<{ selectedDealerAccessories: any }>()
);

export const notificationsClicked = createAction(NOTIFICATIONS_CLICKED);
