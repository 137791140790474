<section class="d-flex align-items-center justify-content-between mb-1">
  <div>Status: <span class="app-text-bold">{{ dealStatus || '---' | titlecase }}</span></div>
</section>

<section class="header-details-layout">
  <div>
    <div class="small">Customer</div>
    <div class="app-text-bold">
      {{ customerName || '---' | titlecase }}
    </div>
  </div>

  <div>
    <div class="small">Sales Person</div>
    <a [routerLink]="[]"
       class="app-text-bold app-text-success"
       (click)="onToggleSalesTeamForm()">
      {{ salesPerson || '---' | titlecase }}
    </a>
  </div>

  <div>
    <div class="small">Sales Manager</div>
    <a [routerLink]="[]"
       class="app-text-bold app-text-success"
       (click)="onToggleSalesTeamForm()">
      {{ salesManager || '---' | titlecase }}
    </a>
  </div>

  <div>
    <div class="small">Vehicle</div>
    <div class="app-text-bold">
      {{ vehicleDescription || '---' }}
    </div>
  </div>

  <div>
    <div class="small">Stock #</div>
    <div class="app-text-bold">
      {{ vehicle.stockNumber || '---' }}
    </div>
  </div>

  <div *ngIf="financeSelected">
    <div class="small">Monthly Payment</div>
    <div class="app-text-bold">
      <span *ngIf="validFinanceTerm">{{ totalFinanceMonthlyPayment$ | async | currency }}</span>
      <span *ngIf="!validFinanceTerm">---</span>
    </div>
  </div>

  <div *ngIf="leaseSelected">
    <div class="small">Monthly Payment</div>
    <div class="app-text-bold">
      <span *ngIf="validLeaseTerm">{{ totalLeaseMonthlyPayment$ | async | currency }}</span>
      <span *ngIf="!validLeaseTerm">---</span>
    </div>
  </div>

  <div>
    <div class="small">Accessories</div>
    <div class="app-text-bold">
      {{ accessoriesTotal || 0 | currency }}
    </div>
  </div>

  <div>
    <div class="small">Down Payment</div>
    <div class="app-text-bold">
      {{ downPayment || 0 | currency }}
    </div>
  </div>

  <form [formGroup]="financeOptionsForm">
    <label>
      Credit Tier
    </label>
    <select class="form-control"
            formControlName="selectedCreditTier"
            (change)="autoSubmitFinanceOptionsForm()">
      <option value=""
              disabled>Select Credit Tier
      </option>
      <option *ngFor="let option of creditTiers"
              [value]="option.tier">
        {{ option.name }}
      </option>
    </select>
  </form>

  <form [formGroup]="vehicleNeedsForm"
        class="column-layout">
    <div>
      <label>
        Est. Miles Per Year
      </label>
      <select class="form-control"
              formControlName="milesDrivenPerYear"
              (change)="autoSubmitVehicleNeedsForm()">
        <option value=""
                disabled>Select Miles
        </option>
        <option *ngFor="let option of milesPerYearOptions"
                [value]="option.miles">
          {{ option.title }}
        </option>
      </select>
    </div>
  </form>
</section>

<section *ngIf="showSalesTeamForm"
         class="app-bg-lt-blue card card-body mt-3">
  <div class="d-flex align-items-center justify-content-between mb-2">
    <h2 class="h3 app-text-success mb-0">Edit</h2>
    <i class="material-icons icon-btn app-text-neutral"
       (click)="onToggleSalesTeamForm()">
      close
    </i>
  </div>

  <div [formGroup]="salesTeamForm"
       class="row">
    <div class="form-group col-sm-6">
      <label [ngClass]="{ 'app-text-danger': touchedInvalid('salesPersonEmployeeId') }">
        Sales Person
      </label>
      <select class="form-control"
              formControlName="salesPersonEmployeeId"
              (change)="autoSubmitSalesPerson()">
        <option disabled
                value="">Select
        </option>
        <option *ngFor="let employee of salesPeople"
                [value]="employee.employeeId">
          {{ employee.firstName | titlecase }} {{ employee.lastName | titlecase }}
        </option>
      </select>
    </div>

    <div class="form-group col-sm-6">
      <label [ngClass]="{ 'app-text-danger': touchedInvalid('salesManagerEmployeeId') }">
        Sales Manager
      </label>
      <select class="form-control"
              formControlName="salesManagerEmployeeId"
              (change)="autoSubmitSalesManager()">
        <option disabled
                value="">Select
        </option>
        <option *ngFor="let employee of salesManagers"
                [value]="employee.employeeId">
          {{ employee.firstName | titlecase }} {{ employee.lastName | titlecase }}
        </option>
      </select>
    </div>
  </div>

</section>
